<template>
  <div class="bg-white shadow shadow-shadowColor rounded-xl p-4 font-sans">
    <div class="chart flex flex-col justify-center items-center space-y-10">
      <h1 class="text-3xl font-semibold font-sans"><font-awesome-icon icon="fa-solid fa-shield-halved"/> {{ t( 'Reactivity' ) }}</h1>
      <div v-if="data != null && data.first_action_started != null && data.first_action_started.title != null" class="flex flex-col justify-center items-center space-y-8">
        <span class="text-5xl"><font-awesome-icon icon="fa-solid fa-hourglass-half" size="2xl" style="color: #37A2DA;" /></span>
        <h1 class="text-3xl font-semibold font-sans">{{ reactivityTime }}</h1>
        <div v-if="data.first_action_started != null" class="text">
          <h1 class="font-semibold text-lg">{{ t( 'First securing action' ) }} :</h1>
          <h1 v-if="data.first_action_started.title != null">
            <span class="font-semibold">{{ t( 'Title' ) }} :</span> {{ data.first_action_started.title }}
          </h1>
          <!-- <h1 v-if="data.first_action_started.description != null">
            <span class="font-semibold">{{ t( 'Description' ) }} :</span> {{ data.first_action_started.description }}
          </h1> -->
          <h1 v-if="data.first_action_started.action_users != null">
            <span class="font-semibold">{{ t( 'Manager' ) }} :</span> {{ data.first_action_started.action_users[0].user.fullname }}
          </h1>
          <h1 v-if="data.first_action_started.action_users != null">
            <span class="font-semibold">{{ t( 'Started date' ) }} :</span> {{ data.first_action_started.action_users[0].completed_date }}
          </h1>
        </div>
      </div>
      <div v-else class="flex flex-col justify-start items-center text-5xl pt-2 space-y-10">
        <font-awesome-icon icon="fa-solid fa-hourglass-half" beat size="2xl" style="color: #37A2DA;" />
        <h1 class="text-2xl font-semibold font-sans text-center">{{ t( 'No securing action in place' ) }}</h1>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import moment from 'moment';

  const props = defineProps( {
    data: { Object, required: true }
  } );

  const { t } = useI18n();
  var reactivityTime = '';
  if ( props.data != null ) {
    reactivityTime = reactivityTime.concat( Math.round( moment.duration( props.data.reactivity_minute, 'minutes' ).asDays() * 10 ) / 10, ' ', t( 'days short' )/*, ' ', duration.hours(), ' ', t( 'hours short' ), ' ', duration.minutes(), ' ', t( 'minutes short' )*/ );
  }
</script>

<style scoped>
  .chart {
    height: 500px;
    width: 350px;
  }
</style>