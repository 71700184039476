<template>
  <div class="flex flex-col round border shadow-lg rounded-xl">
    <div v-if="slots.header" class="flex flex-col bg-gray-100 rounded-t-xl p-1 text-left select-none">
      <div class="font-bold self-center">
        <slot name="header"></slot>
      </div>
      <div class="self-end">
        <div class="flex flex-row gap-0.5" v-if="props.start_date && !props.end_date">
          <span class="self-center text-xs"> {{ t( '_to' ) }} : </span>
          <FieldDate :field="props.start_date" :maxDate="today" @change="props.change_date" @clear-date="props.reset_date" :clearable="reset_date != null" css_class="--dp-small"/>
        </div>
        <div class="flex flex-row gap-0.5" v-if="props.start_date && props.end_date">
          <span class="self-center text-xs"> {{ t( 'period' ) }} : </span>
          <FieldRangeDate :start_date="props.start_date" :end_date="props.end_date" :maxDate="today" @change="props.change_date" @clear-date="props.reset_date" :presetDates="presetDates" :clearable="reset_date != null" css_class="--dp-small"/>
        </div>
      </div>
    </div>
    <div class="flex h-full w-full p-0 align-middle place-content-center">
      <slot name="graph" class="place-self-center"></slot>
    </div>
  </div>
</template>

<script setup>
  import { useSlots } from 'vue';
  import FieldDate from '@/components/object/FieldDate.vue';
  import FieldRangeDate from '@/components/object/FieldRangeDate.vue';
  import moment from 'moment';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const slots = useSlots();
  const props = defineProps( {
    readOnly: { Boolean, required: false, default: false },
    start_date: { String, required: false, default: null },
    end_date: { String, required: false, default: null },
    change_date: { Function, required: false, default: null },
    reset_date: { Function, require: false, default: null },
  } );

  let today = moment().startOf( 'day' ).toDate();
  const presetDates = [
    { label: t( 'Last year' ), value: [ moment().subtract( 1, 'year' ).startOf( 'year' ).toDate(), moment().subtract( 1, 'year' ).endOf( 'year' ).toDate() ] },
    { label: t( 'This year' ), value: [ moment().startOf( 'year' ).toDate(), today ] },
    { label: t( 'Last month' ), value: [ moment().subtract( 1, 'month' ).startOf( 'month' ).toDate(), moment().subtract( 1, 'month' ).endOf( 'month' ).toDate() ] },
    { label: t( 'This month' ), value: [ moment().startOf( 'month' ).toDate(), today ] },
    { label: t( 'Last week' ), value: [ moment().subtract( 1, 'week' ).startOf( 'week' ).toDate(), moment().subtract( 1, 'week' ).endOf( 'week' ).toDate() ] },
    { label: t( 'This week' ), value: [ moment().startOf( 'week' ).toDate(), today ] },
  ];
</script>