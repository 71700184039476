<template>
  <div class="text-secondary-text">
    <div v-if="title" class=" font-bold">{{ title }}</div>
    <p v-if="!editable" @click="setEditable" @focus="setEditable" class="break-all"
      :class="[ readonly ? '' : 'hover:bg-tertiary-hover cursor-pointer' ].concat( 'min-h-[1.5rem]', fieldValue != null && fieldValue != '' ? '' : 'text-gray-400' )">
      {{ fieldValue != null && fieldValue != '' ? fieldValue : placeholder }}
    </p>
    <div class="flex" v-if="editable">
      <div class="flex w-full relative">
        <input ref="editableField" class="w-full text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100"
          :maxlength="maxlength > -1 ? maxlength : null" name="field-value" v-model="editFieldValue" :placeholder="placeholder"
          @keyup.enter.exact.prevent="changeValue" @blur="blur"
          @focus="selectContent"/>
        <span v-if="maxlength !== -1"
          class="noblur absolute bottom-1 right-1 z-10 text-xs text-secondary-text font-bold text-white bg-gray-600 rounded px-1"
          :class="editFieldValue.length >= maxlength ? 'bg-secondary-hover' : undefined">
          {{ editFieldValue.length + '/' + maxlength }}
        </span>
      </div>
      <ValidEditButton class="ml-2 self-end noblur" @click="changeValue"></ValidEditButton>
      <CancelEditButton class="ml-2 self-end noblur" @click="editable=false"></CancelEditButton>
    </div>
  </div>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import ValidEditButton from '@/components/buttons/ValidEditButton.vue';
  import CancelEditButton from '@/components/buttons/CancelEditButton.vue';

  const props = defineProps( {
    title: { String, required: false },
    field: { String, required: true },
    placeholder: { String, required: false, default: '' },
    fkey: { String, required: false },
    readonly: { Boolean, required: false, default: false },
    maxlength: { Number, required: false, default: -1 }
  } );
  const emit = defineEmits( [ 'change' ] );

  const editable = ref ( false );
  const editableField = ref( null );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const fieldValue = ref( props.field );
  const editFieldValue = ref( '' );

  const setEditable = () => {
    if ( !props.readonly ) {
      editable.value = true;
      editFieldValue.value = fieldValue.value ?? '';
      nextTick( () => {
        editableField.value.focus();
      } );
    }
  };

  const selectContent = ( event ) => {
    if ( event != null && event.target != null ) {
      event.target.select();
    }
  };

  const changeValue = () => {
    if ( fieldValue.value != editFieldValue.value ) {
      fieldValue.value = editFieldValue.value;
      emit( 'change', props.fkey, fieldValue.value );
    }
    editable.value = false;
  };

  const blur = ( event ) => {
    if ( event.relatedTarget && event.relatedTarget.className.includes( 'noblur' ) ) {
      event.preventDefault = false;
    } else {
      editable.value = false;
      changeValue();
    }
  };
</script>