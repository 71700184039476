<template>
  <div class="bg-white shadow shadow-shadowColor rounded group p-4">
    <div class="flex w-auto justify-center">
      <div class="w-32">
        <RPStatusButton :rp="rp" @changeItem="changeRP"></RPStatusButton>
      </div>
    </div>
    <div class="w-full mt-4">
      <RPMainDetails :rp="rp" :readonly="readonly" @changeItem="changeRP" @changeUsers="changeUsers"></RPMainDetails>
    </div>
    <div class="flex w-auto mt-4 justify-left">
      <div>
        <LoadingButton ref="downloadReportBtn" type="submit" :label="t( 'Download report' )" @click="clickDownloadReport"/>
      </div>
      <div class="ml-2">
        <RPChartsButton :rp_id="rp.id"></RPChartsButton>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import download from 'downloadjs';
  import API from '@/constants/api.constants';
  import utils from '@/features/utils.js';
  import RPStatusButton from '@/components/buttons/RPStatusButton.vue';
  import RPMainDetails from '@/components/rp/details/RPMainDetails.vue';
  import RPChartsButton from '@/components/buttons/RPChartsButton.vue';
  import { useModal } from 'vue-final-modal';
  import DownloadReportModal from '@/components/modals/rp_modals/DownloadReportModal.vue';

  const props = defineProps( {
    rp: { Object, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emits = defineEmits( [ 'changeRP', 'changeUsers' ] );

  const { t } = useI18n();
  const downloadReportBtn = ref( null );

  const changeRP = ( ...fields ) => {
    emits( 'changeRP', fields );
  };

  const changeUsers = () => {
    emits( 'changeUsers' );
  };

  const clickDownloadReport = () => {
    let dowloadReportModal = useModal( {
      component: DownloadReportModal,
      attrs: {
        overlayTransition: 'vfm-fade',
        contentTransition: 'vfm-fade',
        escToClose: true,
        clickToClose: true,
        rpDocumentsList: props.rp.rp_documents ?? [],
        async onDownload( desired_sections ) {
          desired_sections != null ? await download_report( desired_sections ) : undefined;
          dowloadReportModal.close();
        },
        onCancel() {
          dowloadReportModal.close();
        },
        onClosed() {
          downloadReportBtn.value.stopLoading();
        }
      }
    } );
    dowloadReportModal.open();
  };

  const download_report = async ( desired_sections ) => {
    if ( desired_sections != null ) {
      let url = API.get_api( API.API_NAMES.RP_DOWNLOAD_REPORT );
      await AXIOS.post( url, { id_rp: props.rp.id, locale: store.getters.getLocale, desired_sections: desired_sections }, { headers: { 'auth-token': store.getters.getToken }, responseType: 'blob' } )
        .then( ( response ) => {
          if ( response.status == '200' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            let content = response.headers[ 'content-type' ];
            download( response.data, 'RP_' + props.rp.index + '_Report', content );
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };
</script>

<style scoped lang="scss">
</style>