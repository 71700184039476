<template>
  <div class="popup max-w-[95vw] min-w-[70vw]">
    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>
    <div class="flex justify-center">
      <div class="has-tooltip flex flex-row w-fit text-2xl font-bold text-center">
        <p>{{ t( 'Tools-IsIsNot-method' ) }}</p>
        <font-awesome-icon class="pl-1" icon="fa-solid fa-circle-info" style="color: #2563eb;" size="xs"/>
        <span class='tooltip'>{{ t( 'Tools-IsIsNot tooltip' )}}</span>
      </div>
    </div>
    <div class="text-xl -mx-3" :key="componentKey">
      <!-- Is/Is not list header -->
      <div class="grid grid-cols-5 items-center py-4 px-6 text-2xl font-bold">
        <FieldArea :field="''" readonly="true"/>
        <div class="has-tooltip flex flex-row w-fit text-2xl -ml-10 font-bold text-center">
          <p>{{ t( 'Is' ) }}</p>
          <font-awesome-icon class="pl-1" icon="fa-solid fa-circle-info" style="color: #2563eb;" size="xs"/>
          <span class='tooltip'>{{ t( 'Is tooltip' )}}</span>
        </div>
        <div class="has-tooltip flex flex-row w-fit text-2xl -ml-9 font-bold text-center">
          <p>{{ t( 'Is not' ) }}</p>
          <font-awesome-icon class="pl-1" icon="fa-solid fa-circle-info" style="color: #2563eb;" size="xs"/>
          <span class='tooltip'>{{ t( 'Is not tooltip' )}}</span>
        </div>
        <p class="-ml-10">{{ t( 'Particularity' ) }}</p>
        <p class="-ml-9">{{ t( 'Change' ) }}</p>
      </div>
      <!-- Is/Is not items list -->
      <div class="flex flex-col overflow-y-auto divide-y-2 px-6 max-h-[70vh]" :class="!isIsNot || isIsNot.length === 0 ? 'border-2 rounded-xl mx-6' : ''">
        <div v-for="row in utils.sortListByRegisterDateAscending( isIsNot )" :key="row" class="flex py-3 items-center gap-7">
          <Field class="w-[14vw] max-w-[14vw] text-xl" :field="row.label"
            :fkey="row.id + '&&label'" :readonly="readonly"
            :placeholder="t( 'Label' )" @change="changeRow"
            :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_LABEL"/>
          <FieldArea class="cell" :field="row.is" :fkey="row.id + '&&is'" :readonly="readonly"
            :placeholder="row.is_desc != null && row.is_desc != '' ? row.is_desc : t( 'Is' )"
            @change="changeRow"
            :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_IS"/>
          <FieldArea class="cell" :field="row.is_not" :fkey="row.id + '&&is_not'" :readonly="readonly"
            :placeholder="row.is_not_desc != null && row.is_not_desc != '' ? row.is_not_desc : t( 'Is not' )"
            @change="changeRow"
            :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_IS_NOT"/>
          <FieldArea class="cell" :field="row.particularities" :fkey="row.id + '&&particularities'" :readonly="readonly"
            :placeholder="row.particularities_desc != null && row.particularities_desc != '' ? row.particularities_desc : t( 'Particularity' )"
            @change="changeRow"
            :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_PARTICULARITIES"/>
          <FieldArea class="cell" :field="row.changes" :fkey="row.id + '&&changes'" :readonly="readonly"
            :placeholder="row.changes_desc != null && row.changes_desc != '' ? row.changes_desc : t( 'Change' )"
            @change="changeRow"
            :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_CHANGES"/>
          <div class="hover:bg-tertiary-hover rounded-full" @click="clickRemoveItem( row.id )">
            <button><font-awesome-icon icon="fa-solid fa-xmark" size="lg" class="px-2 py-1"/></button>
          </div>
        </div>
        <!-- List PLACEHOLDER -->
        <div v-if="!isIsNot || isIsNot.length === 0"
          class="my-3 w-full flex justify-center">
            {{ t( 'Empty' ) }}
        </div>
      </div>
    </div>
    <div v-if="!readonly" class="grid justify-items-end mt-4">
      <!-- New Is/Is not item button -->
      <button class="inline-flex justify-center items-center h-8
                bg-primary hover:bg-primary-hover
                text-primary-text
                  font-bold px-4 rounded"
              @click="newLine">{{ t('Add a section') }}</button>
    </div>
  </div>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import FieldArea from '@/components/object/FieldArea.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import API from '@/constants/api.constants';
  import { useModal } from 'vue-final-modal';
  import ConfirmModal from '@/components/modals/ConfirmModal.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    isIsNotData: { Object, required: true },
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits( [ 'click-close', 'changeIsIsNotList', 'removeIsIsNotItem', 'changeIsIsNotItem' ] );

  const { t } = useI18n();
  const isIsNot = ref();
  const idRP = ref();
  const rowToRemoveId = ref( '' );

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  watchEffect( () => ( isIsNot.value = props.isIsNotData ) );
  watchEffect( () => ( idRP.value = props.id_rp ) );

  const clickClose = () => {
    emit( 'click-close' );
  };

  const removeItem = ( id ) => {
    if ( id != null && id != '' ) {
      let url = API.get_api( API.API_NAMES.TOOL_IS_ISNOT, id );
      AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          if ( response.status == '201' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            emit( 'removeIsIsNotItem', id );
          } else {
            forceRerender();
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          forceRerender();
          utils.showCatch( err );
        } );
    }
    rowToRemoveId.value = '';
  };

  const clickRemoveItem = ( id ) => {
    if ( id != null && id != '' ) {
      rowToRemoveId.value = id;
      deleteRowConfirmModal.open();
    }
  };

  const deleteRowConfirmModal = useModal( {
    component: ConfirmModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      header: t( 'Is Is not row deletion HEADER' ),
      body: t( 'Is Is not row deletion BODY' ),

      onCancel() {
        deleteRowConfirmModal.close();
      },
      async onConfirm() {
        if ( rowToRemoveId.value != null && rowToRemoveId.value != '' ) {
          removeItem( rowToRemoveId.value );
        }
        deleteRowConfirmModal.close();
      }
    }
  } );

  const newLine = () => {
    if ( idRP.value != null && idRP.value != '' ) {
      let url = API.get_api( API.API_NAMES.TOOL_IS_ISNOT );
      AXIOS.post( url, { id_rp: idRP.value, label: '' }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          if ( response.status == '200' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            emit( 'changeIsIsNotList' );
          } else {
            forceRerender();
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          forceRerender();
          utils.showCatch( err );
        } );
    }
  };

  const changeRow = ( fkey, newValue ) => {
    if ( newValue != null && fkey != null && fkey != '' ) {
      let tampon = fkey.split( '&&' );
      if ( tampon != null && Array.isArray( tampon ) && tampon.length === 2 ) {
        let rowId = tampon[ 0 ];
        let fkey = tampon[ 1 ];
        if ( rowId != null && rowId != '' && fkey != null && fkey != '' ) {
          let url = API.get_api( API.API_NAMES.TOOL_IS_ISNOT, rowId );
          let body = {};
          body[ fkey ] = newValue;
          AXIOS.put( url, body, { headers: { 'auth-token': store.getters.getToken } } )
            .then( ( response ) => {
              if ( response.status == '201' ) {
                store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
                emit( 'changeIsIsNotItem', rowId );
              } else {
                forceRerender();
              }
              utils.showAxiosError( response );
            } )
            .catch( ( err ) => {
              forceRerender();
              utils.showCatch( err );
            } );
        }
      }
    }
  };
</script>
<style scoped>
  .cell {
    @apply  w-[16vw] max-w-[16vw] text-lg
  }
</style>