<template>
  <div class="popup popup-w-2xl">
    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>
    <div class="flex-col space-y-4">
      <h1 class="text-center text-2xl font-bold justify-center has-tooltip">
        {{ t( 'Tools-Context' ) }}
        <font-awesome-icon class="pb-2" icon="fa-solid fa-circle-info" style="color: #2563eb;" size="xs"/>
        <span class="tooltip">{{ t( 'Tools-Context tooltip' )}}</span>
      </h1>
      <h1 class="text-center font-bold text-xl">{{ title }}</h1>
      <FieldArea :placeholder="t( 'Tools-Context' ) + '...'"
        custom_class="rounded min-h-[25rem] min-w-[70rem] p-1 text-xl"
        :field="tool_context.context" :readonly="readonly"
        @change="changeField" :key="componentKey"
        :maxlength="MAX_INPUT_LENGTH.TOOL_CONTEXT"/>
    </div>
  </div>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import moment from 'moment-timezone';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import FieldArea from '@/components/object/FieldArea.vue';
  import API from '@/constants/api.constants';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    context: { Object, required: true },
    title: { String, required: true },
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits( [ 'click-close', 'update' ] );
  const { t } = useI18n();
  const tool_context = ref( );
  const idRP = ref();

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  watchEffect( () => ( tool_context.value = props.context ) );
  watchEffect( () => ( idRP.value = props.id_rp ) );

  const init = () => {
    moment.locale( store.getters.getLocale );
    if ( !tool_context.value ) {
      tool_context.value = {};
    }
  };

  const clickClose = () => {
    emit( 'click-close' );
  };

  const update = () => {
    emit( 'update', tool_context.value );
  };

  const changeField = ( fkey, val ) => {
    tool_context.value.context = val;
    let axiosCallback = AXIOS.put;
    let axiosUrlId = tool_context.value.id;
    let body = {
      context: tool_context.value.context
    };
    if ( !tool_context.value.id ) {
      axiosCallback = AXIOS.post;
      body.id_rp = idRP.value;
      axiosUrlId = '';
    }
    delete body.id;
    let url = API.get_api( API.API_NAMES.TOOL_CONTEXT, axiosUrlId );
    axiosCallback( url, body, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        if ( response.status == '200' ) {
          tool_context.value.id = response.data.id;
        } else if ( response.status != '201' ) {
          forceRerender();
        }
        update();
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  init();
</script>