<template>
  <!-- <div class="has-tooltip"> -->
    <button class="inline-flex justify-center items-center w-8 h-8
                bg-gray-200 text-secondary-text font-bold rounded noblur"
            :class="css"
            type="button" :disabled="loading" @click="startLoading()">
      <span v-if="!loading" class="text-xl" ><font-awesome-icon :icon="icon" /></span>
      <ClipLoader v-if="loading" color="#000000" size="1.3rem" class="inline-flex"></ClipLoader>
    </button>
    <!-- <span v-if="tooltipContent && tooltipContent != ''" class='tooltip'>{{ t( tooltipContent ) }}</span> -->
  <!-- </div> -->
</template>

<script setup>
  import { ref } from 'vue';
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  const props = defineProps( {
    icon: { String, required: true },
    disableLoading: { Boolean, default: false },
    css: { String, require: false, default: 'hover:bg-primary-hover' },
    tooltipContent: { String, require: false, default: '' }
  } );

  const loading = ref ( false );

  const startLoading = () => {
    if ( props.disableLoading == 'false' || props.disableLoading == false ) {
      loading.value = true;
    }
  };

  const stopLoading = () => {
    loading.value = false;
  };

  defineExpose( { startLoading, stopLoading } );
</script>
<style lang="scss" scoped>
  .has-tooltip:hover .tooltip {
      @apply visible opacity-100 z-50 -mx-12 my-9;
    }
</style>
