<template>
  <div class="text-sm bg-white shadow shadow-shadowColor rounded group mb-4">
    <!-- Expander Header -->
    <div class="focus-visible:outline-none rounded">
      <div @click="toggleExpander" class="flex flex-row content-center items-center space-x-3 px-4 py-2 -mb-1 text-lg rounded hover:bg-tertiary-hover transition duration-200">
        <div class="pt-0.5 -ml-0.5 pr-0.5 flex-none">
          <img src="@/../public/img/capitalising_logo.png" class="object-contain h-6 w-6">
        </div>
        <div class="font-bold">{{ t( 'Capitalising actions' ) }}</div>
        <button v-if="!readonly" @click.stop="addAction" :disabled="isBusy"
          class="flex flex-col has-tooltip  text-xxl rounded-full align-center justify-center"
          :class="!isBusy ? 'hover:bg-fourth-hover text-primary' : 'text-fourth-hover'">
          <font-awesome-icon icon="fa-solid fa-plus" size="xl" class="px-1 py-0"/>
          <span class='tooltip mb-8'>{{ t( 'Add a capitalising action' ) }}</span>
        </button>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Capitalising actions total' )}}</span>
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-gray-500 text-white" :key="componentKey">{{ getIdentifiedActionsTotal() }}</span>
        </div>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Planned capitalising actions' )}}</span>
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-purple-600 text-white" :key="componentKey">{{ getPlannedActionsTotal() }}</span>
        </div>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Completed capitalising actions' )}}</span>
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-blue-500 text-white" :key="componentKey">{{ getCompletedActionsTotal() }}</span>
        </div>
        <SortAndFilterOptions
          :sortOptions="sortOptions"
          :sortSelected="sortOptionSelected"
          :filterOptions="filterOptions"
          :filterSelected="filterOptionSelected"
          @changeSortOption="changeSortOption"
          @changeFilterOption="changeFilterOption"/>
        <div class="flex flex-row">
          <font-awesome-icon v-if="isToggled" icon="fa-solid fa-chevron-right" rotation="90" size="xl"/>
          <font-awesome-icon v-else icon="fa-solid fa-chevron-right" size="xl" />
        </div>
      </div>
    </div>
    <!-- Expander Body -->
    <Collapse :when="isToggled" class="collapse-class">
      <div class="float-right flex flex-row gap-x-4 -mb-2 mt-1 mr-4">
        <div class="p-1 font-bold text-blue-500 hover:bg-tertiary-hover">
          <button v-if="!readonly" @click="openTemplateModal">{{ t( 'Type list' ) }}</button>
        </div>
      </div>
      <div class="flex flex-wrap gap-4 p-4 w-full" :key="componentKey">
        <RPCapitalisingActionItem v-for="action in utils.sortActions( sortOptionSelected, filterActions( capitalisingActions ) )" :key="action.id"
          class="flex-auto"
          :actions="actions" :users="users" :capitalisingAction="action" :readonly="readonly" @changeItem="changeItem" @removeItem="removeItem"/>
      </div>
    </Collapse>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import RPCapitalisingActionItem from '@/components/rp/capitalising_actions/RPCapitalisingActionItem.vue';
  import API from '@/constants/api.constants';
  import SortAndFilterOptions from '@/components/object/SortAndFilterOptions.vue';
  import settings from '@/features/settings';
  import CapitalisingActionTemplatesModal from '@/components/modals/rp_modals/CapitalisingActionTemplatesModal.vue';
  import { useModal } from 'vue-final-modal';
  import SORT from '@/constants/sort.constants';
  import FILTER from '@/constants/filter.constants';

  const props = defineProps( {
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false },
    capitalisingActions: { Array, required: true },
    users: { Array, required: true, default: null },
    actions: { Array, required: true, default: null }
  } );
  const emit = defineEmits( [ 'changeList', 'changeItem', 'removeItem' ] );

  const { t } = useI18n();
  const isToggled = ref( store.getters.getExpanders.capitalisingActions ?? true );

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };
  const isBusy = ref( false );

  const sortOptionSelected = ref( store.getters.getCapitalisingActionsOptions.sort ?? SORT.REGISTER_DATE_DESC );
  const sortOptions = ref( [
    SORT.REGISTER_DATE_ASC,
    SORT.REGISTER_DATE_DESC,
    SORT.LAST_ACTIVITY_ASC,
    SORT.LAST_ACTIVITY_DESC,
    SORT.TITLE_DESC,
    SORT.TITLE_ASC
  ].map( ( item ) => {
    return { val: item, text: t( item ) };
  } ) );
  const filterOptionSelected = ref( store.getters.getCapitalisingActionsOptions.filter ?? FILTER.ALL );
  const filterOptions = ref( [
    FILTER.ALL,
    FILTER.WITH_PILOT,
    FILTER.WITHOUT_PILOT,
    FILTER.IDENTIFIED,
    FILTER.PLANNED,
    FILTER.COMPLETED
  ].map( ( item ) => {
    return { val: item, text: t( item ) };
  } ) );

  const changeSortOption = ( newOption ) => {
    sortOptionSelected.value = newOption;
    store.dispatch( 'setCapitalisingActions', { sort: newOption } );
  };
  const changeFilterOption = ( newOption ) => {
    filterOptionSelected.value = newOption;
    store.dispatch( 'setCapitalisingActions', { filter: newOption } );
  };

  const isIdentifiedAction = ( action ) => {
    return action.action_users == null ||
      ( Array.isArray( action.action_users ) &&
        ( action.action_users.length == 0 ||
          (
            ( action.action_users[ 0 ].completed_date == null ||
              action.action_users[ 0 ].completed_date == '' ) &&
            ( action.action_users[ 0 ].delivery_date == null ||
              action.action_users[ 0 ].delivery_date == '' )
          )
        )
      );
  };

  const isPlannedAction = ( action ) => {
    return action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length > 0 &&
      (
        action.action_users[ 0 ].delivery_date != null &&
        action.action_users[ 0 ].delivery_date != '' &&
        ( action.action_users[ 0 ].completed_date == null ||
          action.action_users[ 0 ].completed_date == '' )
      );
  };

  const isCompletedAction = ( action ) => {
    return action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length > 0 &&
      (
        action.action_users[ 0 ].delivery_date != null &&
        action.action_users[ 0 ].delivery_date != '' &&
        action.action_users[ 0 ].completed_date != null &&
        action.action_users[ 0 ].completed_date != '' &&
        action.action_users[ 0 ].is_efficient != null &&
        action.action_users[ 0 ].is_efficient == false
      );
  };

  const getIdentifiedActionsTotal = () => {
    let total = props.capitalisingActions != null && Array.isArray( props.capitalisingActions ) ? props.capitalisingActions.length : 0;
    return total;
  };

  const getPlannedActionsTotal = () => {
    let total = props.capitalisingActions != null && Array.isArray( props.capitalisingActions ) ? props.capitalisingActions.filter( ( item ) => isPlannedAction( item ) ).length : 0;
    return total;
  };

  const getCompletedActionsTotal = () => {
    let total = props.capitalisingActions != null && Array.isArray( props.capitalisingActions ) ? props.capitalisingActions.filter( ( item ) => isCompletedAction( item ) ).length : 0;
    return total;
  };

  const changeList = () => {
    emit( 'changeList' );
  };

  const changeItem = ( id ) => {
    emit( 'changeItem', id );
  };

  const removeItem = ( id ) => {
    emit( 'removeItem', id );
  };

  const addAction = () => {
    let url = API.get_api( API.API_NAMES.RP_CAPITALISING_ACTION, props.id_rp );
    let body = {
      title: t( 'Title PLACEHOLDER' )
    };
    isBusy.value = true;

    AXIOS.post( url, body, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        utils.analyse_axios_request( response );
        if ( response.status == '200' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeList();
        } else {
          forceRerender();
        }
        isBusy.value = false;
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        isBusy.value = false;
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const toggleExpander = () => {
    store.dispatch( 'setExpanders', { capitalisingActions: !isToggled.value } );
    isToggled.value = store.getters.getExpanders.capitalisingActions;
  };
  defineExpose( { toggleExpander, isToggled } );

  const openTemplateModal = async () => {
    actionTemplateModal.patchOptions( {
      attrs: {
        id_rp: props.id_rp,
        templates: utils.sortListByRegisterDateAscending( await settings.getOneSetting( 'capitalisation_template' ) ?? [] )
      }
    } );
    actionTemplateModal.open();
  };

  const actionTemplateModal = useModal( {
    component: CapitalisingActionTemplatesModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      readonly: props.readonly,
      id_rp: props.id_rp,
      templates: [],
      onCancel() {
        actionTemplateModal.close();
      },
      onAddActions( newItems ) {
        if ( newItems === true || newItems === 'true' ) {
          changeList();
        }
        actionTemplateModal.close();
      }
    }
  } );

  const filterActions = ( actionsList ) => {
    if ( actionsList != null && Array.isArray( actionsList ) ) {
      switch ( filterOptionSelected.value ) {
      case ( FILTER.ALL ):
        return actionsList;
      case ( FILTER.WITH_PILOT ):
        return actionsList.filter( ( action ) => {
          return ( action.action_users != null
            && Array.isArray( action.action_users )
            && action.action_users.length > 0 );
        } );
      case ( FILTER.WITHOUT_PILOT ):
        return actionsList.filter( ( action ) => {
          return action.action_users == null ||
            ( action.action_users != null
              && Array.isArray( action.action_users )
              && action.action_users.length == 0 );
        } );
      case ( FILTER.IDENTIFIED ):
        return actionsList.filter( ( action ) => isIdentifiedAction( action ) );
      case ( FILTER.PLANNED ):
        return actionsList.filter( ( action ) => isPlannedAction( action ) );
      case ( FILTER.COMPLETED ):
        return actionsList.filter( ( action ) => isCompletedAction( action ) );
      default:
        return actionsList;
      }
    }
    return [];
  };
</script>