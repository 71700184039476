<template>
  <div class="w-full h-full p-20" :style="bg_image_dynamic" >
    <div class="w-full max-w-xs m-auto bg-card-sing-in rounded-xl p-5">
      <div>
        <img class="h-15 mx-auto mb-5" src="@/../public/img/base_logo_transparent_background.png" />
      </div>
      <div>
        <SignIn v-show="displayComponent == enumDisplayComponent.SIGNIN" @custom-event-name="changeComponent"></SignIn>
        <GetCode v-show="displayComponent == enumDisplayComponent.GET_CODE" @custom-event-name="changeComponent"></GetCode>
        <VerifyCode v-show="displayComponent == enumDisplayComponent.VERIFY_CODE" @custom-event-name="changeComponent" :user_id="user_id"></VerifyCode>
        <ChangePassword v-show="displayComponent == enumDisplayComponent.CHANGE_PWD" @custom-event-name="changeComponent" :user_id="user_id" :code="code"></ChangePassword>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import SignIn from '@/components/sign-in/SignIn';
  import GetCode from '@/components/sign-in/GetCode';
  import VerifyCode from '@/components/sign-in/VerifyCode';
  import ChangePassword from '@/components/sign-in/ChangePassword';
  import enumDisplayComponent from '@/enum/enumDisplayComponent.js';

  const displayComponent = ref( enumDisplayComponent.SIGNIN );
  const user_id = ref( '' );
  const code = ref( '' );

  const bg_image_dynamic = computed( () => {
    let num = Math.floor( Math.random() * Math.floor( 4 ) ) + 1 ;
    let random = ( '000' + num ).slice( -3 );
    let bgImg = require( '@/../public/background/background-' + random + '.jpg' );
    return {
      'background': 'url(' + bgImg + ')',
      'background-size': 'cover'
    };
  } );

  const changeComponent = ( payload ) => {
    user_id.value = payload.id;
    code.value = payload.code;
    displayComponent.value = payload.display;
  };
</script>

<style scoped lang="scss">
  .bg-card-sing-in {
    background: #dfdbdb;
    box-shadow: 0 5px 5px rgba(0,0,0,.4);
    background-color: #dfdbdb;
  }
</style>