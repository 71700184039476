<template>
  <div class="flex flex-col text-base text-secondary-text w-full">
    <div v-if="title" class="font-bold">{{ title }}</div>
    <p v-if="!editable" @click="setEditable" class="break-words flex-auto w-full"
      :class="[ readonly ? '' : 'hover:bg-tertiary-hover cursor-pointer' ].concat( 'min-h-[1.5rem]', fieldValue != null && fieldValue != '' ? '' : 'text-gray-400', custom_class )">
      {{ fieldValue != null && fieldValue != '' ? fieldValue : placeholder }}
    </p>
    <div class="flex gap-x-2 flex-auto" v-if="editable">
      <div class="flex w-full relative">
        <textarea ref="editableField" class="flex-auto text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100" :class="custom_class"
          :maxlength="maxlength > -1 ? maxlength : null" name="field-value" v-model="editFieldValue" :placeholder="placeholder"
          @blur="blur"
          @keydown.enter.exact.prevent="changeValue"
          @focus="selectContent"/>
          <span v-if="maxlength !== -1"
            class="noblur absolute bottom-1 right-5 text-xs text-secondary-text text-white font-bold bg-gray-600 rounded px-1"
            :class="editFieldValue.length >= maxlength ? 'bg-secondary-hover' : undefined">
            {{ editFieldValue.length + '/' + maxlength }}
          </span>
      </div>
      <ValidEditButton class="self-end noblur" @click="changeValue"></ValidEditButton>
      <CancelEditButton class="self-end noblur" @click="editable=false"></CancelEditButton>
    </div>
  </div>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import ValidEditButton from '@/components/buttons/ValidEditButton.vue';
  import CancelEditButton from '@/components/buttons/CancelEditButton.vue';

  const props = defineProps( {
    title: { String, required: false },
    field: { String, required: true },
    placeholder: { String, required: false, default: '' },
    fkey: { String, required: false },
    custom_class: { String, required: false, default: '' },
    readonly: { Boolean, required: false, default: false },
    maxlength: { Number, required: false, default: -1 }
  } );
  const emit = defineEmits( [ 'change' ] );

  const editable = ref ( false );
  const editableField = ref( null );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const fieldValue = ref( props.field );
  const editFieldValue = ref( '' );

  const setEditable = () => {
    if ( !props.readonly ) {
      editable.value = true;
      editFieldValue.value = fieldValue.value ?? '';
      nextTick( () => {
        editableField.value.focus();
      } );
    }
  };

  const selectContent = ( event ) => {
    if ( event != null && event.target != null ) {
      event.target.select();
    }
  };

  const changeValue = () => {
    if ( fieldValue.value != editFieldValue.value ) {
      fieldValue.value = editFieldValue.value;
      emit( 'change', props.fkey, fieldValue.value );
    }
    editable.value = false;
  };

  const blur = ( event ) => {
    if ( event.relatedTarget && event.relatedTarget.className.includes( 'noblur' ) ) {
      event.preventDefault = false;
    } else {
      editable.value = false;
      changeValue();
    }
  };
</script>