<template>
  <div class="w-full h-full p-2">
    <div class="flex justify-center items-center h-full" v-if="!rp">
      <div class="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-primary to-primary-hover ">
          <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-white rounded-full border-2 border-white"></div>
      </div>
    </div>
    <div class="relative" v-if="rp">
      <div class="flex space-x-4 m-4">
        <button @click="goBack"
          class="px-4 py-2 rounded-full bg-gray-100 text-gray-600 font-bold text-xl transition duration-500 hover:bg-primary hover:bg-opacity-40">
          <font-awesome-icon icon="fa-solid fa-arrow-right" flip="horizontal" size="2xl" style="color: gray"/>
          {{ t( 'Go Back' ) }}
        </button>
        <div class="text-2xl">
          <p>{{ t( 'RP Number' ) }} {{ rp.number }}</p>
          <div class="text-4xl font-bold">
            {{ rp.title }}
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center">
        <div class="flex flex-wrap justify-center gap-4">
          <RPProgressPieChart v-if="progressChartData != null && rp != null" :rp="rp" :data="progressChartData"></RPProgressPieChart>
          <RPReactivityIndicator v-if="reactivityData != null" :data="reactivityData"></RPReactivityIndicator>
          <RP7mParetoChart v-if="paretoCausesData != null" :data="paretoCausesData"></RP7mParetoChart>
          <RPActionsPieChart v-if="actionsChartData != null" :data="actionsChartData"></RPActionsPieChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import utils from '@/features/utils.js';
  import { useRoute } from 'vue-router';
  import store from '@/store';
  import { useI18n } from 'vue-i18n';
  import router from '@/router';
  import API from '@/constants/api.constants';
  import AXIOS from '@/features/axios.js';

  import RPProgressPieChart from '@/components/indicators/rp_indicators/RPProgressPieChart.vue';
  import RP7mParetoChart from '@/components/indicators/rp_indicators/RP7mParetoChart.vue';
  import RPReactivityIndicator from '@/components/indicators/rp_indicators/RPReactivityIndicator.vue';
  import RPActionsPieChart from '@/components/indicators/rp_indicators/RPActionsPieChart.vue';

  const route = useRoute();
  const { t } = useI18n();
  const progressChartData = ref( null );
  const reactivityData = ref( null );
  const paretoCausesData = ref( null );
  const actionsChartData = ref( null );
  const rp = ref( null );

  watch( route, () => {
    getIndicatorsData();
  } );

  const goBack = () => {
    router.back();
  };

  const getIndicatorsData = () => {
    if ( route.params.id != null ) {
      let url = API.get_api( API.API_NAMES.RP_INDICATORS, route.params.id );
      let promises = [];
      promises.push( new Promise ( ( resolve ) => AXIOS.get( url + 'progression_rp', { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          progressChartData.value = response.data.progressChart;
          rp.value = response.data.rp;
          utils.showAxiosError( response );
          resolve();
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
          resolve();
        } ) ) );

      promises.push( new Promise ( ( resolve ) => AXIOS.get( url + 'reactivity', { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          reactivityData.value = response.data;
          utils.showAxiosError( response );
          resolve();
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
          resolve();
        } ) ) );

      promises.push( new Promise ( ( resolve ) => AXIOS.get( url + 'pareto_causes/' + store.getters.getLocale, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          paretoCausesData.value = response.data;
          utils.showAxiosError( response );
          resolve();
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
          resolve();
        } ) ) );

      promises.push( new Promise ( ( resolve ) => AXIOS.get( url + 'breakdown_actions', { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          actionsChartData.value = response.data;
          utils.showAxiosError( response );
          resolve();
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
          resolve();
        } ) ) );

      Promise.all( promises );
    }
  };

  getIndicatorsData();
</script>