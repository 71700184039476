<template>
  <button class="inline-flex justify-center items-center w-8 h-8
                  bg-primary hover:bg-primary-hover
                  text-secondary-text
                  font-bold px-5 rounded"
          type="button" :disabled="loading" @click="startLoading()">
    <span v-if="!loading" class="text-xl" ><font-awesome-icon :icon="icon"   style="color: white"></font-awesome-icon></span>
    <ClipLoader v-if="loading" color="#ffffff" size="1.3rem" class="inline-flex"></ClipLoader>
  </button>
</template>

<script setup>
  import { ref } from 'vue';
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  defineProps( {
    icon: { String, required: true },
    disableLoading: { Boolean, default: false }
  } );

  const loading = ref ( false );

  const startLoading = () => {
    loading.value = true;
  };

  const stopLoading = () => {
    loading.value = false;
  };

  defineExpose( { startLoading, stopLoading } );
</script>
