<template>
  <div>
    <div class="border border-indigo-600 rounded px-1" v-on:click="seen = !seen">
      <div class="flex flex-row locale-changer ">
        <div>
          <img class="max-w-[1.75em] max-h-[1.75em]" :src="require(`../../../public/flags/${locale}.png`)" />
        </div>
        <div>
          <ChevronDownIcon class="h-5 w-5 text-white inline-block align-middle" />
        </div>
      </div>
    </div>
    <div v-if="seen" class="relative h-15 w-13 pl-4 mb-2 left-0 top-0 bg-white border border-black text-black rounded">
      <div v-for="(lang, i) in langs" :key="`Lang${i}`" class="flex flex-row" v-on:click="seen = !seen; locale = `${lang.key}`; change( `${lang.key}` )">
        <img  class="max-w-[1.75em] max-h-[1.75em]" :src="require(`../../../public/flags/${lang.key}.png`)" />
        <span class="mx-2">{{ lang.name }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { ChevronDownIcon } from '@heroicons/vue/24/solid';
  import store from '../../store';

  const langs = ref( [ { key: 'en', name: 'English' }, { key: 'fr', name: 'Français' } ] );
  const { locale } = useI18n();
  const seen = ref( false );
  locale.value = store.getters.getLocale;

  const change = ( language ) => {
    store.dispatch( 'setLocale', { locale: language } );
  };
</script>
