<template>
  <div class="flex w-full relative">
    <input ref="editableField" class="settings-label"
      :maxlength="maxlength > -1 ? maxlength : null" name="field-value" v-model="editFieldValue" :placeholder="placeholder"
      @keyup.enter.exact.prevent="changeValue" @blur="blur"
      @focus="selectContent"/>
    <span v-if="isFocus"
      class="noblur absolute bottom-1 right-1 z-10 text-xs text-secondary-text font-bold text-white bg-gray-600 rounded px-1"
      :class="editFieldValue.length >= maxlength ? 'bg-secondary-hover' : undefined">
      {{ editFieldValue.length + '/' + maxlength }}
    </span>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    field: { String, required: true },
    placeholder: { String, required: false, default: '' },
    fkey: { String, required: false },
    maxlength: { Number, required: false, default: MAX_INPUT_LENGTH.SETTING_VALUE }
  } );
  const emit = defineEmits( [ 'change' ] );

  const isFocus = ref( false );
  const editableField = ref( null );
  const fieldValue = ref( props.field );
  const editFieldValue = ref( '' );

  onMounted( () => {
    editFieldValue.value = fieldValue.value ?? '';
  } );

  const selectContent = ( event ) => {
    isFocus.value = true;
    if ( event != null && event.target != null ) {
      event.target.select();
    }
  };

  const changeValue = () => {
    if ( fieldValue.value != editFieldValue.value ) {
      fieldValue.value = editFieldValue.value;
      emit( 'change', props.fkey, fieldValue.value );
    }
    editableField.value.blur();
    isFocus.value = false;
  };

  const blur = ( event ) => {
    if ( event.relatedTarget && event.relatedTarget.className.includes( 'noblur' ) ) {
      event.preventDefault = false;
    } else {
      isFocus.value = false;
      changeValue();
    }
  };
</script>

<style scoped lang="scss">
.settings-label {
    @apply bg-gray-200;
    @apply appearance-none;
    @apply border-2;
    @apply border-gray-200;
    @apply rounded;
    @apply w-full;
    @apply py-2;
    @apply px-4;
    @apply text-gray-700;
    @apply leading-tight;
    @apply focus:outline-none;
    @apply focus:bg-white;
    @apply focus:border-purple-500;
  }
</style>