<template>
  <fieldset class="min-w-[350px] h-fit border shadow-lg opacity-95 rounded-xl space-y-4 rounded p-4 -ml-[1px]" v-if="selectedUser">
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="flex h-full w-full sm:col-span-6 justify-between">
          <span class="font-bold"> {{ t( 'Modify' ) }} </span>
          <font-awesome-icon icon="fa-solid fa-xmark" class="fa-xl cursor-pointer" @click="close()" />
      </div>
      <div class="sm:col-span-3">
        <label for="first-name" class="block text-lg font-medium leading-6 text-gray-900">{{ t( 'Firstname' ) }}</label>
        <div class="mt-2">
          <Field :fkey="selectedUser.id" :field="selectedUser.firstname" :readonly="true"
            @change="( id, firstname ) => updateUser( id, { firstname } )"
            class="border rounded-lg p-1"
            :maxlength="MAX_INPUT_LENGTH.USER_FIRSTNAME"/>
        </div>
      </div>

      <div class="sm:col-span-3">
        <label for="last-name" class="block text-lg font-medium leading-6 text-gray-900">{{ t( 'Lastname' ) }}</label>
        <div class="mt-2">
          <Field :fkey="selectedUser.id" :field="selectedUser.lastname" :readonly="true"
          @change="( id, lastname ) => updateUser( id, { lastname } )"
          class="border rounded-lg p-1"
          :maxlength="MAX_INPUT_LENGTH.USER_LASTNAME"/>
        </div>
      </div>

      <div class="sm:col-span-6">
        <label for="email" class="block text-lg font-medium leading-6 text-gray-900">{{ t( 'Email' ) }}</label>
        <div class="mt-2">
          <Field :fkey="selectedUser.id" :field="selectedUser.email" :readonly="true"
            @change="( id, email ) => updateUser( id, { email } )"
            class="border rounded-lg p-1"
            :maxlength="MAX_INPUT_LENGTH.USER_EMAIL"/>
        </div>
      </div>

      <div class="sm:col-span-4">
        <label class="block text-lg font-medium leading-6 text-gray-900">{{ t( 'Service' ) }}</label>
        <div class="mt-2">
          <InputSelect :items="services" :selected="selectedUser.id_service" :rkey="selectedUser.id" @change-value="( id_service, index, old_id_service, user_id ) => updateUser( user_id, { id_service } )"/>
        </div>
      </div>

      <div class="sm:col-span-4">
        <label for="email" class="block text-lg font-medium leading-6 text-gray-900">{{ t( 'Language' ) }}</label>
        <div class="mt-2">
          <InputSelect :items="locales" :selected="selectedUser.language" :rkey="selectedUser.id" @change-value="( language, index, old_id_service, user_id ) => updateUser( user_id, { language } )"/>
        </div>
      </div>

      <div class="sm:col-span-4">
        <label for="email" class="block text-lg font-medium leading-6 text-gray-900">{{ t( 'Timezone' ) }}</label>
        <div class="mt-2">
          <InputSelect :items="timezones" :selected="selectedUser.timezone" :rkey="selectedUser.id" @change-value="( timezone, index, old_id_service, user_id ) => updateUser( user_id, { timezone } )"/>
        </div>
      </div>

    </div>
  </fieldset>
</template>

<script setup>
  import { ref } from 'vue';
  import Field from '@/components/object/FieldBasic.vue';
  import InputSelect from '@/components/object/InputSelect.vue';
  import Constants from '@/constants/constants.js';
  import { useI18n } from 'vue-i18n';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';
  import moment from 'moment-timezone';

  const { t } = useI18n();
  const emit = defineEmits( [ 'update', 'close' ] );
  defineProps( {
    selectedUser: { Object, require: true },
    services: { Array, require: true }
  } );
  const locales = ref( Constants.localization.locales.map( ( local ) => { return { val: local, text: t( local ) }; } ) );
  const timezones = ref( moment.tz.names().map( ( timezone ) => { return { val: timezone, text: t( timezone ) }; } ) );

  function updateUser( id, body ) {
    emit( 'update', id, body );
  }

  function close() {
    emit( 'close' );
  }

</script>