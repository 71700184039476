<template>
  <div class="flex flex-col" ref="parent">
    <div class="flex w-full bg-stone-100 border border-1 border-b-0 border-gray-400 rounded-t font-bold select-none" v-if="sortStats.size == 5">
      <div class="w-[12%] p-2">
        <div class="w-fit h-fit flex gap-2 items-center cursor-pointer" @click="sortBy( Sort.Element.NUMBER )">
          <div v-html="t( 'Number' )"></div> <font-awesome-icon :icon="sortStats.get( Sort.Element.NUMBER )"/>
        </div>
      </div>
      <div class="w-[27%] p-2">
        <div class="w-fit h-fit flex gap-2 items-center cursor-pointer" @click="sortBy( Sort.Element.RP )">
          <div v-html="t( 'Resolution' )"></div> <font-awesome-icon :icon="sortStats.get( Sort.Element.RP )"/>
        </div>
      </div>
      <div class="w-[27%] p-2">
        <div class="w-fit h-fit flex gap-2 items-center cursor-pointer" @click="sortBy( Sort.Element.ACTION )">
          <div v-html="t( 'Action' )"></div> <font-awesome-icon :icon="sortStats.get( Sort.Element.ACTION )"/>
        </div>
      </div>
      <div class="w-[20%] p-2">
        <div class="w-fit h-fit flex gap-2 items-center cursor-pointer" @click="sortBy( Sort.Element.PILOTE )">
          <div v-html="t( 'Pilote' )"></div> <font-awesome-icon :icon="sortStats.get( Sort.Element.PILOTE )"/>
        </div>
      </div>
      <div class="w-[14%] p-2">
        <div class="w-fit h-fit flex gap-2 items-center cursor-pointer" @click="sortBy( Sort.Element.DATE )">
          <div v-html="t( 'Due date' )"></div> <font-awesome-icon :icon="sortStats.get( Sort.Element.DATE )"/>
        </div>
      </div>
    </div>

    <div v-if="props.array.length > 0" class="max-h-[400px] w-full border border-1 border-gray-400 rounded-b overflow-auto">
      <div v-for="item in props.array" :key="item" class="flex w-full border hover:bg-stone-50 cursor-pointer" @click="props.event( item )">
        <div class="w-[12%] py-2 px-1 truncate"> {{ item.rp_number }}</div>
        <div class="w-[27%] py-2 px-1 truncate"> {{ item.rp_title }}</div>
        <div class="w-[27%] py-2 px-1 truncate"> {{ item.action_title }}</div>
        <div class="w-[20%] py-2 px-1 truncate"> {{ item.action_pilote }}</div>
        <div class="w-[14%] py-2 px-1 truncate"> {{ item.action_delivery_date }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const Sort = {
    Element: {
      NUMBER: 'number',
      RP: 'rp',
      ACTION: 'action',
      PILOTE: 'pilote',
      DATE: 'date'
    },
    State: {
      NONE: 'fa-solid fa-sort',
      UP: 'fa-solid fa-sort-up',
      DOWN: 'fa-solid fa-sort-down'
    }
  };

  const props = defineProps( {
    array: { Array, require: true, default: [] },
    event: { Function, require: true }
  } );

  let parent = ref( null );
  let sortStats = ref( new Map() );

  onMounted( () => {
    waitForElm( '.cursor-pointer' ).then( () => {
      let delta = parent.value.firstChild.clientWidth - parent.value.lastChild.clientWidth;
      parent.value.firstChild.style.paddingRight = delta + 'px';
    } );
    initArray();
  } );

  function initArray() {
    let element = Sort.Element.DATE;
    resetSortState();
    sortStats.value.set( element, Sort.State.UP );
    sortBy( element );
  }

  function waitForElm( selector ) {
    return new Promise( ( resolve ) => {
      if ( document.querySelector( selector ) ) {
        return resolve( document.querySelector( selector ) );
      }

      const observer = new MutationObserver( () => {
        if ( document.querySelector( selector ) ) {
          observer.disconnect();
          resolve( document.querySelector( selector ) );
        }
      } );

      observer.observe( document.body, {
        childList: true,
        subtree: true
      } );
    } );
  }

  function sortBy( element ) {
    resetSortState( element );
    sortStats.value.get( element ) != Sort.State.UP ? sortStats.value.set( element, Sort.State.UP ) : sortStats.value.set( element, Sort.State.DOWN );
    let sortedArray = props.array;
    switch ( element ) {
    case Sort.Element.NUMBER:
      sortedArray.sort( ( item1, item2 ) => {
        let number1 = item1.rp_number.toUpperCase();
        let number2 = item2.rp_number.toUpperCase();
        if ( number1 < number2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? -1 : 1;
        }
        if ( number1 > number2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? 1 : -1;
        }
        return 0;
      } );
      break;
    case Sort.Element.RP:
      sortedArray.sort( ( item1, item2 ) => {
        let rp1 = item1.rp_title.toUpperCase();
        let rp2 = item2.rp_title.toUpperCase();
        if ( rp1 < rp2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? -1 : 1;
        }
        if ( rp1 > rp2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? 1 : -1;
        }
        return 0;
      } );
      break;
    case Sort.Element.ACTION:
      sortedArray.sort( ( item1, item2 ) => {
        let rp1 = item1.action_title.toUpperCase();
        let rp2 = item2.action_title.toUpperCase();
        if ( rp1 < rp2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? -1 : 1;
        }
        if ( rp1 > rp2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? 1 : -1;
        }
        return 0;
      } );
      break;
    case Sort.Element.PILOTE:
      sortedArray.sort( ( item1, item2 ) => {
        let pilote1 = item1.action_pilote.toUpperCase();
        let pilote2 = item2.action_pilote.toUpperCase();
        if ( pilote1 < pilote2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? -1 : 1;
        }
        if ( pilote1 > pilote2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? 1 : -1;
        }
        return 0;
      } );
      break;
    case Sort.Element.DATE:
      sortedArray.sort( ( item1, item2 ) => {
        let date1 = item1.action_delivery_date.toUpperCase();
        let date2 = item2.action_delivery_date.toUpperCase();
        if ( date1 < date2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? -1 : 1;
        }
        if ( date1 > date2 ) {
          return ( sortStats.value.get( element ) == Sort.State.UP ) ? 1 : -1;
        }
        return 0;
      } );
      break;
    }
  }

  function resetSortState( exept = null ) {
    if ( exept != Sort.Element.NUMBER ) sortStats.value.set( Sort.Element.NUMBER, Sort.State.NONE );
    if ( exept != Sort.Element.RP ) sortStats.value.set( Sort.Element.RP, Sort.State.NONE );
    if ( exept != Sort.Element.ACTION ) sortStats.value.set( Sort.Element.ACTION, Sort.State.NONE );
    if ( exept != Sort.Element.PILOTE ) sortStats.value.set( Sort.Element.PILOTE, Sort.State.NONE );
    if ( exept != Sort.Element.DATE ) sortStats.value.set( Sort.Element.DATE, Sort.State.NONE );
  }
</script>