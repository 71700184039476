<template>
  <div class="grid place-items-center h-screen">
    <div class="text-center">
      <div class="font-bold text-8xl">404</div>
      <div class="text-4xl">{{ t('Oops, this page not found!') }}</div>
      <br/>
      <LoadingButton :label="t('Go back home')" v-on:click="goToHome"/>
    </div>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import router from '@/router';
  import LoadingButton from '@/components/buttons/LoadingButton';

  const { t } = useI18n();

  const goToHome = () => {
    router.push( '/' );
  };
</script>
