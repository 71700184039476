<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-lg"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">
    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <!-- Modal header -->
    <div>
      <div class="bg-white flex flex-row justify-center">
        <h1 class="text-2xl font-bold text-center mb-2"> {{ t( 'Lists of why to create to causes') }} </h1>
      </div>
    </div>

    <!-- Modal Body -->
    <div class="space-y-4">
      <div class="flex flex-col items-start justify-center space-y-4">
        <div v-if="itemsCheckList.length > 0" class="flex space-x-3">
          <!-- Search field -->
          <input type="search" :placeholder="t( 'Search bar' ) + '...'" v-model="searchedValue"
          class="text-sm w-96 rounded p-0.5 border-2 border-gray-200"/>
          <!-- Radio buttons -->
          <div class="flex space-x-4 items-center accent-green-600/95">
            <div class="flex items-center space-x-1">
              <input type="radio" id="allItems" name="itemsFilter" @change="itemsFilter = 'allItems'"
              class="w-5 h-5 cursor-pointer" checked/>
              <label for="allItems" class="cursor-pointer">{{ t( 'All' ) }}</label>
            </div>
            <div class="flex items-center space-x-1">
              <input type="radio" id="selectItems" name="itemsFilter" @change="itemsFilter = 'selectItems'"
              class="w-5 h-5 cursor-pointer"/>
              <label for="selectItems" class="cursor-pointer">{{ t( 'Selected' ) }}</label>
            </div>
            <div class="flex items-center space-x-1">
              <input type="radio" id="unselectItems" name="itemsFilter" @change="itemsFilter = 'unselectItems'"
              class="w-5 h-5 cursor-pointer"/>
              <label for="unselectItems" class="cursor-pointer">{{ t( 'Unselected' ) }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Causes scroll list -->
      <div v-if="itemsCheckList.length > 0" class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
        <div v-for="item in searchFilterCauses()" :key="item" @click="check( item )"
          class="flex items-center pl-2 space-x-6 py-1 hover:bg-fourth-hover duration-200 border border-$c-divider border-gray-300 cursor-pointer">
          <input type="checkbox" :checked="item.checked"
            class="w-6 h-6 accent-green-600/95 rounded cursor-pointer">
          <div class="w-fit flex flex-row space-x-3 items-center">
            <div class="pt-1 pb-2" @click="check( item )"><Field :field="item.name" :readonly="true" @click="check( item )"></Field></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal footer-->
    <div class="flex space-x-4 pt-2 justify-end">
      <div>
        <LoadingButton ref="saveBtn" type="submit" :label="t( 'Create' )" @click="save"/>
      </div>
      <div>
        <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import Field from '@/components/object/FieldBasic.vue';
  import API from '@/constants/api.constants';

  const props = defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    _5WhyList: { Array, required: true, default: [] },
    id_rp: { String, required: true }
  } );
  const emit = defineEmits( [ 'save', 'cancel' ] );

  const { t } = useI18n();
  const saveBtn = ref( null );
  const searchedValue = ref( '' );
  const itemsFilter = ref( '' );

  const searchFilterCauses = () => {
    let result = itemsCheckList.value;
    if ( searchedValue.value != '' ) {
      let search = searchedValue.value.toLowerCase();

      result = itemsCheckList.value.filter( ( item ) =>
        ( item.name ? String( item.name ).toLowerCase().includes( search ) : false )
      );
    }
    switch ( itemsFilter.value ) {
    case 'selectItems':
      result = result.filter( ( item ) => ( item ? item.checked : false ) );
      break;
    case 'unselectItems':
      result = result.filter( ( item ) => ( item ? !item.checked : false ) );
      break;
    default:
      break;
    }
    return result;
  };

  const itemsCheckList = ref(
    props._5WhyList.map( ( item ) => {
      item.checked = true;
      return item;
    } )
  );

  const check = ( item ) => {
    if ( item != null && item.checked != null ) {
      item.checked = !item.checked;
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };

  const save = () => {
    let promises = [];
    let selectedItems = itemsCheckList.value.filter( ( item ) => ( item ? item.checked : false ) );

    selectedItems.forEach( ( item ) => {
      if ( item != null && item.name != null && item.name != '' ) {
        let url = API.get_api( API.API_NAMES.RP_CAUSES, props.id_rp );

        promises.push( AXIOS.post( url, { category: 1, position: 1, description: item.name }, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '200' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            }
            utils.showAxiosError( response );
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
          } ) );
      }
    } );

    Promise.all( promises )
      .then( () => {
        promises.length == 0 ? emit( 'save', false ) : emit( 'save', true );
        saveBtn.value.stopLoading();
      } );
  };
</script>