<template>
  <div class="bg-white shadow shadow-shadowColor rounded-xl p-4">
    <div class="flex w-fit h-fit justify-center">
      <VChart class="chart" :option="option" auto-resize ref="echart" :autoresize="true" @click="click7M"/>
    </div>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import { ref, onMounted } from 'vue';

  // Apache E-Charts mandatory modules
  import { use } from 'echarts/core';
  // import VChart from 'vue-echarts';
  // Apache E-Charts manually imported modules
  import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
  } from 'echarts/components';
  import { BarChart, LineChart } from 'echarts/charts';
  import { UniversalTransition } from 'echarts/features';
  import { CanvasRenderer } from 'echarts/renderers';

  import { useModal } from 'vue-final-modal';
  import CausesListModal from '@/components/modals/rp_modals/CausesListModal.vue';


  use( [
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    CanvasRenderer,
    UniversalTransition
  ] );

  const props = defineProps( {
    data: { Object, require: true }
  } );

  const { t } = useI18n();
  const interval = 5;
  let option = ref( {} );

  onMounted( () => {
    getDataIndicator();
  } );

  function getTotal() {
    let sum = 0;
    if ( props.data != null && props.data.length > 0 ) {
      for ( var i = 0 ; i < props.data.length ; i++ ) {
        sum += props.data[ i ].total_number;
      }
    }
    return sum;
  }

  function getMax() {
    let max = 0;
    props.data.forEach( ( element ) => {
      if ( element.total_number > max ) {
        max = element.total_number;
      }
    } );
    return max + ( interval - max % interval ) ;
  }

  const causesListModal = useModal( {
    component: CausesListModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      onCancel() {
        causesListModal.close();
      }
    }
  } );

  const isEventPopable = ( event ) => {
    return event != null && event.dataIndex != null
      && props.data != null && event.name != null
      && event.dataIndex < props.data.length
      && props.data[ event.dataIndex ].total_number != null
      && props.data[ event.dataIndex ].total_number > 0
      && props.data[ event.dataIndex ].causes != null
      && props.data[ event.dataIndex ].causes.link != null
      && props.data[ event.dataIndex ].causes.not_link != null;
  };

  const click7M = ( event ) => {
    if ( isEventPopable ( event ) ) {
      let axisName = event.name;
      let idx = event.dataIndex;
      let causes = props.data[ idx ].causes;

      causesListModal.patchOptions( {
        attrs: {
          axisName: axisName,
          causes_linked: causes.link,
          causes_unlinked: causes.not_link
        }
      } );
      causesListModal.open();
    }
  };

  const getDataIndicator = () => {
    let link = [];
    let not_link = [];
    let pourcent = [];
    let label_7m = [];
    props.data.forEach( ( data ) => {
      link.push( data.causes.link.length );
      not_link.push( data.causes.not_link.length );
      pourcent.push( data.pourcent );
      label_7m.push( data.name );
    } );

    option.value = {
      title: {
        text: t( 'Total number of causes' ) + ' : ' + getTotal(),
        subtext: t( 'Pareto diagram of probable causes' ),
        left: 'center',
        bottom: '0%',
        textStyle: {
          fontSize: 15
        },
        subtextStyle: {
          fontSize: 18,
          fontWeight: 'bold',
          color: 'fff'
        },
        padding: [
          20,  // up
          0, // right
          0,  // down
          0, // left
        ]
      },
      grid: [
        { height: '62%' }
      ],
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [ t( 'Linked causes' ), t( 'Not linked causes' ), t( 'Percentage' ) ],
        top: '0%',
        left: 'center',
        selectedMode: true,
        itemWidth: 28,
        itemHeight: 17,
        textStyle: {
          fontSize: 16
        }
      },
      xAxis: [
        {
          data: label_7m,
          type: 'category',
          gridIndex: 0,
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            interval: 0,
            rotate: 25,
            fontSize: 13.5
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: t( 'Quantity' ) + ' (' + t( 'unit' ) + ')',
          nameTextStyle: {
            fontSize: 15
          },
          gridIndex: 0,
          min: 0,
          max: getMax(),
          interval: 5,
          axisLabel: {
            formatter: '{value}',
            fontSize: 15
          },
          parsing: {
            yAxisKey: 'nCauses'
          }
        },
        {
          type: 'value',
          name: t( 'Percentage' ) + ' (%)',
          nameTextStyle: {
            fontSize: 15
          },
          gridIndex: 0,
          min: 0,
          max: 100,
          interval: 10,
          axisLabel: {
            formatter: '{value}',
            fontSize: 15
          }
        }
      ],
      series: [
        {
          name: t( 'Not linked causes' ),
          color: '#546fc6',
          type: 'bar',
          stack: 'one',
          data: not_link
        },
        {
          name: t( 'Linked causes' ),
          color: '#8094d5',
          type: 'bar',
          stack: 'one',
          data: link
        },
        {
          name: t( 'Percentage' ),
          color: '#56ea31',
          type: 'line',
          yAxisIndex: 1,
          encode: {
            x: 2,
            y: 1,
          },
          tooltip: {
            valueFormatter: function( value ) {
              return value.toFixed( 1 ) + ' %';
            }
          },
          symbolSize: 9,
          lineStyle: {
            width: 3
          },
          emphasis: {
            scale: 1.4,
          },
          data: pourcent
        }
      ]
    };
  };
</script>

<style scoped>
  .chart {
    height: 500px;
    width: 650px;
  }
</style>