<template>
  <LoadingButton ref="actionBtn" v-if="rp.state == RP_STATE.OPEN" type="submit" :label="t('Closing')" v-on:click="closeConfirmModal.open()"/>
  <LoadingButton ref="actionBtn" v-if="rp.state == RP_STATE.CLOSE" type="submit" :label="t('Re-open')" v-on:click="reopen"/>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import API from '@/constants/api.constants';
  import store from '@/store';
  import { RP_STATE, RP_FIELD } from '@/constants/rp.constants.js';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import { useModal } from 'vue-final-modal';
  import ConfirmModal from '@/components/modals/ConfirmModal.vue';

  const props = defineProps( {
    rp: { Object, required: true }
  } );
  const emit = defineEmits( [ 'changeItem' ] );

  const { t } = useI18n();
  const actionBtn = ref( null );

  const close = () => {
    AXIOS.put( API.get_api( API.API_NAMES.RP_CLOSE, props.rp.id ), {}, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        changeItem();
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const reopen = () => {
    AXIOS.put( API.get_api( API.API_NAMES.RP_REOPEN, props.rp.id ), {}, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        changeItem();
        actionBtn.value.stopLoading();
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const changeItem = () => {
    emit( 'changeItem', RP_FIELD.STATE );
  };

  const closeConfirmModal = useModal( {
    component: ConfirmModal,
    attrs: {
      header: t( 'Problem resolution closing confirmation' ),
      body: t( 'Are you sure you want to close this problem resolution ?' ),
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,

      onConfirm() {
        close();
        closeConfirmModal.close();
      },
      onCancel() {
        closeConfirmModal.close();
      },
      onClosed() {
        actionBtn.value.stopLoading();
      }
    }
  } );
</script>
