<template>
  <div class="flex p-4 gap-2">
    <!-- RP List -->
    <RPList class="w-full h-full min-w-[45rem]"/>

    <div class="flex flex-wrap gap-4 content-start place-content-center w-full h-full">

      <!-- Chart Duration RP (1 date) -->
      <GlobalDurationRPChart class="w-[25rem] h-72" @isBusy="isBusy = !isBusy" :readOnly="isBusy"/>

      <!-- Chart/Counter Late Action (1 date) -->
      <GlobalLateActionsChart class="w-[25rem] h-72" @isBusy="isBusy = !isBusy" :readOnly="isBusy"/>

      <!-- Chart Pareto Action (2 dates) -->
      <GlobalParetoActionsChart class="w-[25rem] h-72" @isBusy="isBusy = !isBusy" :readOnly="isBusy"/>

      <!-- All Counters (2 dates) -->
      <GlobalAllCounter class="w-[25rem] h-72" @isBusy="isBusy = !isBusy" :readOnly="isBusy"/>

    </div>
  </div>
</template>

<script setup>
  import RPList from '@/components/rp/RPList.vue';
  import GlobalDurationRPChart from '@/components/indicators/global_indicators/GlobalDurationRPChart.vue';
  import GlobalLateActionsChart from '@/components/indicators/global_indicators/GlobalLateActionsChart.vue';
  import GlobalParetoActionsChart from '@/components/indicators/global_indicators/GlobalActionsParetoChart.vue';
  import GlobalAllCounter from '@/components/indicators/global_indicators/GlobalAllCounter.vue';
  import { ref } from 'vue';

  const isBusy = ref( false );
</script>
