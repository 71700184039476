<template>
  <div class="[ readonlt ? '' : 'hover:bg-tertiary-hover cursor-pointer'] min-h-[2rem] w-full" v-if="!editable || readonly" @click="setEditable()">{{ fieldValue }}</div>
  <div class="flex" v-if="editable && !readonly">
    <input type="number" ref="editableField" class="w-full text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100"
              :min="min" :max="max" maxlength="1000" name="field-value" v-model="editFieldValue"
              @keyup.enter="changeValue" @blur="(event) => { blur(event); }"/>
    <ValidEditButton class="ml-2 self-end noblur" @click="changeValue"></ValidEditButton>
    <CancelEditButton class="ml-2 self-end noblur" @click="editable=false"></CancelEditButton>
  </div>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import ValidEditButton from '@/components/buttons/ValidEditButton.vue';
  import CancelEditButton from '@/components/buttons/CancelEditButton.vue';

  const props = defineProps( {
    field: { String, required: true },
    fkey: { String, required: false },
    min: { Number },
    max: { Number },
    oneline: { Boolean, default: false },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits( [ 'change' ] );

  const editable = ref ( false );
  const editableField = ref( null );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const fieldValue = ref( props.field );
  const editFieldValue = ref( '' );

  const setEditable = () => {
    if ( !props.readonly ) {
      editable.value = true;
      editFieldValue.value = fieldValue.value;
      nextTick( () => {
        editableField.value.focus();
      } );
    }
  };

  const changeValue = () => {
    if ( fieldValue.value != editFieldValue.value ) {
      fieldValue.value = editFieldValue.value;
      emit( 'change', props.fkey, fieldValue.value );
    }
    editable.value = false;
  };

  const blur = ( event ) => {
    if ( event.relatedTarget && event.relatedTarget.className.includes( 'noblur' ) ) {
      event.preventDefault = false;
    } else {
      editable.value = false;
      changeValue();
    }
  };
</script>