<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-xl"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <!-- Modal header -->
    <div>
      <div class="bg-white flex flex-row justify-center">
        <h1 class="text-2xl font-bold text-center mb-2"> {{ t( 'Impact Studies' ) }} </h1>
      </div>
    </div>

    <!-- Modal Body -->
    <div class="space-y-4" :key="componentKey">
      <div class="flex flex-col max-h-[400px] p-1 items-center overflow-auto m-1 border border-1 border-gray-200 ">
        <div class="w-[95%] h-full p-1 m-1 rounded" v-for="impact_study in impact_studies" :key="impact_study.value" :value="impact_study.value">
          <Field :title="impact_study.question" :field="impact_study.answer"
            :fkey="impact_study.id" :placeholder="t( 'Answer' ) + '...'" :readonly="readonly"
            @change="addAnswerToQuestion"
            :maxlength="MAX_INPUT_LENGTH.IMPACT_STUDY_ANSWER"/>
        </div>
        <div v-if="!impact_studies || impact_studies.length === 0">
          <div class="my-3">
            {{ t( 'No impact study is associated with the RP.' ) }}
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import API from '@/constants/api.constants';
  import Field from '@/components/object/FieldBasic.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    readonly: { Boolean, required: false, default: true },
    id_rp: { String, required: true },
    impact_studies: { Array, required: true, default: [] }
  } );
  const emit = defineEmits( [ 'addActions', 'cancel', 'update' ] );
  const { t } = useI18n();

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  const clickClose = () => {
    emit( 'cancel' );
  };

  const update = ( id_impact_study, answer ) => {
    emit( 'update', [ id_impact_study, answer ] );
  };

  function addAnswerToQuestion( id_impact_study, answer ) {
    let url = API.get_api( API.API_NAMES.IMPACT_STUDIES, id_impact_study );
    AXIOS.put( url, { answer }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        }
        update( id_impact_study, answer );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  }

</script>