<template>
  <div class="flex flex-col font-sans items-center justify-center font-bold w-full h-full has-tooltip">
    <div class="tooltip flex flex-col bg-gray-100 rounded-lg py-1 px-2">
      <div class="text-sm underline">
        {{ t( 'Reactivity' ) }}
      </div>
      <div class="text-xs">
        {{ t( 'Median time securring action', [ days + ' ' + ( days > 1 ? t('days') : t('day') ) ] ) }}
      </div>
    </div>
    <div class="text-base w-fit h-fit text-center"> <font-awesome-icon icon="fa-solid fa-shield-halved" size="sm"/> {{ t( 'Reactivity' ) }} </div>
    <div class="flex items-center justify-center text-sm w-full h-full gap-2">
      <font-awesome-icon icon="fa-solid fa-hourglass-half" size="xl" style="color: #37A2DA;" />
      {{ days }} {{ days > 1 ? t('days') : t('day') }}
    </div>
  </div>
</template>

<script setup>
  import moment from 'moment';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps( {
    data: { Array, required: true }
  } );
  let days = Math.round( moment.duration( props.data.median_time, 'minutes' ).asDays() * 10 ) / 10;
</script>