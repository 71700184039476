<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-xl"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex-col space-y-6">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center"> {{ t( 'Capitalising actions list' ) }} </h1>

      <!-- Modal Body -->
      <!-- Capitalising actions templates scroll list -->
      <div v-if="templatesCheckList.length > 0" class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
        <RPCapitalisingActionTemplateItem v-for="template in templatesCheckList" :template="template" :key="template" :readonly="readonly" @click="check"></RPCapitalisingActionTemplateItem>
      </div>

      <!-- Modal footer-->
      <div v-if="!readonly" class="flex space-x-4 justify-end items-center">
        <p class="w-full justify-self-auto pl-2"><i>{{ t( 'A.M.D.E.C legend' ) }}</i></p>
        <div>
          <LoadingButton ref="saveBtn" type="submit" :label="t( 'Select' )" @click="save"/>
        </div>
        <div>
          <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import API from '@/constants/api.constants';
  import RPCapitalisingActionTemplateItem from '@/components/rp/capitalising_actions/RPCapitalisingActionTemplateItem.vue';

  const props = defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    readonly: { Boolean, required: false, default: true },
    id_rp: { String, required: true },
    templates: { Array, required: true, default: [] }
  } );
  const emit = defineEmits( [ 'addActions', 'cancel' ] );

  const { t } = useI18n();
  const saveBtn = ref( null );

  const templatesCheckList = ref(
    props.templates.map( ( template ) => {
      template.checked = false;
      return template;
    } ) );

  const check = ( template ) => {
    if ( !props.readonly && template != null
      && template.checked != null ) {
      template.checked = !template.checked;
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };

  const save = () => {
    let promises = [];

    if ( templatesCheckList.value != null
      && Array.isArray( templatesCheckList.value )
      && templatesCheckList.value.length > 0 ) {
      let url = API.get_api( API.API_NAMES.RP_CAPITALISING_ACTION, props.id_rp );
      templatesCheckList.value.forEach( ( template ) => {
        let body = {
          title: template.title != null && template.title != '' ? template.title : t( 'Title PLACEHOLDER' ),
          description: template.description != null && template.description != '' ? template.description : ''
        };
        if ( template.checked != null && ( template.checked === true || template.checked === 'true' ) ) {
          promises.push( new Promise ( ( resolve ) => AXIOS.post( url, body, { headers: { 'auth-token': store.getters.getToken } } )
            .then( ( response ) => {
              if ( response.status == '200' ) {
                store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
              }
              utils.showAxiosError( response );
              resolve();
            } )
            .catch( ( err ) => {
              utils.showCatch( err );
              resolve();
            } ) ) );
        }
      } );
    }
    Promise.all( promises )
      .then( () => {
        promises.length <= 0 ? emit( 'addActions', false ) : emit( 'addActions', true );
        saveBtn.value.stopLoading();
      } );
  };
</script>