<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-md"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex-col space-y-5">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center"> {{ t( 'Add a note' ) }} </h1>

      <!-- Modal Body -->
      <FieldArea :title="t( 'Note' )"
        class="text-xl"
        :placeholder="t( 'Description PLACEHOLDER' )"
        :field="note"
        fkey="note"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.RP_NOTE"/>

      <!-- Modal footer-->
      <div class="flex space-x-4 justify-end">
        <div>
          <LoadingButton type="submit" :label="t( 'Add' )" @click="addNote"/>
        </div>
        <div>
          <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
  } );
  const emit = defineEmits( [ 'addNote', 'cancel' ] );

  const note = ref();

  const { t } = useI18n();

  const changeField = ( ...val ) => {
    if ( val && val[ 1 ] && val[ 1 ] != '' ) {
      note.value = val[ 1 ];
    }
  };

  const addNote = () => {
    if ( note.value != undefined && note.value != null && note.value.trim() != '' ) {
      emit( 'addNote', note.value );
    } else {
      clickClose();
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>