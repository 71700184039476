<template>
  <div class="flex text-tertiary-text mb-20 p-4 gap-6">
    <div class="flex-col min-w-[45rem] max-w-3xl space-y-4">
      <div class="font-bold text-4xl">{{ t('Settings') }}</div>

      <!-- Change max uploaded file size -->
      <div class="md:flex md:items-center gap-x-4">
        <div class="md:w-1/2">
          <label class="block font-bold md:text-right">
            {{ t('Max size of images and documents') }}
          </label>
        </div>
        <div class="md:w-1/2">
          <div class="flex items-center gap-x-4">
            <div class="md:w-1/2">
              <input class="settings-label"
                id="max-file-size-mo" type="number" v-model.number.lazy="params.max_file_size_mo"
                @change="settings.setOneSetting('max_file_size_mo', params.max_file_size_mo)"
                @focus="selectContent">
            </div>
            <label class="block font-bold md:text-right">
              {{ t('Mo') }}
            </label>
          </div>
        </div>
      </div>

      <!-- Change RP number pattern-->
      <div class="md:flex md:items-center gap-x-4">
        <label class="md:w-1/2 block font-bold md:text-right">
          {{ t('RP Pattern') }}
        </label>
        <div class="md:w-1/2 relative">
          <div class="flex items-center md:w-1/2">
            <FieldSettings fkey="rp_pattern" :key="params.rp_pattern" :field="params.rp_pattern"
                @change="settings.setOneSetting"/>
          </div>
        </div>
      </div>
      <div>
        <label class="block italic text-sm text-left mb-1 pl-4">
          <span class="font-bold">%yyyy</span> - {{ t( 'RP Number pattern year 4 digits' ) }}<br>
          <span class="font-bold">%yy</span> - {{ t( 'RP Number pattern year 2 digits' ) }}<br>
          <span class="font-bold">%mm</span> - {{ t( 'RP Number pattern month 2 digits' ) }}<br>
          <span class="font-bold">%m</span> - {{ t( 'RP Number pattern month 1 or 2 digits' ) }}<br>
          <span class="font-bold">%dd</span> - {{ t( 'RP Number pattern day 2 digits' ) }}<br>
          <span class="font-bold">%d</span> - {{ t( 'RP Number pattern day 1 or 2 digits' ) }}<br>
          <span class="font-bold">%#</span> - {{ t( 'RP Number pattern x digits' ) }}<br>
          <span class="font-bold">%site</span> - {{ t( 'RP Number pattern site' ) }}<br>
          <span class="font-bold">%service</span> - {{ t( 'RP Number pattern service' ) }}<br>
          <span class="font-bold">{{ t( 'Example' ) }}</span> : {{ t( 'RP Number pattern example' ) }}
          <br>RP-%yyyy-%mm-%dd-#### &rarr; RP-2023-07-05-0012.
        </label>
      </div>

      <!-- Change app hour format -->
      <div class="md:flex md:items-center gap-x-4">
        <label class="md:w-1/2 font-bold md:text-right">
          {{ t('Time mode') }}
        </label>
        <div class="flex items-center gap-x-2">
          <label for="toggle" class="font-bold">{{ t( '12h' ) }}</label>
          <Toggle id="toggle" :value="params.time_24h" @toggle="toggle_time_24h()"/>
          <label for="toggle" class="font-bold">{{ t( '24h' ) }}</label>
        </div>
      </div>

      <!-- Change 7M list -->
      <fieldset class="border border-solid border-gray-400 rounded p-4">
        <legend class="custom-legend font-bold flex items-center gap-x-4">
          {{ t('Ishikawa 7M Label') }}
          <div class="has-tooltip">
            <IconLoadingButton ref="reset7Mbtn" icon="fa-solid fa-rotate-right" @click="clickResetButton( settings.RESET_KEYS.ISHIKAWA_7M )"/>
            <span class='tooltip'>{{ t( 'Reset tooltip' )}}</span>
          </div>
        </legend>
        <div class="grid grid-cols-3 gap-4">
          <div v-for="( ishikawa_7m, idx ) in params.ishikawa_7m" :key="ishikawa_7m">
            <FieldSettings :fkey="idx" :field="ishikawa_7m"
                @change="change_ishikawa_7m"/>
          </div>
        </div>
      </fieldset>

      <!-- Change global company parameters -->
      <fieldset class="border border-solid border-gray-400 rounded px-4 pb-6 pt-4 flex flex-col gap-y-4">
        <legend class="custom-legend font-bold">{{ t('Company') }}</legend>
        <div class="md:flex md:items-center gap-x-4 w-full">
          <label class="md:w-1/2 font-bold md:text-right">
            {{ t('Name') }}
          </label>
          <div class="md:w-1/2 relative">
            <div class="md:w-3/4">
              <FieldSettings fkey="company_name" :key="params.company_name" :field="params.company_name"
                @change="settings.setOneSetting"/>
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center gap-x-4 w-full">
          <label class="md:w-1/2 font-bold md:text-right">
            {{ t('Address') }}
          </label>
          <div class="md:w-1/2 relative">
            <div class="md:w-3/4">
            <FieldSettings fkey="company_address" :key="params.company_address" :field="params.company_address"
                @change="settings.setOneSetting"/>
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center gap-x-4 w-full">
          <label class="md:w-1/2 font-bold md:text-right">
            {{ t('Email') }}
          </label>
          <div class="md:w-1/2 relative">
            <div class="md:w-3/4">
            <FieldSettings fkey="company_email" :key="params.company_email" :field="params.company_email"
                @change="settings.setOneSetting"/>
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center gap-x-4 w-full">
          <label class="md:w-1/2 font-bold md:text-right">
            {{ t('Phone') }}
          </label>
          <div class="md:w-1/2 relative">
            <div class="md:w-3/4">
            <FieldSettings fkey="company_phone" :key="params.company_phone" :field="params.company_phone"
                @change="settings.setOneSetting"/>
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center gap-x-4 w-full">
          <label class="md:w-1/2 font-bold md:text-right">
            {{ t('Company logo') }}
          </label>
          <div class="md:w-1/2 relative">
            <div v-if="params.logo_path != null && params.logo_path !== ''"
              class="flex items-center space-x-3">
              <img class="w-3/4" :src="utils.get_api_base_url() + '/' +  params.logo_path">
              <div class="hover:bg-secondary-hover rounded-full">
                <button @click.prevent.stop="deleteLogoConfirmModal.open()">
                  <font-awesome-icon icon="fa-solid fa-xmark" size="xl" class="px-2 py-1"/>
                </button>
              </div>
            </div>
            <label v-else>{{ t( 'No logo' ) }}</label>
          </div>
        </div>

        <div class="md:flex md:items-center gap-x-2 w-full">
          <label class="has-tooltip md:w-1/2 font-bold md:text-right whitespace-pre">
            {{ t('Select a logo') }}
            <font-awesome-icon icon="fa-solid fa-circle-info" class="mb-2 -ml-1" style="color: #2563eb;" size="sm"/>
            <span class='tooltip'>{{ t( 'Valid extensions' )}}</span>
          </label>
          <div class="md:w-1/2 relative flex items-center space-x-3">
            <label class="flex items-center custom-file-upload">
              <input type="file" id="company-logo-setting" name="company-logo-setting"
                accept="image/png, image/jpeg" @change="setNewLogo"/>
              <font-awesome-icon icon="fa-solid fa-upload" />
              {{ t( 'Choose file' ) + "..." }}
            </label>
            <ClipLoader v-if="uploading" class="flex items-center color-primary" size="1.7rem"/>
          </div>
        </div>
      </fieldset>

      <!-- Non utilisé pour le moment -->
      <!-- <div class="md:flex md:items-center">
        <div class="md:w-1/2">
          <label class="block font-bold md:text-right mb-1 md:mb-0 pr-4">
            {{ t('Timezone') }}
          </label>
        </div>
        <div class="md:w-1/2">
          <div class="flex items-center">
            <div class="md:w-1/2">
              <select class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 secondary-text leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="timezone" v-model="params.timezone" @change="settings.setOneSetting('timezone', params.timezone)">
                <option v-for="timezone in moment.tz.names()" :key="timezone" :value="timezone">
                  {{ timezone }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="flex flex-col min-w-[50rem] space-y-4">
      <!-- Change capitalising actions templates list -->
      <fieldset class="border border-solid border-gray-400 space-y-4 rounded p-4 pt-0 w-full h-fit">
        <legend class="custom-legend font-bold flex items-center gap-x-4">
          {{ t('Capitalising actions list') }}
          <div class="has-tooltip">
            <IconLoadingButton ref="resetCapitalisationBtn" icon="fa-solid fa-rotate-right" @click="clickResetButton( settings.RESET_KEYS.CAPITALISATION_TEMPLATE )"/>
            <span class='tooltip'>{{ t( 'Reset tooltip' )}}</span>
          </div>
        </legend>
        <div class="w-fit">
          <LoadingButton ref="actionTemplateBtn" type="submit" :label="t( 'Add a template' )" @click="newActionTemplateModal.open()"/>
        </div>

        <!-- Action templates scroll list -->
        <div v-if="params.capitalisation_template.length > 0" class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
          <!-- Action template item -->
          <div v-for="template in utils.sortListByRegisterDateAscending( params.capitalisation_template )" :key="template" class="flex items-center p-3 space-x-6 w-full border border-$c-divider border-gray-300">
            <div class="w-full">
              <Field :fkey="template.id" :field="template.title" :readonly="false"
                @change="changeTemplate"
                :maxlength="MAX_INPUT_LENGTH.CAPITALIZATION_TEMPLATE_TITLE"/>
            </div>
            <button @click="clickDeleteCapitalisation( template )"><font-awesome-icon icon="fa-solid fa-xmark" size="xl"
              class="px-2 py-1 hover:bg-secondary-hover rounded-full"/></button>
          </div>
        </div>
      </fieldset>

      <!-- Change Is/Is not default items list -->
      <fieldset class="border border-solid border-gray-400 space-y-4 rounded p-4 pt-0 w-full h-fit">
        <legend class="custom-legend font-bold flex items-center gap-x-4">
          <div class="has-tooltip-4">
            {{ t( 'Is/Is not tool default list' ) }}
            <font-awesome-icon icon="fa-solid fa-circle-info" style="color: #2563eb;"/>
            <span class='tooltip'>{{ t( 'Is/Is not tool default list tooltip' ) }}</span>
          </div>
          <div class="has-tooltip">
            <IconLoadingButton ref="resetIsIsNotBtn" icon="fa-solid fa-rotate-right" @click="clickResetButton( settings.RESET_KEYS.IS_IS_NOT_TEMPLATE )"/>
            <span class='tooltip'>{{ t( 'Reset tooltip' )}}</span>
          </div>
        </legend>
        <div class="w-fit">
          <LoadingButton ref="isIsNotactionTemplateBtn" type="submit" :label="t( 'Add a section' )" @click="newIsIsNotTemplateModal.open()"/>
        </div>
        <!-- Is/Is not default items list -->
        <div v-if="params.is_is_not_template.length > 0" class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
          <!-- Is/Is not default item -->
          <div v-for="template in utils.sortListByRegisterDateAscending( params.is_is_not_template )" :key="template"
            class="flex items-center justify-between p-3 space-x-6 w-full hover:bg-fourth-hover border border-$c-divider border-gray-300 cursor-pointer"
            @click="clickChangeIsIsNot( template )">
            <p>{{ template.label }}</p>
            <div class="hover:bg-secondary-hover rounded-full">
              <button @click.prevent.stop="clickDeleteIsIsNot( template )"
                class="hover:bg-secondary-hover rounded-full">
                <font-awesome-icon icon="fa-solid fa-xmark" size="xl" class="px-2 py-1"/>
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
</div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useModal } from 'vue-final-modal';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import settings from '@/features/settings';
  import Toggle from '@/components/object/ToggleBasic.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldSettings from '@/components/object/FieldSettings.vue';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import NewActionTemplateModalVue from '@/components/modals/rp_modals/NewActionTemplateModal.vue';
  import NewIsIsNotTemplateModal from '@/components/modals/tools_modals/NewIsIsNotTemplateModal.vue';
  import ChangeIsIsNotTemplateModal from '@/components/modals/tools_modals/ChangeIsIsNotTemplateModal.vue';
  import ConfirmModal from '@/components/modals/ConfirmModal.vue';
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  import IconLoadingButton from '@/components/buttons/IconLoadingButton.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const { t } = useI18n();
  const params = ref( {
    max_file_size_mo: 0,
    rp_pattern: '##',
    time_24h: false,
    color_sticky_note: '',
    company_name: '',
    company_address: '',
    company_email: '',
    company_phone: '',
    capitalisation_template: [],
    ishikawa_7m: [],
    is_is_not_template: []
  } );
  const actionTemplateBtn = ref( null );
  const isIsNotactionTemplateBtn = ref( null );
  const uploading = ref( false );
  const reset7Mbtn = ref( null );
  const resetCapitalisationBtn = ref( null );
  const resetIsIsNotBtn = ref( null );
  const curResetKey = ref ( '' );

  settings.getSettings().then( ( res ) => {
    params.value.max_file_size_mo = res.max_file_size_mo;
    params.value.rp_pattern = res.rp_pattern;
    params.value.time_24h = res.time_24h === true || res.time_24h === 'true';
    params.value.color_sticky_note = res.color_sticky_note;
    params.value.company_name = res.company_name;
    params.value.company_address = res.company_address;
    params.value.company_email = res.company_email;
    params.value.company_phone = res.company_phone;
    params.value.capitalisation_template = res.capitalisation_template;
    params.value.ishikawa_7m = res.ishikawa_7m;
    params.value.is_is_not_template = res.is_is_not_template;
    params.value.logo_path = res.logo_path;
  } );

  const selectContent = ( event ) => {
    if ( event != null && event.target != null ) {
      event.target.select();
    }
  };

  const toggle_time_24h = async () => {
    await settings.setOneSetting( 'time_24h', !params.value.time_24h );
    let newValue = await settings.getOneSetting( 'time_24h' );
    params.value.time_24h = ( newValue === true || newValue === 'true' );
  };

  const change_ishikawa_7m = async ( idx, val ) => {
    let newValue = val;
    if ( idx != null && params.value.ishikawa_7m[ idx ] != null && newValue != null && newValue != '' ) {
      params.value.ishikawa_7m[ idx ] = newValue;
      let body = {
        locale: store.getters.getLocale,
        array: params.value.ishikawa_7m
      };
      await settings.setOneSetting( 'ishikawa_7m', body );
      params.value.ishikawa_7m = await settings.getOneSetting( 'ishikawa_7m' ) ?? [];
    }
  };

  const changeTemplate = async ( templateId, newTitle ) => {
    let template = params.value.capitalisation_template.find( ( template ) => {
      return template.id != null && template.id === templateId;
    } );
    if ( template != null && template.title != newTitle ) {
      let body = {
        locale: store.getters.getLocale,
        array: [ { id: templateId, title: newTitle, register_date: template.register_date } ]
      };
      await settings.setOneSetting( 'capitalisation_template', body );
      params.value.capitalisation_template = await settings.getOneSetting( 'capitalisation_template' ) ?? [];
    }
  };

  const clickDeleteCapitalisation = ( template ) => {
    deleteCapitalisationConfirmModal.patchOptions( {
      attrs: {
        async onConfirm() {
          await deleteTemplate( template );
          deleteCapitalisationConfirmModal.close();
        }
      } } );
    deleteCapitalisationConfirmModal.open();
  };

  const deleteCapitalisationConfirmModal = useModal( {
    component: ConfirmModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      header: t( 'Capitalisation template deletion confirmation HEADER' ),
      body: t( 'Capitalisation template deletion confirmation BODY' ),

      onCancel() {
        deleteCapitalisationConfirmModal.close();
      }
    }
  } );

  const deleteTemplate = async ( template ) => {
    if ( template != null && template.title != null && template.id != null ) {
      let body = {
        locale: 'fr',
        array: [ { id: template.id, title: template.title, delete: true } ]
      };
      await settings.setOneSetting( 'capitalisation_template', body );
      params.value.capitalisation_template = await settings.getOneSetting( 'capitalisation_template' ) ?? [];
    }
  };

  const clickDeleteIsIsNot = ( template ) => {
    deleteIsIsNotConfirmModal.patchOptions( {
      attrs: {
        async onConfirm() {
          await deleteIsIsNotTemplate( template );
          deleteIsIsNotConfirmModal.close();
        }
      } } );
    deleteIsIsNotConfirmModal.open();
  };

  const deleteIsIsNotConfirmModal = useModal( {
    component: ConfirmModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      header: t( 'Is/Is not criterion deletion confirmation HEADER' ),
      body: t( 'Is/Is not criterion deletion confirmation BODY' ),

      onCancel() {
        deleteIsIsNotConfirmModal.close();
      }
    }
  } );

  const deleteIsIsNotTemplate = async ( template ) => {
    if ( template != null && template.label != null && template.id != null ) {
      let body = {
        locale: 'fr',
        array: [ { id: template.id, delete: true } ]
      };
      await settings.setOneSetting( 'is_is_not_template', body );
      params.value.is_is_not_template = await settings.getOneSetting( 'is_is_not_template' ) ?? [];
    }
  };

  const newActionTemplateModal = useModal( {
    component: NewActionTemplateModalVue,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,

      onCancel() {
        newActionTemplateModal.close();
      },
      async onAddTemplate( templateTitle ) {
        if ( templateTitle != null && templateTitle != '' ) {
          let body = {
            locale: store.getters.getLocale,
            array: [ { id: '', title: templateTitle } ]
          };
          await settings.setOneSetting( 'capitalisation_template', body );
          params.value.capitalisation_template = await settings.getOneSetting( 'capitalisation_template' ) ?? [];
        }
        newActionTemplateModal.close();
      },
      onClosed() {
        actionTemplateBtn.value.stopLoading();
      }
    }
  } );

  const newIsIsNotTemplateModal = useModal( {
    component: NewIsIsNotTemplateModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,

      onCancel() {
        newIsIsNotTemplateModal.close();
      },
      async onAddTemplate( template ) {
        if ( template != null && template.label != null && template.label != '' ) {
          let body = {
            locale: store.getters.getLocale,
            array: [ {
              id: '',
              label: template.label,
              is_desc: template.is_desc != null && template.is_desc != '' ? template.is_desc : undefined,
              is_not_desc: template.is_not_desc != null && template.is_not_desc != '' ? template.is_not_desc : undefined,
              particularities_desc: template.particularities_desc != null && template.particularities_desc != '' ? template.particularities_desc : undefined,
              changes_desc: template.changes_desc != null && template.changes_desc != '' ? template.changes_desc : undefined
            } ]
          };
          await settings.setOneSetting( 'is_is_not_template', body );
          params.value.is_is_not_template = await settings.getOneSetting( 'is_is_not_template' ) ?? [];
        }
        newIsIsNotTemplateModal.close();
      },
      onClosed() {
        isIsNotactionTemplateBtn.value.stopLoading();
      }
    }
  } );

  const clickChangeIsIsNot = ( isIsNot ) => {
    if ( isIsNot != null && isIsNot.id != null ) {
      changeIsIsNotModal.patchOptions( {
        attrs: {
          is_is_not_template: isIsNot
        }
      } );
      changeIsIsNotModal.open();
    }
  };

  const changeIsIsNotModal = useModal( {
    component: ChangeIsIsNotTemplateModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: false,
      is_is_not_template: null,

      onCancel() {
        changeIsIsNotModal.close();
      },
      async onChangeTemplate( template ) {
        if ( template != null && template.label != null && template.label != '' ) {
          let body = {
            locale: store.getters.getLocale,
            array: [ {
              id: template.id,
              label: template.label != null && template.label != '' ? template.label : undefined,
              is_desc: template.is_desc != null ? template.is_desc : undefined,
              is_not_desc: template.is_not_desc != null ? template.is_not_desc : undefined,
              particularities_desc: template.particularities_desc != null ? template.particularities_desc : undefined,
              changes_desc: template.changes_desc != null ? template.changes_desc : undefined,
              register_date: template.register_date
            } ]
          };
          await settings.setOneSetting( 'is_is_not_template', body );
          params.value.is_is_not_template = await settings.getOneSetting( 'is_is_not_template' ) ?? [];
        }
        changeIsIsNotModal.close();
      }
    }
  } );

  const setNewLogo = async ( event ) => {
    uploading.value = true;
    if ( event.target.files != null
      && event.target.files[ 0 ] != null ) {
      let fileToUpload = event.target.files[ 0 ];
      let maxFileSize = await settings.getOneSetting( 'max_file_size_mo' );
      if ( fileToUpload.size != null && fileToUpload.size <= maxFileSize * ( 1024 * 1024 ) ) { // Convert all sizes in Bytes/Octets
        await settings.setCompanyLogo( event.target.files[ 0 ] );
        let logo_path =  await settings.getOneSetting( 'logo_path' );
        params.value.logo_path = ( logo_path != null && logo_path !== '' ) ? logo_path + '?timestamp=' + new Date().getTime() : ''; // Timestamp is a fake parameter used in order to update the logo displayed
      } else {
        utils.showAlert( t( 'Warning file to big', { fileName: fileToUpload.name ?? '' } ) );
      }
    }
    uploading.value = false;
  };

  const deleteLogoConfirmModal = useModal( {
    component: ConfirmModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      header: t( 'Logo deletion HEADER' ),
      body: t( 'Logo deletion BODY' ),

      onCancel() {
        deleteLogoConfirmModal.close();
      },
      async onConfirm() {
        await settings.deleteLogo();
        params.value.logo_path = await settings.getOneSetting( 'logo_path' ) ?? '';
        deleteLogoConfirmModal.close();
      }
    }
  } );

  const clickResetButton = ( key ) => {
    curResetKey.value = key;
    confirmResetsettingsmodal.patchOptions( {
      attrs: {
        header: t( key + ' HEADER' ),
        body: t( key + ' BODY' ),
      }
    } );
    confirmResetsettingsmodal.open();
  };

  const confirmResetsettingsmodal = useModal( {
    component: ConfirmModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',

      onClosed() {
        curResetKey.value = '';
        reset7Mbtn.value.stopLoading();
        resetCapitalisationBtn.value.stopLoading();
        resetIsIsNotBtn.value.stopLoading();
      },
      onCancel() {
        confirmResetsettingsmodal.close();
      },
      async onConfirm() {
        if ( curResetKey.value != null && curResetKey.value != '' ) {
          await settings.resetSetting( curResetKey.value );
          params.value[ curResetKey.value ] = await settings.getOneSetting( curResetKey.value ) ?? [];
        }
        confirmResetsettingsmodal.close();
      }
    }
  } );
</script>

<style scoped lang="scss">
  input[type="file"] {
      display: none;
  }
  .custom-file-upload {
    @apply inline-flex justify-center items-center w-fit h-8 gap-x-2;
    @apply bg-primary hover:bg-primary-hover hover:cursor-pointer;
    @apply text-primary-text;
    @apply font-bold px-4 rounded;
  }
  .settings-label {
    @apply bg-gray-200;
    @apply appearance-none;
    @apply border-2;
    @apply border-gray-200;
    @apply rounded;
    @apply w-full;
    @apply py-2;
    @apply px-4;
    @apply text-gray-700;
    @apply leading-tight;
    @apply focus:outline-none;
    @apply focus:bg-white;
    @apply focus:border-purple-500;
  }
</style>