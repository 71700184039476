<template>
  <select v-if="!readonly" ref="select_element" :class="[readonly ? '': 'bg-white border border-gray-300 olor rounded hover:bg-tertiary-hover cursor-pointer', 'w-full -ml-1']" v-model="selected_value" @change="change($event)" @blur="(event) => { blur(event); }">
    <option v-for="item in items" :key="item.value" :value="item.val" :disabled="item.disabled ?? false">
      {{ item.text }}&nbsp;
    </option>
  </select>
  <span v-if="readonly">
    <span v-for="item in items" :key="item.value">
      <span v-if="item.val == selected_value">
        {{item.text}}
      </span>
    </span>
  </span>
</template>

<script setup>
  import { ref } from 'vue';

  const props = defineProps( {
    selected: { String, required: true },
    items: { Array, required: true },
    rkey: { Object, required: false },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits( [ 'change-value', 'blur' ] );

  const select_element = ref( null );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const selected_value = ref( props.selected );

  const change = ( e ) => {
    emit( 'change-value', selected_value.value, e.target.selectedIndex, props.selected, props.rkey );
  };

  const focus = () => {
    select_element.value.focus();
  };

  const clear_selection = () => {
    selected_value.value = null;
  };

  const change_selection = ( selection ) => {
    selected_value.value = selection;
  };

  const blur = ( event ) => {
    emit( 'blur', event );
  };

  defineExpose( { focus, clear_selection, change_selection } );
</script>
