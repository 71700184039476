<template>
  <div class="flex h-full w-full p-5 gap-8">
    <fieldset class="w-full h-fit round border shadow-lg opacity-95 rounded-xl space-y-4 p-4">
      <div class="flex flex-row items-center justify-center">
        <span class="font-bold text-3xl mb-2 px-2">{{ t( 'Services list' ) }}</span>
      </div>
      <div class="flex flex-row justify-center mb-4 gap-x-4">
        <div class="flex flex-row items-center justify-center">
          <select @change="select_changed($event)"
                  class="text-gray-800 bg-gray-150 border-2 border-r-0 border-gray-150 text-sm rounded-l-lg p-2 h-[40px]">
            <option value="">{{ t("All fields") }}</option>
            <option v-for="item in headers" :key="item.value" :value="item.value">
                {{ item.text }}
            </option>
          </select>
          <input type="search" :placeholder="t( 'Search bar' ) + '...'" v-model="searchedServices" class="bg-gray-150 text-sm w-96 rounded-r-lg p-2 border-2 border-gray-150 h-[40px]"/>
        </div>
        <AddButton :tooltipContent="t( 'Add a service' )" :rounded="true" @add="show_addDervice" class="w-[3rem] h-[3rem]"></AddButton>
      </div>
      <EasyDataTable v-if="services" alternating buttons-pagination
        class="w-full h-full"
        :empty-message="t( 'No Available Data' )"
        :rows-of-page-separator-message="t( 'of' )"
        :rows-items="[25, 50, 75, 100]"
        :rows-per-page-message="t( 'Show' )"
        :rows-per-page="display.row_per_page"
        :headers="headers" :items="services"
        :search-value="searchedServices"
        :search-field="searchedField"
        sort-by="name"
        @click-row="show_updateService"
        table-class-name="customize-table">
      </EasyDataTable>
    </fieldset>
    <UpdateService v-if="selectedService" :key="selectedService" :selectedService="selectedService" :services="services" @update="updateService" @delete="deleteService" @close="close"/>
    <AddService v-if="displayAddService" :key="displayAddService" @add="addService" @close="close"/>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import utils from '@/features/utils.js';
  import API from '@/constants/api.constants';
  import { useI18n } from 'vue-i18n';
  import AddButton from '@/components/buttons/AddButton.vue';
  import UpdateService from '@/components/management/UpdateService.vue';
  import AddService from '@/components/management/AddService.vue';

  const { t } = useI18n();

  const services = ref( [] );
  const searchedServices = ref( '' );
  const searchedField = ref();
  const selectedService = ref( null );
  const displayAddService = ref( false );
  const headers = [
    { text: t( 'Service name' ), value: 'name', sortable: true }
  ];
  const display = ref( {
    row_per_page: store.getters.getDisplay.dashboard.row_per_page
  } );

  onMounted( () => {
    getServices();
  } );

  function getServices() {
    let url = API.get_api( API.API_NAMES.SERVICES );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        let services_result = response.data;
        let url = API.get_api( API.API_NAMES.USERS );
        AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            services_result.forEach( ( service ) => {
              service.users = response.data.filter( ( user ) => user.id_service === service.id );
            } );
            services.value = services_result;
            utils.showAxiosError( response );
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
          } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  }

  function addService( name ) {
    let url = API.get_api( API.API_NAMES.SERVICES );
    AXIOS.post( url, { name: name }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '200' ) {
          getServices();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  }

  function updateService( id, name ) {
    let url = API.get_api( API.API_NAMES.SERVICES, id );
    AXIOS.put( url, { name: name }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          getServices();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  }

  function deleteService( id ) {
    let url = API.get_api( API.API_NAMES.SERVICES, 'remove', id );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          getServices();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  }

  function show_addDervice() {
    close();
    displayAddService.value = true;
  }
  function show_updateService( params ) {
    close();
    selectedService.value = params;
  }

  const select_changed = ( event ) => {
    searchedField.value = event.target.value;
  };

  const close = () => {
    displayAddService.value = false;
    selectedService.value = null;
  };
</script>