<template>
  <div>
    <div>
      <label class="block mb-2 text-blue-700" for="email">{{ t('Password') }}</label>
      <input class="w-full p-2 mb-3 text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100" type="password" name="password" v-model="input.password">
    </div>
    <div>
      <label class="block mb-2 text-blue-700" for="password">{{ t('Confirm Password') }}</label>
      <input class="w-full p-2 mb-9 text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100" type="password" name="confirm-password" v-model="input.confirmPassword">
    </div>
    <div>
      <LoadingButton :label="t('Change Password')" v-on:click="changePassword()"/>
    </div>
    <div class="mt-4 text-white grid grid-cols-2 justify-items-stretch">
      <div class="justify-self-start">
        <SelectLanguage/>
      </div>
      <div class="justify-self-end">
        <a class="text-blue-500 hover:text-blue-900 text-sm" href="#" v-on:click="signin()">{{ t('Sign-in') }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, inject } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import enumDisplayComponent from '@/enum/enumDisplayComponent';
  import AXIOS from '@/features/axios.js';
  import passwordValidator from '@/features/password-validator.js';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import API from '@/constants/api.constants';

  const props = defineProps( {
    user_id: String,
    code: String
  } );
  const emit = defineEmits( [ 'custom-event-name' ] );

  const { t } = useI18n();
  const input = ref( { password: '', confirmPassword: '' } );
  const cryoptojs = inject( 'cryptojs' );

  const signin = () => {
    window.location = window.location.origin;
  };

  const changePassword = () => {
    let resultPassValidator = passwordValidator.validate( input.value.password );

    if ( input.value.password != '' ) {
      if ( input.value.password != input.value.confirmPassword ) {
        utils.showWarning( t( 'Passwords do not match' ) );
      } else if ( resultPassValidator.length > 0 ) {
        utils.showWarning( resultPassValidator[ 0 ].message );
      } else {

        AXIOS.post( API.get_api( API.API_NAMES.AUTH_PASSWORD_CHANGE ), { id: props.user_id, code: props.code, password: cryoptojs.HmacSHA1( input.value.password, process.env.VUE_APP_SECRET_PASSWORD ).toString() } )
          .then( ( response ) => {
            if ( response.status == '204' ) {
              utils.showAlert( t( 'Your password are changed' ) );
              emit( 'custom-event-name', { display: enumDisplayComponent.SIGNIN } );
            } else {
              utils.showAlert( t( 'Your password are NOT changed' ) );
            }
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
          } );
      }
    } else {
      utils.showWarning( t( 'Please enter a valid password' ) );
    }
  };
</script>
