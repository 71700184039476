import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import moment from 'moment';
import SORT from '@/constants/sort.constants';
import FILTER from '@/constants/filter.constants';

const config = {
  expires: ( process.env.VUE_APP_SESSION_EXPIRES_IN / 1440 ),
  secure: true,
  samesite: 'lax'
};
const cookies = Cookies.withAttributes( config );

export default createStore( {
  state: {
    i18n: {
      locale: process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr'
    },
    auth: {
      connect: false,
      user: null,
      token: ''
    },
    auth_flashmeeting: {
      url: '',
      automatic: false
    },
    display: {
      dashboard: {
        row_per_page: 25
      },
      board: {
        start_date: moment().startOf( 'day' ).toISOString(),
        n_days: 5
      }
    },
    lastRPVisitedURL: '/dashboard',
    expanders: {
      description: true,
      causes: false,
      securingActions: false,
      actions: false,
      documents: false,
      notes: false,
      capitalisingActions: false
    },
    causes: {
      sort: SORT.REGISTER_DATE_DESC,
      filter: FILTER.ALL,
      ishikawaView: false
    },
    securingActions: {
      sort: SORT.REGISTER_DATE_DESC,
      filter: FILTER.ALL
    },
    actions: {
      sort: SORT.REGISTER_DATE_DESC,
      filter: FILTER.ALL
    },
    capitalisingActions: {
      sort: SORT.REGISTER_DATE_DESC,
      filter: FILTER.ALL
    },
    notes: {
      sort: SORT.REGISTER_DATE_DESC,
      filter: FILTER.ALL
    }
  },
  getters: {
    getLocale: ( state ) => {
      return state.i18n.locale;
    },
    isAuthenticated: ( state ) => {
      return state.auth.connect;
    },
    get_connected_user: ( state ) => {
      return state.auth.user;
    },
    getToken: ( state ) => {
      return state.auth.token;
    },
    getAuthFlashmeeting: ( state ) => {
      return state.auth_flashmeeting;
    },
    getDisplay: ( state ) => {
      return state.display;
    },
    getCausesOptions: ( state ) => {
      return state.causes;
    },
    getSecuringActionsOptions: ( state ) => {
      return state.securingActions;
    },
    getActionsOptions: ( state ) => {
      return state.actions;
    },
    getCapitalisingActionsOptions: ( state ) => {
      return state.capitalisingActions;
    },
    getNotesOptions: ( state ) => {
      return state.notes;
    },
    getExpanders: ( state ) => {
      return state.expanders;
    },
    getLastRPVisitedURL: ( state ) => {
      return state.lastRPVisitedURL;
    }
  },
  mutations: {
    SET_LOCALE( state, locale ) {
      state.i18n.locale = locale;
    },
    SET_CONNECTED( state, connect ) {
      state.auth.connect = Boolean( connect );
    },
    SET_CONNECTED_ID( state, connect_id ) {
      state.auth.connect_id = connect_id;
    },
    SET_CONNECTED_USER( state, connect_user ) {
      state.auth.user = connect_user;
    },
    SET_TOKEN( state, token ) {
      state.auth.token = token;
    },
    SET_AUTH_FLASHMEETING( state, url, automatic ) {
      state.auth_flashmeeting.url = url;
      state.auth_flashmeeting.automatic = automatic;
    },
    SET_DISPLAY( state, options ) {
      state.display = { ...state.display, ...options };
    },
    SET_EXPANDERS( state, newExpanderState ) {
      state.expanders = { ...state.expanders, ...newExpanderState };
    },
    SET_SECURING_ACTIONS( state, newSecuringActionsOption ) {
      state.securingActions = { ...state.securingActions, ...newSecuringActionsOption };
    },
    SET_CAUSES( state, newCausesOption ) {
      state.causes = { ...state.causes, ...newCausesOption };
    },
    SET_ACTIONS( state, newActionsOption ) {
      state.actions = { ...state.actions, ...newActionsOption };
    },
    SET_CAPITALISING_ACTIONS( state, newCapitalisingActionsOption ) {
      state.capitalisingActions = { ...state.capitalisingActions, ...newCapitalisingActionsOption };
    },
    SET_LAST_RP_VISITED_URL( state, newURL ) {
      state.lastRPVisitedURL = newURL;
    }
  },
  actions: {
    setLocale( { commit }, { locale } ) {
      moment.locale( locale );
      commit( 'SET_LOCALE', locale );
    },
    setConnected( { commit }, { connect, connect_user, token } ) {
      commit( 'SET_CONNECTED', connect );
      commit( 'SET_CONNECTED_USER', connect_user );
      commit( 'SET_TOKEN', token );
    },
    updateToken( { commit }, { token } ) {
      commit( 'SET_TOKEN', token );
    },
    setAuthFlashmeeting( { commit }, { url, automatic } ) {
      commit( 'SET_AUTH_FLASHMEETING', url, automatic );
    },
    setDisplayDashboard( { commit }, options ) {
      commit( 'SET_DISPLAY', { dashboard: options } );
    },
    setDisplayBoard( { commit }, options ) {
      commit( 'SET_DISPLAY', { board: options } );
    },
    setExpanders( { commit }, newExpanderState ) {
      commit( 'SET_EXPANDERS', newExpanderState );
    },
    setSecuringActions( { commit }, newSecuringActionsOption ) {
      commit( 'SET_SECURING_ACTIONS', newSecuringActionsOption );
    },
    setCauses( { commit }, newCausesOption ) {
      commit( 'SET_CAUSES', newCausesOption );
    },
    setActions( { commit }, newActionsOption ) {
      commit( 'SET_ACTIONS', newActionsOption );
    },
    setCapitalisingActions( { commit }, newCapitalisingActionsOption ) {
      commit( 'SET_CAPITALISING_ACTIONS', newCapitalisingActionsOption );
    },
    setLastRPVisitedURL( { commit }, newURL ) {
      commit( 'SET_LAST_RP_VISITED_URL', newURL );
    }
  },
  plugins: [
    createPersistedState( {
      storage: {
        getItem: ( key ) => cookies.get( key ),
        setItem: ( key, value ) =>
        cookies.set( key, value ),
        removeItem: ( key ) => cookies.remove( key )
      }
    } )
  ]
} );
