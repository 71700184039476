<template>
  <div class="has-tooltip justify-center items-center w-8 aspect-square text-sm bg-white shadow rounded focus-visible:outline-none" @click="addItem">
    <span v-if="tooltip != null && tooltip.trim() != ''" class='tooltip -mt-8'>{{ tooltip }}</span>
    <button class="text-primary text-xl w-full h-full">
      <font-awesome-icon icon="fas fa-plus" />
    </button>
  </div>
</template>

<script setup>
  defineProps( {
    tooltip: { String, required: false, default: '' }
  } );
  const emit = defineEmits( [ 'add-item' ] );

  const addItem = () => {
    emit( 'add-item' );
  };
</script>
