<template>
  <div class="flex flex-row border border-2 border-gray-400 rounded w-full">
    <div class="has-tooltip-2 w-full p-2">
      <div class="w-fit">{{ file.title }}</div>
      <span class='tooltip'>{{ file.description }}</span>
    </div>
    <div class="flex justify-center">
      <div class="has-tooltip-2">
        <a v-if="isDownloadable" @click="emits( 'download', file )" class="flex items-center hover:bg-blue-300 px-2 py-1 h-full" :href="utils.get_api_base_url() + '/public/' + file.filename" target="_blank"><font-awesome-icon icon="fa-solid fa-eye" /></a>
        <span class='tooltip -mx-20'>{{ t( 'OPEN' ) }}</span>
      </div>
      <div class="has-tooltip-2 w-full">
        <button v-if="isEditable" @click="emits( 'edit', file )" class="flex items-center hover:bg-green-300 px-2 py-1 h-full"><font-awesome-icon icon="fa-solid fa-pen"/></button>
        <span class='tooltip -mx-20'>{{ t( 'Edit' ) }}</span>
      </div>
      <div class="has-tooltip-3 w-full">
        <button v-if="isDeletable" @click="emits( 'delete', file )" class="flex items-center hover:bg-red-300 px-2 py-1 h-full"><font-awesome-icon icon="fa-solid fa-xmark"/></button>
        <span class='tooltip -mx-20'>{{ t( 'Delete' ) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import utils from '@/features/utils.js';
  import { useI18n } from 'vue-i18n';

  defineProps( {
    file: { Object, require: true },
    isDeletable: { Boolean, require: false, default: false },
    isDownloadable: { Boolean, require: false, default: false },
    isEditable: { Boolean, require: false, default: false }
  } );
  const emits = defineEmits( [ 'download', 'delete', 'edit' ] );
  const { t } = useI18n();
</script>

<style lang="scss" scoped>
</style>