<template>
  <div class="fixed top-0 mt-16 w-full z-50">
    <BoardHeader :settings="boardSettings" :sites="sites"> </BoardHeader>
  </div>
  <div class="mt-36">
    <BoardCalendar :themes="themes" :items="actions" :settings="boardSettings" @redraw-calendar="init()" :key="componentCalendar"></BoardCalendar>
  </div>
  <div class="fixed bottom-8 w-full">
    <BoardThemes :themes="themes" :key="componentTheme"/>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import moment from 'moment';
  import BoardHeader from '@/components/board/BoardHeader.vue';
  import BoardThemes from '@/components/board/BoardThemes.vue';
  import BoardCalendar from '@/components/board/BoardCalendar.vue';
  import API from '@/constants/api.constants';

  const sites = ref( [] );
  const themes = ref( [] );
  const actions = ref( [] );
  const boardStartDate = ref( moment() );
  const componentCalendar = ref ( 0 );
  const componentTheme = ref ( 0 );

  const boardSettings = ref ( {
    start_date: store.getters.getDisplay.board.start_date,
    n_days: store.getters.getDisplay.board.n_days
  } );

  const forceInitCalendar = () => {
    componentCalendar.value += 1;
  };

  const forceInitTheme = () => {
    componentTheme.value += 1;
  };

  const init = () => {

    moment.locale( store.getters.getLocale );
    boardStartDate.value = moment();

    let url = API.get_api( API.API_NAMES.BOARD );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        if ( response.data.sites && response.data.sites.length > 0 ) {
          sites.value = response.data.sites;

          url = API.get_api( API.API_NAMES.BOARD_THEMES, sites.value[ 0 ] );
          AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
            .then( ( response ) => {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
              themes.value = response.data;
              forceInitTheme();
              utils.showAxiosError( response );
            } )
            .catch( ( err ) => {
              utils.showCatch( err );
            } );

          url = API.get_api( API.API_NAMES.BOARD_ACTIONS, sites.value[ 0 ] );
          AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
            .then( ( response ) => {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
              actions.value = response.data;
              forceInitCalendar();
              utils.showAxiosError( response );
            } )
            .catch( ( err ) => {
              utils.showCatch( err );
            } );
        }
        forceInitCalendar();
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );


    // AXIOS.get( AXIOS.get( API.get_api( API.API_NAMES.MY_SITES ), { params: { }, headers: { 'auth-token': store.getters.getToken } } )
    //   .then( ( response ) => {
    //     store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
    //     if ( response.data.length > 0 ) {
    //       site.value = response.data[ 0 ];

    //       let promises = [];

    //       promises.push ( AXIOS.get( API.get_api( API.API_NAMES.BOARD_THEMES, site.value.id ), { params: { }, headers: { 'auth-token': store.getters.getToken } } ) );
    //       promises.push ( AXIOS.get( API.get_api( API.API_NAMES.BOARD_ACTIONS, site.value.id ), { params: { }, headers: { 'auth-token': store.getters.getToken } } ) );

    //       Promise.all( promises )
    //         .then( ( response ) => {
    //           store.dispatch( 'updateToken', { token: response[ 0 ].headers[ 'auth-token' ] } );
    //           if ( response[ 0 ].data.length > 0 ) {
    //             themes.value = response[ 0 ].data;
    //           }
    //           if ( response[ 1 ].data.length > 0 ) {
    //             actions.value = response[ 1 ].data;
    //           }

    //           forceInitRerender();
    //           utils.showAxiosError( response );
    //         } )
    //         .catch( ( err ) => {
    //           utils.showCatch( err );;
    //         } );
    //     }
    //     store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
    //     utils.showAxiosError( response );
    //   } )
    //   .catch( ( err ) => {
    //     utils.showCatch( err );
    //   } );
  };

  init();
</script>
