import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import VueNotificationList from '@dafcoe/vue-notification';
import VueCryptojs from 'vue-cryptojs';
import { createVfm } from 'vue-final-modal';
import VChart from 'vue-echarts';

import './assets/tailwind.css';
import 'vue3-easy-data-table/dist/style.css';
import '@dafcoe/vue-notification/dist/vue-notification.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-final-modal/style.css';
import { Collapse } from 'vue-collapsed';

import SelectLanguage from './components/object/SelectLanguage';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faRightToBracket, faGear, faHome, faArrowUpShortWide, faArrowDownShortWide, faSignal,
         faCheck, faXmark, faCalendar, faClock, faTrashCan, faPlus, faAnglesLeft, faAnglesRight,
         faMagnifyingGlassPlus, faMagnifyingGlassMinus, faTable, faCircleInfo, faGears, faShieldHalved,
         faLightbulb, faChartLine, faArrowRight, faHourglassHalf, faCircleCheck, faChartPie, faCircleHalfStroke,
         faChevronRight, faDownload, faUpload, faPen, faEye, faFilter, faArrowUpAZ, faC, faBackward, faSort,
         faSortUp, faSortDown, faClipboardList, faUsers, faBuilding, faIdBadge, faCircleExclamation,
         faTriangleExclamation, faFish, faGripVertical, faRotateRight
       }
       from '@fortawesome/free-solid-svg-icons';

library.add( [ faRightToBracket, faGear, faHome, faArrowUpShortWide, faArrowDownShortWide, faSignal,
              faCheck, faXmark, faCalendar, faClock, faTrashCan, faPlus, faAnglesLeft, faAnglesRight,
              faMagnifyingGlassPlus, faMagnifyingGlassMinus, faTable, faCircleInfo, faGears, faShieldHalved,
              faLightbulb, faChartLine, faArrowRight, faHourglassHalf, faCircleCheck, faChartPie, faCircleHalfStroke,
              faChevronRight, faDownload, faUpload, faPen, faEye, faFilter, faArrowUpAZ, faC, faBackward, faSort,
              faSortUp, faSortDown, faClipboardList, faUsers, faBuilding, faIdBadge, faCircleExclamation,
              faTriangleExclamation, faFish, faGripVertical, faRotateRight
            ] );

const vfm = createVfm();

createApp( App )
  .use( i18n )
  .use( store )
  .use( router )
  .use( VueNotificationList )
  .use( VueCryptojs )
  .use( vfm )
  .component( 'EasyDataTable', Vue3EasyDataTable )
  .component( 'SelectLanguage', SelectLanguage )
  .component( 'font-awesome-icon', FontAwesomeIcon )
  .component( 'VChart', VChart )
  .component( 'Collapse', Collapse )
  .mount( '#app' );
