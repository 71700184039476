<template>
  <div class="flex flex-col font-sans items-center justify-center font-bold w-full h-full has-tooltip">
    <div class="tooltip flex flex-col bg-gray-100 rounded-lg py-1 px-2">
      <div class="text-sm underline">
        {{ t( 'Recurrent resolution' ) }}
      </div>
      <div class="text-xs">
        {{ t( 'There are x recurring RP out of a total of y', [ props.data.number_recurrent_rp, props.data.number_all_rp ] ) }}
      </div>
    </div>
    <div class="text-base w-fit h-fit text-center"> {{ t( 'Recurrence' ) }} </div>
    <div class="flex flex-col text-sm items-center justify-center w-full h-full">
      <div class="flex items-center">
        <p class="text-red-700 text-xl">{{ props.data.number_recurrent_rp }}</p>&nbsp;/&nbsp;{{ props.data.number_all_rp }}
      </div>
      <div class="flex items-center" v-if="!Number.isNaN( props.data.pourcent )">
        {{ props.data.pourcent }} <p class="m-1 text-xs">%</p>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps( {
    data: { Array, required: true }
  } );
</script>