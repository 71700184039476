<template>
  <div class="flex h-full w-full p-5 gap-8">
    <fieldset class="w-full h-fit round border shadow-lg opacity-95 rounded-xl space-y-4 p-4">
      <div class="flex flex-row items-center justify-center">
        <span class="font-bold text-3xl mb-2 px-2">{{ t( 'Users list' ) }}</span>
      </div>
      <div class="flex flex-row items-center justify-center">
        <select @change="select_changed($event)"
                class="text-gray-800 bg-gray-150 border-2 border-r-0 border-gray-150 text-sm rounded-l-lg p-2 h-[40px]">
          <option value="">{{ t("All fields") }}</option>
          <option v-for="item in headers" :key="item.value" :value="item.value">
              {{ item.text }}
          </option>
        </select>
        <input type="search" :placeholder="t( 'Search bar' ) + '...'" v-model="searchedUsers" class="bg-gray-150 text-sm w-96 rounded-r-lg p-2 border-2 border-gray-150 h-[40px]"/>
      </div>
        <EasyDataTable v-if="users" alternating buttons-pagination
          class="w-full h-full"
          :empty-message="t( 'No Available Data' )"
          :rows-of-page-separator-message="t( 'of' )"
          :rows-items="[25, 50, 75, 100]"
          :rows-per-page-message="t( 'Show' )"
          :rows-per-page="display.row_per_page"
          :headers="headers" :items="users"
          :search-value="searchedUsers"
          :search-field="searchedField"
          sort-by="lastname"
          @click-row="show_user"
          table-class-name="customize-table">
        </EasyDataTable>
    </fieldset>
    <UpdateUser :key="selectedUser" :selectedUser="selectedUser" :services="services" @update="updateUser" @close="close"/>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import utils from '@/features/utils.js';
  import API from '@/constants/api.constants';
  import { useI18n } from 'vue-i18n';
  import UpdateUser from '@/components/management/UpdateUser.vue';

  const { t } = useI18n();

  const users = ref( [] );
  const searchedUsers = ref( '' );
  const searchedField = ref();
  const selectedUser = ref( null );
  const services = ref( [] );
  const headers = [
    { text: t( 'Firstname' ), value: 'firstname', sortable: true },
    { text: t( 'Lastname' ), value: 'lastname', sortable: true },
    { text: t( 'Service name' ), value: 'service', sortable: true },
    { text: t( 'Language' ), value: 'language_formatted', sortable: true }
  ];
  const display = ref( {
    row_per_page: store.getters.getDisplay.dashboard.row_per_page
  } );

  onMounted( () => {
    getUsers();
  } );

  function getUsers() {
    let url = API.get_api( API.API_NAMES.USERS );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        let users_result = response.data;
        let url = API.get_api( API.API_NAMES.SERVICES );
        AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            services.value = response.data.map( ( service ) => { return { val: service.id, text: service.name }; } );
            users.value = users_result.map( ( user ) => {
              if ( user.disabled === false ) {
                user.service = services.value.find( ( service ) => service.val == user.id_service ).text;
                user.language_formatted = user.language ? t( user.language ) : '';
                return user;
              }
            } );
            utils.showAxiosError( response );
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
          } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  }

  function updateUser( id, body ) {
    let url = '';
    if ( body.email ) {
      url = API.get_api( API.API_NAMES.USERS, 'email', id );
    } else {
      url = API.get_api( API.API_NAMES.USERS, id );
    }
    AXIOS.put( url, body, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          getUsers();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  }

  /*
  Décommenter pour supprimer un Utilisateur
  function deleteUser( id ) {
    let url = API.get_api( API.API_NAMES.USERS, 'remove', id );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          getUsers();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  }
  */

  function show_user( params ) {
    close();
    selectedUser.value = params;
  }

  const select_changed = ( event ) => {
    searchedField.value = event.target.value;
  };

  const close = () => {
    selectedUser.value = null;
  };
</script>