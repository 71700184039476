<template>
  <nav class="top-0 z-50 fixed w-full bg-white shadow-lg">
    <div class="mx-auto px-4">
      <div class="flex justify-center">
        <div class="flex space-x-7">
          <div>
            <!-- Website Logo -->
            <a href="#" class="flex items-center py-4 px-2">
              <img src="@/../public/img/base_textlogo_transparent_background.png" alt="Logo" class="h-8 w-18 mr-2">
            </a>
          </div>
          <!-- Primary Navbar items -->
          <div class="hidden md:flex items-center space-x-1">
            <router-link to="/dashboard" class="router-link"><font-awesome-icon class="fa-xl" icon="fa-solid fa-home" />&nbsp;{{ t('Dashboard') }}</router-link>
            <router-link v-if="store.getters.getLastRPVisitedURL != null
              && store.getters.getLastRPVisitedURL !== '' && store.getters.getLastRPVisitedURL !== '/dashboard'"
              :to="store.getters.getLastRPVisitedURL" class="router-link flex items-center justify-center">
              <!-- <StepBackwardIcon class="-ml-2" :size="35"></StepBackwardIcon> -->
              <font-awesome-icon icon="fa-solid fa-backward" size="xl" class="pr-1"/>
              &nbsp;
              <p>{{ t('Last RP visited') }}</p>
            </router-link>
            <router-link to="/board" class="router-link"><font-awesome-icon class="fa-xl" icon="fa-solid fa-table" />&nbsp;{{ t('Board') }}</router-link>
            <router-link to="/settings" v-if="isUpdateSettings" class="router-link"><font-awesome-icon class="fa-xl" icon="fa-solid fa-gear" />&nbsp;{{ t('Settings') }}</router-link>
            <router-link to="/management" class="router-link"><font-awesome-icon class="fa-xl" icon="fa-solid fa-clipboard-list"/>&nbsp;{{ t( 'Management' ) }}</router-link>
            <a v-on:click="logout()" href="" class="router-link" :title="t('Logout')"><font-awesome-icon class="fa-xl" icon="fa-solid fa-right-to-bracket" />&nbsp;{{ t('Logout') }}</a>
          </div>
        </div>
        <!-- Mobile menu button -->
        <div class="md:hidden flex items-center">
          <button class="outline-none mobile-menu-button">
          <svg class=" w-6 h-6 text-tertiary-text hover:text-green-500 "
            x-show="!showMenu"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
        </div>
      </div>
    </div>
    <!-- mobile menu -->
    <div class="hidden mobile-menu">
      <ul class="">
      </ul>
    </div>
  </nav>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import router from '@/router';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import rights from '@/features/rights';
  import API from '@/constants/api.constants';

  const { t } = useI18n();
  const isUpdateSettings = ref( rights.isUpdateSettings() );

  const logout = () => {
    let url = API.get_api( API.API_NAMES.SIGN_OUT );
    AXIOS.post( url )
      .then( ( response ) => {
        store.dispatch( 'setConnected', { connect: false, connect_user: null, token: '' } );
        utils.showAxiosError( response );
        router.push( '/' );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };
</script>

<style scoped lang="scss">

  .router-link {
    @apply py-4;
    @apply px-2;
    @apply text-gray-500;
    @apply font-semibold;
    @apply hover:text-green-500;
    @apply transition;
    @apply duration-300;
  }
  .router-link-active {
    @apply text-green-500;
    @apply border-b-4;
    @apply border-green-500;
  }
</style>