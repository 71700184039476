<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-md"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="space-y-5 text-xl">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center px-3"> {{ t( 'Edit a file' ) }} </h1>

      <!-- Modal Body -->
      <Field :title="t( 'Title' )" :field="curFile.title" fkey="title"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.DOCUMENT_TITLE"/>
      <Field :title="t( 'Description' )" :field="curFile.description" fkey="description"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.DOCUMENT_DESCRIPTION"/>

      <!-- Modal footer-->
      <div class="flex space-x-4 justify-end">
        <div>
          <LoadingButton type="submit" :label="t( 'Save' )" @click="confirm"/>
        </div>
        <div>
          <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    file: { Object, require: true }
  } );
  const emit = defineEmits( [ 'confirm', 'cancel' ] );
  const curFile = ref( Object.assign( {}, props.file ) );
  const { t } = useI18n();

  const changeField = ( fkey, val ) => {
    curFile.value[ fkey ] = val;
  };

  const confirm = () => {
    emit( 'confirm', curFile.value );
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>