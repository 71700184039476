<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-md"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">
    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>
    <!-- Modal header -->
    <div>
      <div class="bg-white grid grid-cols-12">
        <div class="col-span-11">
          <h1 class="text-xl font-bold text-center mb-2"> {{ t( 'Modify a section' ) }} </h1>
        </div>
      </div>
    </div>

    <!-- Modal Body -->
    <div class="flex flex-col text-xl space-y-4">
      <Field :title="t( 'Section label' )"
        :placeholder="t( 'Label' )"
        :field="template.label"
        fkey="label"
        @change="changeField"
        :key="componentKey"
        :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_LABEL"/>
      <FieldArea :title="t( 'Is field description' )"
        :placeholder="t( 'Is field description' )"
        :field="template.is_desc"
        fkey="is_desc"
        class="text-lg"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_IS_DESC"/>
      <FieldArea :title="t( 'Is not field description' )"
        :placeholder="t( 'Is not field description' )"
        :field="template.is_not_desc"
        fkey="is_not_desc"
        class="text-lg"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_IS_NOT_DESC"/>
      <FieldArea :title="t( 'Particularity field description' )"
        :placeholder="t( 'Particularity field description' )"
        :field="template.particularities_desc"
        fkey="particularities_desc"
        class="text-lg"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_PARTICULARITIES_DESC"/>
      <FieldArea :title="t( 'Change field description' )"
        :placeholder="t( 'Change field description' )"
        :field="template.changes_desc"
        fkey="changes_desc"
        class="text-lg"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_CHANGES_DESC"/>
    </div>

    <!-- Modal footer-->
    <div class="flex space-x-4 pt-4 justify-end">
      <div>
        <LoadingButton type="submit" :label="t( 'Save' )" @click="changeTemplate"/>
      </div>
      <div>
        <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    is_is_not_template: { Object, required: true }
  } );
  const emit = defineEmits( [ 'changeTemplate', 'cancel' ] );

  const componentKey = ref( 0 );
  const template = ref( Object.assign( { }, props.is_is_not_template ) );
  const { t } = useI18n();

  const forceRerender = () => {
    componentKey.value += 1;
  };

  const changeField = ( fkey, newValue ) => {
    if ( fkey && fkey != '' && newValue != null
      && ( fkey !== 'label' || ( fkey === 'label' && newValue.trim() !== '' ) ) ) {
      template.value[ fkey ] = newValue;
    } else {
      forceRerender();
    }
  };

  const changeTemplate = () => {
    if ( template.value != null && template.value[ 'label' ].trim() != null && template.value[ 'label' ].trim() != '' ) {
      emit( 'changeTemplate', template.value );
    } else {
      clickClose();
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>