<template>
  <div class="popup w-[94vw] h-[95vh] overflow-x-hidden">
    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex flex-row w-full h-full">
      <div class="flex flex-col w-[30%] p-4 gap-y-4">
        <!-- Retrieved StickyNotes lists -->
        <div class="flex flex-col gap-y-4 rounded shadow shadow-shadowColor p-3">
          <div class="text-2xl font-bold text-center">{{ t( 'StickyNotes List' ) }}</div>
          <details v-for="(item, idx) in sticky_notes" :key="item" class="flex flex-col gap-y-3 p-3 bg-white shadow shadow-shadowColor rounded group" v-bind:open="idx==0">
            <summary class="list-none flex flex-wrap items-center cursor-pointer
              focus-visible:outline-none rounded
              group-open:rounded-b-none group-open::z-[1] relative
              hover:bg-tertiary-hover -m-3 p-3">
              <div class="flex flex-1 font-bold">{{ moment(item.register_date).format('LL') }}</div>
              <div class="flex w-10 items-center justify-center">
                <div class="border-8 border-transparent border-l-black ml-2
                group-open:rotate-90 transition-transform origin-left"></div>
              </div>
            </summary>
            <div class="grid grid-cols-3 gap-[1%] pt-4">
              <div v-for="(file, idx_img) in item.files" :key="file" :style='"display:"+file.display+";"'>
                <img class="w-full aspect-square" :src="file.src" :sticky_notes_idx="idx" :image_idx="idx_img" :draggable="true" @dragstart="dragStart"/>
                <div class="relative">
                  <div class="absolute bottom-2 right-2">
                    <button><font-awesome-icon icon="fas fa-xmark" size="xl" @click="delete_picture(idx, idx_img)"/></button>
                  </div>
                </div>
              </div>
            </div>
          </details>
        </div>
        <!-- File Dropzone -->
        <div class="flex flex-col gap-y-4 rounded shadow shadow-shadowColor p-3">
          <div class="text-2xl font-bold text-center">{{ t( 'StickyNotes' ) }}</div>
          <FilesDropZone ref="fileDropZone" :maxNumberOfFiles="MAX_NUMBER_OF_FILES"
            :dropZoneText="t( 'Drag \'n\' drop some file here' )"
            :uploadBtnLabel="t( 'Run' )"
            @uploadFiles="run">
          </FilesDropZone>
        </div>
      </div>
      <div class="grow">
        <!-- Ishikawa drag & drop areas -->
        <div class="relative">
          <div class="absolute flex flex-wrap justify-center space-x-[1%] -ml-[2%] h-full w-full gap-y-[1.8%]">
            <!-- Top areas -->
            <div v-for="(ishikawa_7m, idx) in evenIdx7mList(ishikawa_7m)" :key="ishikawa_7m" class="flex flex-col w-[20%] h-1/2 items-start"  @drop="drop" :idx="evenIdx( idx )" droppable="true">
              <p class="text-2xl w-full" :idx="evenIdx( idx )" droppable="true">{{ ishikawa_7m }}</p>
              <!-- Pictures list -->
              <div class="flex flex-wrap w-full overflow-y-auto overflow-x-hidden" :idx="evenIdx( idx )" droppable="true">
                <div v-for="ishikawa_7m_image in ishikawa_7m_images[evenIdx( idx )]" :key="ishikawa_7m_image" :idx="evenIdx( idx )" droppable="true" class="aspect-square w-[30%] h-fit mr-[2%] mb-[2%]">
                  <img class="aspect-square h-full" :idx="evenIdx( idx )" droppable="true" :src="ishikawa_7m_image.image_src" :sticky_notes_idx="ishikawa_7m_image.sticky_notes_idx" :image_idx="ishikawa_7m_image.image_idx" :draggable="true" @dragstart="dragStart"/>
                  <div class="relative" droppable="true" :idx="evenIdx( idx )">
                    <button class="absolute bottom-0 right-1 text-sm" droppable="true" @click="remove_picture(evenIdx( idx ), ishikawa_7m_image.sticky_notes_idx, ishikawa_7m_image.image_idx)" :idx="evenIdx( idx )">
                      <font-awesome-icon icon="fas fa-xmark" size="xl" droppable="true" :idx="evenIdx( idx )"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Bottom areas -->
            <div v-for="(ishikawa_7m, idx) in oddIdx7mList(ishikawa_7m)" :key="ishikawa_7m" class="flex flex-col w-[25%] h-1/2 justify-end" @drop="drop" :idx="oddIdx( idx )" droppable="true">
              <!-- Pictures list -->
              <div class="flex flex-wrap-reverse w-[80%] overflow-y-auto overflow-x-hidden" :idx="oddIdx( idx )" droppable="true">
                <div v-for="ishikawa_7m_image in ishikawa_7m_images[oddIdx( idx )]" :key="ishikawa_7m_image" :idx="oddIdx( idx )" droppable="true" class="aspect-square mr-[2%] mb-[2%] w-[30%] h-fit">
                  <img class="aspect-square h-full" :idx="oddIdx( idx )" droppable="true" :src="ishikawa_7m_image.image_src" :sticky_notes_idx="ishikawa_7m_image.sticky_notes_idx" :image_idx="ishikawa_7m_image.image_idx" :draggable="true" @dragstart="dragStart"/>
                  <div class="relative" droppable="true" :idx="evenIdx( idx )">
                    <button class="absolute bottom-0 right-1 text-sm" droppable="true" :idx="evenIdx( idx )" @click="remove_picture(oddIdx( idx ), ishikawa_7m_image.sticky_notes_idx, ishikawa_7m_image.image_idx)" >
                      <font-awesome-icon icon="fas fa-xmark" size="xl" droppable="true" :idx="evenIdx( idx )"/>
                    </button>
                  </div>
                </div>
              </div>
              <p class="text-2xl w-full" :idx="oddIdx( idx )" droppable="true">{{ ishikawa_7m }}</p>
            </div>
          </div>
          <!-- Ishikawa fishbone diagram SVG -->
          <img src="@/../public/img/Ishikawa_test_2.svg" alt="Ishikawa fishbone diagram">
        </div>

        <div class="float-right mt-[5%]">
          <div class="flex w-fit gap-x-4">
            <LoadingButton type="submit" ref="validateBtn" :label="t('Save')" @click="validate()"/>
            <LoadingButton :label="t('Cancel')" @click="clickClose"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import moment from 'moment-timezone';
  import utils from '@/features/utils.js';
  import FilesDropZone from '@/components/file/FilesDropZone.vue';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import settings from '@/features/settings';
  import path from 'path-browserify';
  import API from '@/constants/api.constants';

  const { t } = useI18n();
  const sticky_notes = ref( [] );

  const MAX_NUMBER_OF_FILES = 1;
  const validateBtn = ref( null );
  const ishikawa_7m = ref( [] );
  const ishikawa_7m_images = ref ( [ [], [], [], [], [], [], [] ] );
  const fileDropZone = ref( null );

  const props = defineProps( {
    id_rp: { String, required: true }
  } );
  const emit = defineEmits( [ 'clickClose', 'changeCauseList' ] );

  const init = () => {
    sticky_notes.value = [];
    moment.locale( store.getters.getLocale );
    AXIOS.get( API.get_api( API.API_NAMES.RP_STICKY_NOTES, props.id_rp + '?type=' + 'ishikawa7M' ), { headers: { 'auth-token': store.getters.getToken, 'Content-Type': 'multipart/form-data' } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        response.data.forEach( ( item ) => {
          item.origin = utils.get_api_base_url() + path.join( '/public', item.path, item.origin );
          item.files = item.files.map( ( it ) => { return { src: utils.get_api_base_url() + path.join( '/public', item.path, it ), display: 'block' }; } );
          sticky_notes.value.push( item );
        } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const getFormData = ( element ) => {
    const formData = new FormData();
    formData.append( 'file', element.file );
    formData.append( 'type', 'ishikawa7M' );
    return formData;
  };

  const run = ( filesToUpload ) => {
    if ( filesToUpload.length === 0 ) fileDropZone.value.stopLoading();

    filesToUpload.forEach( ( file ) => {
      AXIOS.post( API.get_api( API.API_NAMES.RP_STICKY_NOTES, props.id_rp ), getFormData( file ), { headers: { 'auth-token': store.getters.getToken, 'Content-Type': 'multipart/form-data' } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          fileDropZone.value.clearFilesList();
          fileDropZone.value.stopLoading();
          init();
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          fileDropZone.value.stopLoading();
          utils.showCatch( err );
        } );
    } );
  };

  const delete_picture = ( idx_main, idx_img ) => {
    let main = sticky_notes.value[ idx_main ];
    let path = main.path.substring( main.path.lastIndexOf( '/' ) + 1 );
    let image = main.files[ idx_img ].src.substring( main.files[ idx_img ].src.lastIndexOf( '/' ) + 1 );
    AXIOS.delete( API.get_api( API.API_NAMES.RP_STICKY_NOTES + '?id=' + main.id + '&path=' + path + '&image=' ) + image, { headers: { 'auth-token': store.getters.getToken, 'Content-Type': 'multipart/form-data' } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        if ( response.status === 201 ) {
          sticky_notes.value[ idx_main ].files.splice( idx_img, 1 );
          if ( sticky_notes.value[ idx_main ].files.length == 0 ) {
            sticky_notes.value.splice( idx_main, 1 );
          }
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  onMounted( async () => {
    ishikawa_7m.value = await settings.getOneSetting( 'ishikawa_7m' );
  } );

  const remove_picture = ( idx, sticky_notes_idx, image_idx ) => {
    let remove_idx = -1;
    for ( let i = 0; i < ishikawa_7m_images.value[ idx ].length; i++ ) {
      if ( ishikawa_7m_images.value[ idx ][ i ].sticky_notes_idx == sticky_notes_idx &&
        ishikawa_7m_images.value[ idx ][ i ].image_idx == image_idx ) {
        remove_idx = i;
      }
    }
    if ( remove_idx != -1 ) {
      ishikawa_7m_images.value[ idx ].splice( remove_idx, 1 );
    }
    sticky_notes.value[ sticky_notes_idx ].files[ image_idx ] ? sticky_notes.value[ sticky_notes_idx ].files[ image_idx ].display = 'block' : null;
  };

  const drop = ( event ) => {
    if ( event.target.getAttribute( 'droppable' ) == 'true' ) {
      const sticky_notes_idx = event.dataTransfer.getData( 'sticky_notes_idx' );
      const image_idx = event.dataTransfer.getData( 'image_idx' );
      const image_src = event.dataTransfer.getData( 'image_src' );
      const origin_idx = event.dataTransfer.getData( 'origin_idx' );
      let index_ishikawa = event.target.getAttribute( 'idx' );

      // Move picture to Ishikawa
      ishikawa_7m_images.value[ index_ishikawa ].push( {
        sticky_notes_idx: sticky_notes_idx,
        image_idx: image_idx,
        image_src: image_src
      } );

      if ( origin_idx == 'null' ) {
        // Hide to list
        sticky_notes.value[ sticky_notes_idx ].files[ image_idx ].display = 'none';
      } else {
        let remove_idx = -1;
        for ( let i = 0; i < ishikawa_7m_images.value[ origin_idx ].length; i++ ) {
          if ( ishikawa_7m_images.value[ origin_idx ][ i ].sticky_notes_idx == sticky_notes_idx &&
            ishikawa_7m_images.value[ origin_idx ][ i ].image_idx == image_idx ) {
            remove_idx = i;
          }
        }
        if ( remove_idx != -1 ) {
          ishikawa_7m_images.value[ origin_idx ].splice( remove_idx, 1 );
        }
      }
    }
  };

  const validate = () => {
    let promises = [];
    ishikawa_7m_images.value.forEach( ( category, cat_idx ) => {
      category.forEach( ( images, img_idx ) => {

        promises.push( AXIOS.post( API.get_api( API.API_NAMES.RP_CAUSES, props.id_rp ), { category: Number( cat_idx + 1 ), position: img_idx + 1, path: images.image_src, description: t( 'Description PLACEHOLDER' ) }, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '200' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            }
            utils.showAxiosError( response );
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
          } )
        );
      } );
    } );

    Promise.all( promises ).then( () => {
      validateBtn.value.stopLoading();
      promises.length > 0 ? emit( 'changeCauseList' ) : undefined;
      emit( 'clickClose' );
    } );
  };

  const dragStart = ( event ) => {
    event.dataTransfer.setData( 'sticky_notes_idx', event.target.getAttribute( 'sticky_notes_idx' ) );
    event.dataTransfer.setData( 'image_idx', event.target.getAttribute( 'image_idx' ) );
    event.dataTransfer.setData( 'image_src', event.target.getAttribute( 'src' ) );
    event.dataTransfer.setData( 'origin_idx', event.target.getAttribute( 'idx' ) );
  };

  const clickClose = () => {
    emit( 'clickClose' );
  };

  const evenIdx7mList = ( list ) => {
    return list.filter( ( element ) =>
      list.indexOf( element ) % 2 == 0
    );
  };

  const oddIdx7mList = ( list ) => {
    return list.filter( ( element ) =>
      list.indexOf( element ) % 2 != 0
    );
  };

  const evenIdx = ( idx ) => {
    return idx * 2;
  };

  const oddIdx = ( idx ) => {
    return idx * 2 + 1;
  };

  init();
</script>