<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-lg overflow-visible"
    :esc-to-close="true" overlay-transition="vfm-fade" content-transition="vfm-fade"
    :click-to-close="true" :lock-scroll="true">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex-col space-y-4">
      <!-- Modal header -->
      <div class="text-secondary-text text-base space-y-3">
        <!-- Modal Title -->
        <h1 class="text-2xl/5 font-bold text-center">{{ t( 'Event details' ) }}</h1>

        <!-- Modal Indicators -->
        <div class="flex justify-center align-item-center items-center space-x-3" :key="componentKey">
          <div v-if="isCapitalisationAction()" class="">
            <div class="has-tooltip">
              <span class='tooltip whitespace-pre-line'>{{ t( 'Capitalising action' ) }}</span>
              <div class="flex justify-center items-center pr-3">
                <img src="@/../public/img/capitalising_logo.png" class="h-8 w-8">
              </div>
            </div>
          </div>
          <div v-if="lateDays() > 0" class="has-tooltip pb-0.5 px-2 rounded bg-red-600 text-white text-xl font-semibold">
            <span class='tooltip whitespace-pre-line'>{{ t( 'Late days' ) }}</span>
            {{ lateDays() }}
          </div>
          <div v-if="( isCompletedAction() || isEfficientAction() )">
            <div class="has-tooltip">
              <span class='tooltip whitespace-pre-line'>{{ t( 'Action completed' ) }}</span>
              <font-awesome-icon icon="fa-solid fa-circle-check" size="2xl" style="color: #45D52E;" />
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Body -->
      <div :key="componentKey" class="flex flex-col space-y-2">
        <div class="font-bold text-xl w-full">
          <Field :placeholder="t( 'Title PLACEHOLDER' )" :field="curAction.title"
            fkey="title" @change="changeField"
            :maxlength="MAX_INPUT_LENGTH.ACTION_TITLE"/>
        </div>
        <FieldArea :placeholder="t( 'Description PLACEHOLDER' )" :field="curAction.description"
          fkey="description" @change="changeField"
          :maxlength="MAX_INPUT_LENGTH.ACTION_DESCRIPTION"/>
          <div v-if="curActionUser != null" :key="curActionUser" >
          <div class="flex flex-row grow gap-4 items-center">
            <span v-if="isEfficientAction()" class="w-fit bg-green-500 text-white text-center text-xs font-bold w-64 mr-2 px-2.5 py-0.5 h-5 rounded"> {{ t( 'Efficient' ) }}</span>
            <span v-else-if="isCompletedAction()" class="w-fit bg-blue-500 text-white text-center text-xs font-bold w-64 mr-2 px-2.5 py-0.5 h-5 rounded"> {{ t( 'Completed' ) }}</span>
            <div v-else-if="isPlannedAction()" class="flex flex-row w-fit">
              <span class="w-fit bg-purple-600 text-white text-center text-xs font-bold mr-2 px-2.5 py-0.5 h-5 rounded"> {{ t( 'Planned Singular' ) }}</span>
              <div v-if="lateDays() > 0" class="py-0.5 px-1 w-min rounded bg-red-600 text-white text-xs font-bold" droppable="true">
                {{ lateDays() }}
              </div>
            </div>
            <div class="text-secondary-text font-bold">{{ t( 'Pilote' ) }}</div>
            <InputSelect :items="userSelectList" :selected="curActionUser.user.id"
                :rkey="curActionUser.id"
                @change-value="changePilote"
                :readonly="actionUserReadOnly()"/>
            <div class="flex w-[16rem] min-w-[16rem] w-fit space-x-3 items-center">
              <FieldDate :title="t('Due date')"
                :field="curActionUser.delivery_date"
                :rkey="curActionUser.id"
                :minDate="minDeliveryDate"
                @change="changeDate"
                @clear-date="changeDate( moment().format( 'YYYY-MM-DD' ) )"
                :readonly="actionUserReadOnly()"
                :key="componentKey"/>
            </div>
          </div>
          <div v-if="( isCompletedAction() || isEfficientAction() )
            && curActionUser.is_efficient != null
            && !isCapitalisationAction()"
            class="flex flex-row space-x-4 justify-start items-center">
            <div class="text-secondary-text font-bold">{{ t( 'Action efficient' ) }}</div>
            <input type="checkbox" @click="changeEfficiency( !curActionUser.is_efficient )" :checked="curActionUser.is_efficient"
              class="w-6 h-6 accent-green-600/95 rounded" :class="!readonly ? 'cursor-pointer' : ''" :disabled="readonly">
          </div>
        </div>
        <!-- <FilesDropZone :files="curAction.docs" /> -->
      </div>

      <!-- Modal footer-->
      <div class="flex space-x-4 pt-4 justify-end">
        <div>
          <LoadingButton v-if="curAction.action_users ? curAction.action_users.length > 0 : false" ref="archiveBtn" type="submit" :label="t('Archived')" @click="archive_confirm"/>
        </div>
        <div v-if="!( isCompletedAction() || isEfficientAction() )">
          <LoadingButton type="submit" :label="t('Completed')" @click="complete( true )"/>
        </div>
        <div v-if="( isCompletedAction() || isEfficientAction() )">
          <LoadingButton type="submit" :label="t('Uncompleted')" @click="complete( false )"/>
        </div>
        <div>
          <LoadingButton type="submit" :label="t('Save')" @click="save" ref="saveBtn" />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import { VueFinalModal } from 'vue-final-modal';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import moment from 'moment';
  import API from '@/constants/api.constants';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import InputSelect from '@/components/object/InputSelect.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldDate from '@/components/object/FieldDate.vue';
  // import FilesDropZone from '@/components/file/FilesDropZone.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  import { useModal } from 'vue-final-modal';
  import ConfirmModal from '@/components/modals/ConfirmModal.vue';
  import FieldArea from '@/components/object/FieldArea.vue';

  const props = defineProps( {
    action: { Array, required: true },
    id_user: { String, required: true },
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits( [ 'close', 'changeItem' ] );

  const { t } = useI18n();
  const componentKey = ref( 0 );

  // Local copies of action details & action_user details
  const curAction = ref( JSON.parse( JSON.stringify( props.action ) ) );
  const curActionUser = ref( props.action != null
    && props.action.action_users != null
    && Array.isArray( props.action.action_users )
    && props.action.action_users.length > 0
    ? JSON.parse( JSON.stringify( props.action.action_users[ 0 ] ) ) : null );

  const userSelectList = ref( [] );
  const archiveBtn = ref( null );
  const saveBtn = ref( null );
  const minDeliveryDate = ref( moment().toDate() );

  const lateDays = () => {
    let lateDays = 0;
    if ( isPlannedAction() ) {
      lateDays = moment( ).diff( curActionUser.value.delivery_date, 'days' );
    } else if ( isCompletedAction() || isEfficientAction() ) {
      lateDays = moment( curActionUser.value.completed_date ).diff( curActionUser.value.delivery_date, 'days' );
    }
    return lateDays;
  };

  const isCapitalisationAction = () => {
    return curAction.value.action_type != null ? curAction.value.action_type === 'capitalisation' : false;
  };

  const actionUserReadOnly = () => {
    if ( curActionUser.value != null ) {
      return curActionUser.value.completed_date != null && curActionUser.value.completed_date != '';
    }
    return true;
  };

  const init = () => {
    refreshPilotesList();
  };

  const isPlannedAction = () => {
    return curActionUser.value != null &&
      curActionUser.value.delivery_date != null &&
      curActionUser.value.delivery_date != '' &&
      ( curActionUser.value.completed_date == null ||
        curActionUser.value.completed_date == '' );
  };

  const isCompletedAction = () => {
    return curActionUser.value != null &&
      curActionUser.value.delivery_date != null &&
      curActionUser.value.delivery_date != '' &&
      curActionUser.value.completed_date != null &&
      curActionUser.value.completed_date != '' &&
      curActionUser.value.is_efficient != null &&
      curActionUser.value.is_efficient == false;
  };

  const isEfficientAction = () => {
    return curActionUser.value != null &&
      curActionUser.value.delivery_date != null &&
      curActionUser.value.delivery_date != '' &&
      curActionUser.value.completed_date != null &&
      curActionUser.value.completed_date != '' &&
      curActionUser.value.is_efficient != null &&
      curActionUser.value.is_efficient == true;
  };

  const refreshPilotesList = () => {
    // Get list of user auth tio this action
    userSelectList.value = [];
    let url = API.get_api( API.API_NAMES.RP_USERS, props.id_rp );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '200' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          let userAuth = response.data;
          userAuth.forEach( ( element ) => {
            userSelectList.value.push( {
              val: element.id,
              text: element.fullname,
            } );
          } );
          componentKey.value += 1;
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };


  const clickClose = () => {
    emit( 'close' );
  };

  const changeItem = () => {
    emit( 'changeItem' );
  };

  const changeField = ( fkey, val ) => {
    curAction.value[ fkey ] = val;
    componentKey.value += 1;
  };

  const changeDate = ( val ) => {
    if ( curActionUser.value != null ) {
      curActionUser.value.delivery_date = val;
      componentKey.value += 1;
    }
  };

  const changePilote = ( val ) => {
    if ( curActionUser.value != null ) {
      curActionUser.value.user.id = val;
      componentKey.value += 1;
    }
  };

  const changeEfficiency = ( val ) => {
    if ( curActionUser.value != null ) {
      curActionUser.value.is_efficient = val;
    }
  };

  const archive = () => {
    if ( curActionUser.value != null ) {
      curActionUser.value.archived_date = moment().format( 'YYYY-MM-DD' );
      save();
    }
  };

  const archiveConfirmModal = useModal( {
    component: ConfirmModal,
    attrs: {
      header: t( 'Confirmation of archiving unfinished engagement' ),
      body: t( 'Would you like to archive an engagement that hasn\'t been completed ?' ),
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',

      onConfirm() {
        archive();
        archiveConfirmModal.close();
      },
      onCancel() {
        archiveConfirmModal.close();
      },
      onClosed() {
        archiveBtn.value.stopLoading();
      }
    }
  } );

  const archive_confirm = () => {
    if ( curActionUser.value != null ) {
      if ( curActionUser.value.completed_date != null && curActionUser.value.completed_date != '' ) {
        archive();
      } else {
        archiveConfirmModal.open();
      }
    }
  };

  const complete = ( bState ) => {
    if ( curActionUser.value != null ) {
      if ( bState ) {
        curActionUser.value.completed_date = moment().format( 'YYYY-MM-DD' );
      } else {
        curActionUser.value.completed_date = '';
      }
      save();
    }
  };

  const save = () => {
    let promises = [];

    if ( props.action != null && curAction.value != null ) {
      let oriAction = props.action;

      // Send title or description if changes
      if ( ( curAction.value != null && oriAction != null )
        && ( oriAction.title != curAction.value.title || oriAction.description != curAction.value.description ) ) {
        let body = {
          title: oriAction.title != curAction.value.title ? curAction.value.title : undefined,
          description: oriAction.description != curAction.value.description ? curAction.value.description : undefined
        };
        promises.push( new Promise( ( resolve ) => {
          AXIOS.put( API.get_api( API.API_NAMES.ACTIONS, props.action.id ), body, { headers: { 'auth-token': store.getters.getToken } } )
            .then( ( response ) => {
              if ( response.status == '201' ) {
                store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
                resolve();
              }
              utils.showAxiosError( response );
            } )
            .catch( ( err ) => {
              utils.showCatch( err );
            } );
        } ) );
      }
    }

    let oriActionUser =  props.action.action_users[ 0 ] ?? null;
    if ( ( oriActionUser != null && curActionUser.value != null )
      && (
        oriActionUser.user.id != curActionUser.value.user.id
        || oriActionUser.delivery_date != curActionUser.value.delivery_date
        || oriActionUser.completed_date != curActionUser.value.completed_date
        || oriActionUser.archived_date != curActionUser.value.archived_date
        || oriActionUser.is_efficient != curActionUser.value.is_efficient
      ) ) {
      let body = {
        id_users: oriActionUser.user.id != curActionUser.value.user.id ? curActionUser.value.user.id : undefined,
        delivery_date: oriActionUser.delivery_date != curActionUser.value.delivery_date ? curActionUser.value.delivery_date : undefined,
        completed_date: oriActionUser.completed_date != curActionUser.value.completed_date ? curActionUser.value.completed_date : undefined,
        archived_date: oriActionUser.archived_date != curActionUser.value.archived_date ? curActionUser.value.archived_date : undefined,
        is_efficient: oriActionUser.is_efficient != curActionUser.value.is_efficient ? curActionUser.value.is_efficient : undefined
      };
      promises.push( new Promise( ( resolve ) => {
        AXIOS.put( API.get_api( API.API_NAMES.ACTIONS_USERS, oriActionUser.id ), body, { headers: { 'auth-token': store.getters.getToken } } )
          .then ( ( response ) => {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
          } );
      } ) );
    }

    Promise.all( promises )
      .then( () => {
        saveBtn.value != null ? saveBtn.value.stopLoading() : undefined;
        if ( promises.length > 0 ) {
          changeItem();
        }
        clickClose();
      } );
  };

  init();
</script>