<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-md"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex flex-col space-y-8">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center px-3"> {{ header }} </h1>

      <!-- Modal Body -->
      <h1 class="text-xl px-3" v-html="body"></h1>

      <!-- Modal footer-->
      <div class="flex space-x-4 justify-end">
        <div>
          <LoadingButton type="submit" :label="labelOpt1" @click="confirm"/>
        </div>
        <div>
          <LoadingButton type="submit" :label="labelOpt2" @click="clickClose"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';

  const props = defineProps( {
    header: { String, required: false },
    body: { String, required: false },
    labelOpt1: { String, required: false },
    labelOpt2: { String, required: false },
    escToClose: { Boolean, required: false, default: true },
    clickToClose: { Boolean, required: false, default: true },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false }
  } );
  const emit = defineEmits( [ 'confirm', 'cancel' ] );

  const { t } = useI18n();

  var header = props.header || t( 'Confirmation' );
  var body = props.body || '';
  var labelOpt1 = props.labelOpt1 || t( 'Yes' );
  var labelOpt2 = props.labelOpt2 || t( 'No' );

  const confirm = () => {
    emit( 'confirm' );
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>