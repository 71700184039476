<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-lg"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="space-y-4">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center"> {{ axisName }} </h1>

      <!-- Modal Body -->
      <div class="flex flex-col items-start justify-center space-y-4">
        <h1 class="text-xl font-bold"> {{ t( 'Probable causes list' ) }} </h1>
        <div v-if="causesCheckList().length > 0" class="flex space-x-3">
          <div>
            <!-- Field searched selector -->
            <select @change="selectChanged($event)"
                    class="text-gray-800 text-sm rounded-l-lg p-1 bg-gray-200 cursor-pointer">
              <option value="" class="bg-gray-200">{{ t("All fields") }}</option>
              <option v-for="item in headers" :key="item.value" :value="item.value" class="bg-gray-200">
                  {{ item.text }}
              </option>
            </select>
            <!-- Search field -->
            <input type="search" :placeholder="t( 'Search bar' ) + '...'" v-model="searchedValue"
            class="text-sm w-96 rounded-r-lg p-0.5 border-2 border-gray-200"/>
          </div>
          <!-- Radio buttons -->
          <div class="flex space-x-4 items-center accent-green-600/95">
            <div class="flex items-center space-x-1">
              <input type="radio" id="allCauses" name="causeFilter" @change="causesFilter = 'allCauses'"
              class="w-5 h-5 cursor-pointer" checked/>
              <label for="allCauses" class="cursor-pointer">{{ t( 'All causes' ) }}</label>
            </div>
            <div class="flex items-center space-x-1">
              <input type="radio" id="linkedCauses" name="causeFilter" @change="causesFilter = 'linkedCauses'"
              class="w-5 h-5 cursor-pointer"/>
              <label for="linkedCauses" class="cursor-pointer">{{ t( 'Linked causes' ) }}</label>
            </div>
            <div class="flex items-center space-x-1">
              <input type="radio" id="unLinkedCauses" name="causeFilter" @change="causesFilter = 'unLinkedCauses'"
              class="w-5 h-5 cursor-pointer"/>
              <label for="unLinkedCauses" class="cursor-pointer">{{ t( 'Unlinked causes' ) }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Causes scroll list -->
      <div v-if="causesCheckList().length > 0" class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
        <RPCauseListItem v-for="cause in searchFilterCauses()" :key="cause" :cause="cause" :readonly="true"></RPCauseListItem>
      </div>
      <div v-else>
        feur
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import RPCauseListItem from '@/components/rp/causes/RPCauseListItem.vue';
  import utils from '@/features/utils';

  const props = defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    causes_linked: { Array, required: false, default: [] },
    causes_unlinked: { Array, required: false, default: [] },
    axisName: { String, required: false, default: '' }
  } );
  const emit = defineEmits( [ 'cancel' ] );

  const { t } = useI18n();
  const headers = [
    { text: t( 'Ranking' ), value: 'Ranking', sortable: true },
    { text: t( 'Description' ), value: 'Description', sortable: true },
  ];
  const searchedValue = ref( '' );
  const searchedField = ref( '' );
  const causesFilter = ref( '' );

  const selectChanged = ( event ) => {
    searchedField.value = event.target.value;
  };

  const searchFilterCauses = () => {
    let result = causesCheckList();
    if ( searchedValue.value != '' ) {
      let search = searchedValue.value.toLowerCase();
      switch ( searchedField.value ) {
      case 'Ranking':
        result = causesCheckList().filter( ( cause ) =>
          ( cause.position ? String( cause.position ).toLowerCase().includes( search ) : false )
        );
        break;
      case 'Description':
        result = causesCheckList().filter( ( cause ) =>
          ( cause.description ? String( cause.description ).toLowerCase().includes( search ) : false )
        );
        break;
      default:
        result = causesCheckList().filter( ( cause ) =>
          ( cause.description ? String( cause.description ).toLowerCase().includes( search ) : false )
          || ( cause.position ? String( cause.position ).toLowerCase().includes( search ) : false )
        );
        break;
      }
    }
    switch ( causesFilter.value ) {
    case 'linkedCauses':
      result = result.filter( ( cause ) => ( cause ? cause.checked : false ) );
      break;
    case 'unLinkedCauses':
      result = result.filter( ( cause ) => ( cause ? !cause.checked : false ) );
      break;
    default:
      break;
    }
    return result;
  };

  const causesCheckList = () => {
    let causesLinkList = props.causes_linked.map( ( cause ) => {
      cause.checked = true;
      return cause;
    } );
    let causesUnlinkList = props.causes_unlinked.map( ( cause ) => {
      cause.checked = false;
      return cause;
    } );
    let finalList = utils.sortListByPositionAscending( causesLinkList.concat( causesUnlinkList ) );
    return finalList;
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>