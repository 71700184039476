import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '@/components/views/HomeView.vue';
import _404View from '@/components/views/404View.vue';
import SignInView from '@/components/views/SignInView.vue';
import SettingsView from '@/components/views/SettingsView.vue';
import DashboardView from '@/components/views/DashboardView.vue';
import StickyNotesView from '@/components/views/StickyNotesView.vue';
import BoardView from '@/components/views/BoardView.vue';
import RPView from '@/components/views/RPView.vue';
import ManagementView from '@/components/views/ManagementView';
import RPChartsView from '@/components/views/RPChartsView.vue';
import store from '@/store';
import AXIOS from '@/features/axios.js';
import utils from '@/features/utils.js';
import API from '@/constants/api.constants';

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      {
        path: 'dashboard',
        component: DashboardView
      },
      {
        path: 'board',
        component: BoardView
      },
      {
        path: 'settings',
        component: SettingsView
      },
      {
        path: '/rp/:id',
        component: RPView
      },
      {
        path: '/management',
        component: ManagementView
      },
      {
        path: '/sticky_notes/:id/:type',
        component: StickyNotesView
      },
      {
        path: '/charts/:id',
        component: RPChartsView
      }
    ]
  },
  {
    path: '/404',
    component: _404View
  },
  {
    path: '/login',
    component: SignInView
  }
];

const router = createRouter( {
  history: createWebHashHistory(),
  routes
} );

router.beforeEach( ( to, from, next ) => {

  if ( to.path === '/404' ) {
    return next();
  }

  const url_params = new URLSearchParams( window.location.search );
  const user_id = url_params.get( 'flashmeeting-user-id' );

  if ( user_id ) {
    AXIOS.get( API.get_api( API.API_NAMES.AUTH_IS_FM_USER_SIGN_IN ), { params: { 'user-id': user_id } } )
      .then( ( response ) => {
        if ( response.status === 200 ) {
          if ( utils.is_true( response.data.signin ) ) {
            window.location = window.location.origin;
          } else {
            return next( {
              path: '/404',
              query: { returnUrl: to.path }
            } );
          }
        } else {
          return next( {
            path: '/404',
            query: { returnUrl: to.path }
          } );
        }
        utils.showAxiosError( response );
      } )
      .catch( () => {
        return next( {
          path: '/404',
          query: { returnUrl: to.path }
        } );
      } );
  } else {

    AXIOS.get( API.get_api( API.API_NAMES.AUTH_IS_SIGN_IN_WEB ) )
      .then( ( response ) => {
        if ( response.status === 200 ) {
          if ( utils.is_true( response.data.signin ) ) {
            AXIOS.get( API.get_api( API.API_NAMES.USERS, response.data.id ), { headers: { 'auth-token': response.headers[ 'auth-token' ] } } )
              .then( ( user ) => {
                store.dispatch( 'setConnected', { connect: true, connect_user: user.data, token: user.headers[ 'auth-token' ] } );
                if ( to.path === '/login' || to.path === '/resetpwd' || to.path === '/coderesetpwd' || to.path === '/changepwd' ) {
                  return next( {
                    path: '/',
                    query: { returnUrl: to.path }
                  } );
                } else {
                  return next();
                }
              } )
              .catch( () => {
                return next( {
                  path: '/404',
                  query: { returnUrl: to.path }
                } );
              } );
          } else {
            store.dispatch( 'setAuthFlashmeeting', { url: response.data.url, automatic: response.data.automatic } );
            if ( !response.data.url || response.data.url && response.data.automatic === 'false' ) {
              if ( to.path !== '/login' && to.path !== '/resetpwd' && to.path !== '/coderesetpwd' || to.path === '/changepwd' ) {
                return next( {
                  path: '/login',
                  query: { returnUrl: to.path }
                } );
              } else {
                return next();
              }
            } else {
              window.location = response.data.url;
            }
          }
        } else {
          return next( {
            path: '/404',
            query: { returnUrl: to.path }
          } );
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        if ( err.response && err.response.status == 406 ) {
          store.dispatch( 'setAuthFlashmeeting', { url: err.response.data.url, automatic: err.response.data.automatic } );
            if ( !err.response.data.url || err.response.data.url && err.response.data.automatic === 'false' ) {
              if ( to.path !== '/login' && to.path !== '/resetpwd' && to.path !== '/coderesetpwd' || to.path === '/changepwd' ) {
                return next( {
                  path: '/login',
                  query: { returnUrl: to.path }
                } );
              } else {
                return next();
              }
            } else {
              window.location = err.response.data.url;
            }
        } else {
          return next( {
            path: '/404',
            query: { returnUrl: to.path }
          } );
        }
      } );
    }
} );

export default router;
