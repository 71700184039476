<template>
  <div class="rounded-lg whitespace-pre break-words">
    <div class="border-2 border-dashed border-green-500 p-2 rounded-lg">
      <!-- Drag & drop zone -->
      <div v-bind="getRootProps()" class="text-center">
        <div class="flex flex-col justify-center items-center text-gray-400">
          <font-awesome-icon icon="fa-solid fa-upload" size="2xl" style="color: #12BC00;"/>
          <p v-if="maxNumberOfFiles !== -1">{{ '(' + t( 'Max number of files' ) + " : " + maxNumberOfFiles + ')' }}</p>
        </div>
        <input v-bind="getInputProps()" />
        <p v-html="dropZoneText"></p>
      </div>
      <div v-if="filesList.length > 0" class="pt-3 -mx-2">
        <div class="border-t-2 border-dashed border-green-500">
          <!-- Upload button -->
          <div class="flex flex-row grow justify-center pt-3">
            <div class="w-32">
              <LoadingButton ref="uploadBtn" type="submit" :label="uploadBtnLabel" @click="uploadFiles"/>
            </div>
          </div>
          <!-- List of files waiting for being uploaded -->
          <div v-for="( file, idx ) in filesList" :key="file" class="flex px-3 py-1.5">
            <FileItem :file="file" :isDeletable="true" :isEditable="isFileEditable" @delete="remove( idx )" @edit="editFile( file )"></FileItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useDropzone } from 'vue3-dropzone';
  import { useModal } from 'vue-final-modal';
  import FileEditionModal from '@/components/modals/FileEditionModal.vue';
  import FileItem from '@/components/file/FileItem.vue';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import settings from '@/features/settings';
  import utils from '@/features/utils.js';

  const props = defineProps( {
    maxNumberOfFiles: { Number, required: false, default: -1 },
    dropZoneText: { String, required: false, default: '' },
    isFileEditable: { Boolean, required: false, default: false },
    uploadBtnLabel: { String, required: true, default: '' }
  } );
  const emit = defineEmits( [ 'uploadFiles' ] );

  const { t } = useI18n();
  const { getRootProps, getInputProps } = useDropzone( { onDrop } );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const filesList = ref( [] );
  const editedFile = ref( null );
  const uploadBtn = ref ( null );
  const MAX_FILE_TITLE_SIZE = 28;
  const MAX_FILE_DESC_SIZE = 500;
  const MB_IN_B_SIZE = 1048576;
  const fileMaxSize = ref( -1 );

  settings.getOneSetting( 'max_file_size_mo' ).then( ( res ) => {
    fileMaxSize.value = res * MB_IN_B_SIZE;
  } );

  function onDrop( acceptFiles, rejectReasons ) {
    if ( rejectReasons.length == 0 ) {
      acceptFiles.every( ( element ) => {
        if ( props.maxNumberOfFiles !== -1 && filesList.value.length >= props.maxNumberOfFiles ) {
          utils.showWarning( t( 'Warning too much file selected' ) );
          return false;
        }
        if ( fileMaxSize.value !== -1 && element.size > fileMaxSize.value ) {
          utils.showWarning( t( 'Warning file to big', { fileName: element.name ?? '' } ) );
          return true;
        }
        else {
          filesList.value.push( {
            title: trimTitle( element.name ),
            description: trimDescription( element.name ),
            file: element,
            filename: element.name
          } );
        }
        return true;
      } );
    }
  }

  const trimTitle = ( title ) => {
    if ( title.length > MAX_FILE_TITLE_SIZE ) {
      return title.toString().substring( 0, MAX_FILE_TITLE_SIZE );
    }
    return title;
  };

  const trimDescription = ( description ) => {
    if ( description.length > MAX_FILE_DESC_SIZE ) {
      return description.toString().substring( 0, MAX_FILE_DESC_SIZE );
    }
    return description;
  };

  const remove = ( idx ) => {
    filesList.value.splice( idx, 1 );
  };

  const uploadFiles = () => {
    emit( 'uploadFiles', filesList.value );
  };

  const editFile = ( clickedFile ) => {
    fileEditModal.patchOptions( {
      attrs: {
        file: clickedFile,
      }
    } );
    editedFile.value = clickedFile;
    fileEditModal.open();
  };

  const fileEditModal = useModal( {
    component: FileEditionModal,
    attrs: {
      file: null,
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      onConfirm( fileChanged ) {
        editedFile.value.title = trimTitle( fileChanged.title );
        editedFile.value.description = trimDescription( fileChanged.description );
        fileEditModal.close();
      },
      onCancel() {
        fileEditModal.close();
      }
    }
  } );

  const stopLoading = () => {
    uploadBtn.value.stopLoading();
  };

  const clearFilesList = () => {
    filesList.value = [];
  };

  defineExpose( { stopLoading, clearFilesList } );
</script>