<template>
  <div @click="click"
  v-if="impact_study.answer && impact_study.answer != ''"
    class="flex items-center pl-2 space-x-6 py-1 hover:bg-fourth-hover duration-200 border border-$c-divider border-gray-300"
    :class="!readonly ? 'cursor-pointer' : ''">
    <input :id="impact_study.id" type="checkbox" :checked="impact_study.checked"
    class="w-6 h-6 accent-green-600/95 rounded" :class="!readonly ? 'cursor-pointer' : ''" :disabled="readonly">
    <div class="w-fit flex flex-col gap-1 pb-3 pt-2">
      <label class="text-md font-medium truncat">{{ impact_study.question ?? '' }}</label>
      <label class="text-sm truncat">{{ impact_study.answer ?? ''  }}</label>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps( {
    impact_study: { Object, require: true },
    readonly: { Boolean, required: false, default: true }
  } );

  const emit = defineEmits( [ 'click' ] );

  const click = () => {
    emit( 'click', props.impact_study );
  };
</script>