<template>
  <div class="text-secondary-text font-bold pb-1" v-html="title"></div>
  <div class="flex gap-4">
    <!-- Date & Time Picker -->
    <div class="w-[14.5rem]">
      <Datepicker v-model="fieldDateTime"
                  :input-class-name="css_class"
                  :locale="params.locale" :format="params.format" :is-24="params.is_24h.toString() == 'true' || params.is_24h == 1 ? true : false"
                  :key="componentKeyInit"
                  @update:modelValue="handle"
                  :readonly="readonly"
                  @cleared="cleared"
                  :min-date="minDate" :max-date="maxDate"
                  :clearable="clearable"
                  :placeholder="placeholder"
                  @internal-model-change="updateSelectState"
                  ref="datePicker">
        <template #input-icon>
          <font-awesome-icon class="ml-2" icon="fa-solid fa-calendar" />
        </template>
        <template #action-buttons>
          <div class="flex space-x-2 items-center">
            <button class="dp-cancel-btn"
              @click="closeMenu">{{ t('datetime-picker-cancel') }}
            </button>
            <button class="dp-select-btn"
              :disabled="isSelectButtonDisabled"
              :class="isSelectButtonDisabled ? 'cursor-not-allowed bg-blue-200 border-blue-200' : ''"
              @click="selectDate">{{ t( 'datetime-picker-select' ) }}
            </button>
          </div>
        </template>
        <template #am-pm-button="{ toggle, value }">
          <button class="dp-select-btn p-2"
            @click="toggle">{{ value }}
          </button>
        </template>
      </Datepicker>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import store from '@/store';
  import moment from 'moment';
  import settings from '@/features/settings';
  import Datepicker from '@vuepic/vue-datepicker';

  const props = defineProps( {
    title: { String, required: false, default: '' },
    field: { String, required: true },
    fkey: { String, required: false },
    readonly: { Boolean, required: false, default: false },
    minDate: { Date, required: false, default: null },
    maxDate: { Date, required: false, default: null },
    clearable: { Boolean, required: false, default: true },
    placeholder: { String, required: false, default: '' },
    css_class: { String, required: false, default: '' }
  } );
  const emit = defineEmits( [ 'change', 'clear' ] );

  const { t } = useI18n();
  const componentKeyInit = ref ( 0 );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const fieldDateTime = ref( props.field ? moment( props.field ) : null );
  const params = ref( {
    is_24h: true,
    locale: store.getters.getLocale,
    format: 'MM/dd/yyyy, HH:mm'
  } );
  const datePicker = ref( null );
  const isSelectButtonDisabled = ref( false );
  const forceInitRerender = () => {
    componentKeyInit.value += 1;
  };

  const init = async () => {
    moment.locale( store.getters.getLocale );

    params.value.format = moment.localeData().longDateFormat( 'L' ).replaceAll( 'D', 'd' ).replaceAll( 'Y', 'y' );
    params.value.is_24h = await settings.getOneSetting( 'time_24h' );
    params.value.is_24h === true || params.value.is_24h === 'true' ? params.value.format += ', HH:mm' : params.value.format += ', hh:mm aa';
    forceInitRerender();
  };

  const handle = ( dateTime ) => {
    if ( dateTime ) {
      let newDate = moment( dateTime ).toISOString();
      change( newDate );
    } else {
      fieldDateTime.value = moment( props.field );
    }
  };

  const change = ( datetime ) => {
    emit( 'change', props.fkey, datetime );
  };

  const selectDate = () => {
    datePicker.value.selectDate();
  };

  const updateSelectState = ( date ) => {
    if ( date != null && date instanceof ( Date )
      && props.minDate != null && props.minDate instanceof ( Date )
      && props.minDate.getTime() > date.getTime() ) {
      isSelectButtonDisabled.value = true;
    }
    else if ( date != null && date instanceof ( Date )
      && props.maxDate != null && props.maxDate instanceof ( Date )
      && props.maxDate.getTime() < date.getTime() ) {
      isSelectButtonDisabled.value = true;
    }
    else {
      isSelectButtonDisabled.value = false;
    }
  };

  const closeMenu = () => {
    datePicker.value.closeMenu();
  };

  const cleared = () => {
    let newDate = moment().toISOString();
    emit( 'clear', props.fkey, newDate );
  };
  init();
</script>