<template>
  <div class="text-sm bg-white shadow shadow-shadowColor rounded group mb-4 mb-10" :key="componentKey">
    <!-- Expander Header -->
    <div class="focus-visible:outline-none rounded">
      <div @click="toggleExpander" class="flex flex-row content-center items-center px-4 py-2 space-x-3 -mb-1 text-lg rounded hover:bg-tertiary-hover transition duration-200">
        <div class="-ml-1 -mr-0.5">
          <font-awesome-icon icon="fa-solid fa-gears" size="xl" />
        </div>
        <div class="font-bold">{{ t( 'Causes' ) }}</div>
        <button v-if="!readonly" @click.stop="addCause" :disabled="isBusy"
          class="flex flex-col has-tooltip  text-xxl rounded-full align-center justify-center"
          :class="!isBusy ? 'hover:bg-fourth-hover text-primary' : 'text-fourth-hover'">
          <font-awesome-icon icon="fa-solid fa-plus" size="xl" class="px-1 py-0"/>
          <span class='tooltip mb-8'>{{ t( 'Add a cause' ) }}</span>
        </button>
        <div class="has-tooltip flex">
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-gray-500 text-white" :key="componentKey">{{ getNumberOfCauses() }}</span>
          <span class='tooltip'>{{ t( 'Causes total' )}}</span>
        </div>
        <SortAndFilterOptions v-if="!ishikawaView"
          :sortOptions="sortOptions"
          :sortSelected="sortOptionSelected"
          :filterOptions="filterOptions"
          :filterSelected="filterOptionSelected"
          @changeSortOption="changeSortOption"
          @changeFilterOption="changeFilterOption"/>
        <div class="flex flex-row" :class="ishikawaView ? 'grow justify-end' : ''">
          <font-awesome-icon v-if="isToggled" icon="fa-solid fa-chevron-right" rotation="90" size="xl"/>
          <font-awesome-icon v-else icon="fa-solid fa-chevron-right" size="xl" />
        </div>
      </div>
    </div>
    <!-- Expander Body -->
    <Collapse :when="isToggled" class="collapse-class flex-col w-full h-full">
      <div class="flex w-full justify-even pt-1">
        <div class="flex items-center space-x-2 justify-start pl-5 w-full">
          <font-awesome-icon icon="fa-solid fa-grip-vertical" size="xl"/>
          <Toggle id="toggle" :value="ishikawaView" @click.prevent.stop @toggle="toggleIshikawa"/>
          <img src="@/../public/img/fish-bone.svg" class="w-[2.7rem] -my-3" :alt="t( 'Fishbone icon' )">
        </div>
        <div class="flex flex-row w-full justify-end gap-x-4 -mb-2 gap-y-1 px-4 w-fit">
          <button v-if="causes != null" @click="clickDownloadIshikawa"
            class="p-1 font-bold text-blue-500 hover:bg-tertiary-hover">
            {{ t('Download Ishikawa') }}
          </button>
          <button v-if="!readonly" @click="clickStickyNotes"
            class="p-1 font-bold text-blue-500 hover:bg-tertiary-hover">
            {{ t('StickyNotes') }}
          </button>
          <!-- 5 Why tool button -->
          <button @click="show5WhyModal = true" class="has-tooltip-4 float-right px-1.5 rounded font-bold text-blue-500 py-1 hover:bg-tertiary-hover">
            {{ t( 'Tool 5 why' ) }}
            <span class='tooltip'>{{ t( 'Tool 5 why tooltip' ) }}</span>
          </button>
        </div>
      </div>
      <!-- Ishikawa fishbone diagram SVG -->
      <RPIshikawaView v-if="ishikawaView" :id_rp="id_rp" :causes="utils.sortCauses( SORT.REGISTER_DATE_DESC, causes )" @changeItem="changeItem" @clickItem="clickCause" class="flex w-full" :key="componentKey2"/>
      <div v-else class="flex flex-wrap w-full p-4 gap-4" :key="componentKey">
        <RPCauseItem v-for="cause in utils.sortCauses( sortOptionSelected, filterCauses ( causes ) )" :key="cause"
          class="flex-auto"
          :item="cause" :categories="categories" :readonly="readonly"
          @changeItem="changeItem" @removeItem="removeItem"/>
      </div>
    </Collapse>
  </div>
  <VueFinalModal v-model="showIshikawaModal" class="flex justify-center items-center" :click-to-close="false" :esc-to-close="true">
    <ToolStickyNotes :id_rp="id_rp" @clickClose="closeStickyNotes" @changeCauseList="changeList"/>
  </VueFinalModal>
  <VueFinalModal v-model="show5WhyModal" class="flex justify-center items-center"
    :click-to-close="true"
    :esc-to-close="true"
    :overlay-transition="'vfm-fade'"
    :content-transition="'vfm-fade'"
    :lock-scroll="true">
    <Tool5Why :id_rp="id_rp" :tool5WhyData="tool5Why" :readonly="readonly"
    @clickClose="close5Why" @change5Why="update5Why" @changeCauseList="changeList"/>
  </VueFinalModal>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import download from 'downloadjs';
  import RPCauseItem from '@/components/rp/causes/RPCauseItem.vue';
  import RPIshikawaView from '@/components/rp/causes/RPIshikawaView.vue';
  import { VueFinalModal, useModal } from 'vue-final-modal';
  import ToolStickyNotes from '@/components/modals/tools_modals/StickyNotes.vue';
  import Tool5Why from '@/components/modals/tools_modals/_5WhyToolModal.vue';
  import CauseDetailsModal from '@/components/modals/rp_modals/CauseDetailsModal.vue';
  import API from '@/constants/api.constants';
  import settings from '@/features/settings';
  import SORT from '@/constants/sort.constants';
  import FILTER from '@/constants/filter.constants';
  import SortAndFilterOptions from '@/components/object/SortAndFilterOptions.vue';
  import Toggle from '@/components/object/ToggleBasic.vue';
  import router from '@/router';

  const props = defineProps( {
    id_rp: { String, required: true },
    rp: { Object, required: true },
    causes: { Array, required: true },
    readonly: { Boolean, required: false, default: false },
    fileName: { String, required: true }
  } );
  const emit = defineEmits( [ 'changeList', 'changeItem', 'change5Why', 'removeItem' ] );

  const { t } = useI18n();
  const isToggled = ref( store.getters.getExpanders.causes ?? true );
  const ishikawaView = ref( store.getters.getCausesOptions.ishikawaView ?? false );
  const categories = ref( [] );
  const show5WhyModal = ref( false );
  const showIshikawaModal = ref( false );

  const componentKey = ref ( 0 );
  const componentKey2 = ref ( 0 );

  const forceRerender = () => {
    componentKey.value += 1;
    componentKey2.value += 1;
  };
  const isBusy = ref( false );

  const tool5Why = ref( null );
  watchEffect( () => ( tool5Why.value = props.rp.tool_5why ) );

  const sortOptionSelected = ref( store.getters.getCausesOptions.sort ?? SORT.REGISTER_DATE_DESC );
  const sortOptions = ref( [
    SORT.REGISTER_DATE_ASC,
    SORT.REGISTER_DATE_DESC,
    SORT.LAST_ACTIVITY_ASC,
    SORT.LAST_ACTIVITY_DESC,
    SORT.RANKING_ASC,
    SORT.RANKING_DESC
  ].map( ( item ) => {
    return { val: item, text: t( item ) };
  } ) );
  const filterOptionSelected = ref( store.getters.getCausesOptions.filter ?? FILTER.ALL );
  const filterOptions = ref( [
    FILTER.ALL,
  ].map( ( item ) => {
    return { val: item, text: t( item ) };
  } ) );

  const changeSortOption = ( newOption ) => {
    sortOptionSelected.value = newOption;
    store.dispatch( 'setCauses', { sort: newOption } );
  };
  const changeFilterOption = ( newOption ) => {
    filterOptionSelected.value = newOption;
    store.dispatch( 'setCauses', { filter: newOption } );
  };

  const clickCause = ( id ) => {
    if ( id != null && props.causes != null && Array.isArray( props.causes ) ) {
      let causeToDisplay = props.causes.find( ( cause ) => ( cause.id != null && cause.id === id ) );
      if ( causeToDisplay != null ) {
        causeDetailsModal.patchOptions( {
          attrs: {
            id_rp: props.id_rp,
            cause: causeToDisplay,
            categories: categories
          }
        } );
        causeDetailsModal.open();
      }
    }
  };

  const causeDetailsModal = useModal( {
    component: CauseDetailsModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      onClose() {
        causeDetailsModal.close();
      },
      onChangeItem( id ) {
        if ( id != null ) {
          changeItem( id );
        }
      },
      onRemoveItem( id ) {
        if ( id != null ) {
          removeItem( id );
        }
        causeDetailsModal.close();
      }
    }
  } );

  const changeList = () => {
    emit( 'changeList' );
  };

  const changeItem = ( id ) => {
    emit( 'changeItem', id );
  };

  const removeItem = ( id ) => {
    emit( 'removeItem', id );
  };

  const update5Why = () => {
    emit( 'change5Why' );
  };

  const close5Why = () => {
    show5WhyModal.value = false;
  };

  const closeStickyNotes = () => {
    showIshikawaModal.value = false;
    forceRerender();
  };

  const clickStickyNotes = () => {
    router.push( '/sticky_notes/' + props.id_rp + '/ishikawa7M' );
    // TODO : Bug with Ishikawa modal files dropzone
    // showIshikawaModal.value = true;
  };

  const init = async () => {
    let ishikawa_7m = await settings.getOneSetting( 'ishikawa_7m' );
    for ( let i = 0; i < ishikawa_7m.length; i++ ) {
      categories.value.push( { val: ( i + 1 ), text: ishikawa_7m[ i ] } ) ;
    }
    filterOptions.value = [ { val: FILTER.ALL, text: t( FILTER.ALL ) } ].concat( categories.value );
  };

  const getNumberOfCauses = () => {
    let total = 0;
    if ( props.causes != null && Array.isArray( props.causes ) ) {
      total = props.causes.filter( ( item ) => !item.disable ).length;
    }
    return total;
  };

  const addCause = () => {
    let url = API.get_api( API.API_NAMES.RP_CAUSES, props.id_rp );
    isBusy.value = true;

    AXIOS.post( url, { category: typeof ( filterOptionSelected.value ) == 'number' ? filterOptionSelected.value : 1, position: 1, description: t( 'Description PLACEHOLDER' ) }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '200' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeList();
        } else {
          forceRerender();
        }
        isBusy.value = false;
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        isBusy.value = false;
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const clickDownloadIshikawa = () => {
    let url = API.get_api( API.API_NAMES.RP_DOWNLOAD_ISHIKAWA );
    AXIOS.post( url, { id_rp: props.id_rp, locale: store.getters.getLocale }, { headers: { 'auth-token': store.getters.getToken }, responseType: 'blob' } )
      .then( ( response ) => {
        if ( response.status == '200' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          let content = response.headers[ 'content-type' ];
          download( response.data, props.fileName, content );
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const filterCauses = ( causesList ) => {
    if ( causesList != null && Array.isArray( causesList ) ) {
      switch ( typeof ( filterOptionSelected.value ) ) {
      case 'number':
        return causesList.filter( ( cause ) => {
          return ( cause.category != null && cause.category == filterOptionSelected.value );
        } );
      default :
        return causesList;
      }
    }
    return [];
  };

  const toggleExpander = () => {
    store.dispatch( 'setExpanders', { causes: !isToggled.value } );
    isToggled.value = store.getters.getExpanders.causes;
  };
  defineExpose( { toggleExpander, isToggled } );

  const toggleIshikawa = () => {
    store.dispatch( 'setCauses', { ishikawaView: !ishikawaView.value } );
    ishikawaView.value = store.getters.getCausesOptions.ishikawaView;
  };

  init();
</script>
