<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-lg"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="space-y-4">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center"> {{ t( 'Link action to probable causes') }} </h1>

      <!-- Modal Body -->
      <h1 class="text-xl font-bold"> {{ t( 'Selected action') }} </h1>
      <div class="flex flex-col border border-2 border-gray-400 rounded gap-2 p-2">
        <div class="font-bold text-xl w-full max-w-[37rem]">
          <Field :field="action.title" fkey="title" :placeholder="t( 'Title PLACEHOLDER' )" :readonly="true"></Field>
        </div>
        <FieldArea :placeholder="t( 'Description PLACEHOLDER' )" :field="action.description" fkey="description" :readonly="true"></FieldArea>
      </div>
      <div class="flex flex-col items-start justify-center space-y-4">
        <h1 class="text-xl font-bold"> {{ t( 'Probable causes list' ) }} </h1>
        <div v-if="causesCheckList.length > 0" class="flex space-x-3">
          <div>
            <!-- Field searched selector -->
            <select @change="selectChanged($event)"
                    class="text-gray-800 text-sm rounded-l-lg p-1 bg-gray-200 cursor-pointer">
              <option value="" class="bg-gray-200">{{ t("All fields") }}</option>
              <option v-for="item in headers" :key="item.value" :value="item.value" class="bg-gray-200">
                  {{ item.text }}
              </option>
            </select>
            <!-- Search field -->
            <input type="search" :placeholder="t( 'Search bar' ) + '...'" v-model="searchedValue"
            class="text-sm w-96 rounded-r-lg p-0.5 border-2 border-gray-200"/>
          </div>
          <!-- Radio buttons -->
          <div class="flex space-x-4 items-center accent-green-600/95">
            <div class="flex items-center space-x-1">
              <input type="radio" id="allCauses" name="causeFilter" @change="causesFilter = 'allCauses'"
              class="w-5 h-5 cursor-pointer" checked/>
              <label for="allCauses" class="cursor-pointer">{{ t( 'All causes' ) }}</label>
            </div>
            <div class="flex items-center space-x-1">
              <input type="radio" id="linkedCauses" name="causeFilter" @change="causesFilter = 'linkedCauses'"
              class="w-5 h-5 cursor-pointer"/>
              <label for="linkedCauses" class="cursor-pointer">{{ t( 'Linked causes' ) }}</label>
            </div>
            <div class="flex items-center space-x-1">
              <input type="radio" id="unLinkedCauses" name="causeFilter" @change="causesFilter = 'unLinkedCauses'"
              class="w-5 h-5 cursor-pointer"/>
              <label for="unLinkedCauses" class="cursor-pointer">{{ t( 'Unlinked causes' ) }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Causes scroll list -->
      <div v-if="causesCheckList.length > 0" class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
        <RPCauseListItem v-for="cause in searchFilterCauses()" :key="cause" :cause="cause" :readonly="readonly" @click="check"></RPCauseListItem>
      </div>

      <!-- Modal footer-->
      <div v-if="!readonly" class="flex space-x-4 justify-end">
        <div>
          <LoadingButton ref="saveBtn" type="submit" :label="t( 'Save' )" @click="save"/>
        </div>
        <div>
          <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import FieldArea from '@/components/object/FieldArea.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import API from '@/constants/api.constants';
  import RPCauseListItem from '@/components/rp/causes/RPCauseListItem.vue';

  const props = defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    readonly: { Boolean, required: false, default: true },
    causes: { Array, required: true, default: [] },
    action: { Object, required: true, default: {} }
  } );
  const emit = defineEmits( [ 'save', 'cancel' ] );

  const { t } = useI18n();
  const saveBtn = ref( null );
  const headers = [
    { text: t( 'Ranking' ), value: 'Ranking', sortable: true },
    { text: t( 'Description' ), value: 'Description', sortable: true },
  ];
  const searchedValue = ref( '' );
  const searchedField = ref( '' );
  const causesFilter = ref( '' );

  const selectChanged = ( event ) => {
    searchedField.value = event.target.value;
  };

  const searchFilterCauses = () => {
    let result = causesCheckList.value;
    if ( searchedValue.value != '' ) {
      let search = searchedValue.value.toLowerCase();
      switch ( searchedField.value ) {
      case 'Ranking':
        result = causesCheckList.value.filter( ( cause ) =>
          ( cause.position ? String( cause.position ).toLowerCase().includes( search ) : false )
        );
        break;
      case 'Description':
        result = causesCheckList.value.filter( ( cause ) =>
          ( cause.description ? String( cause.description ).toLowerCase().includes( search ) : false )
        );
        break;
      default:
        result = causesCheckList.value.filter( ( cause ) =>
          ( cause.description ? String( cause.description ).toLowerCase().includes( search ) : false )
          || ( cause.position ? String( cause.position ).toLowerCase().includes( search ) : false )
        );
        break;
      }
    }
    switch ( causesFilter.value ) {
    case 'linkedCauses':
      result = result.filter( ( cause ) => ( cause ? cause.checked : false ) );
      break;
    case 'unLinkedCauses':
      result = result.filter( ( cause ) => ( cause ? !cause.checked : false ) );
      break;
    default:
      break;
    }
    return result;
  };

  const causesCheckList = ref(
    utils.sortListByPositionAscending(
      props.causes.map( ( cause ) => {
        if ( props.action.action_causes && props.action.action_causes.find( ( item ) => item.cause.id == cause.id ) != undefined ) {
          cause.checked = true;
        } else {
          cause.checked = false;
        }
        return cause;
      } ) ) );

  const check = ( cause ) => {
    if ( !props.readonly && cause != undefined && cause != null
      && cause.checked != undefined && cause.checked != null ) {
      cause.checked = !cause.checked;
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };

  const save = () => {
    let promises = [];

    if ( props.action.id != undefined && props.action.id != null ) {
      let causesToUnlink = causesCheckList.value.filter( ( cause ) => {
        if ( !cause.checked && props.action.action_causes
          && props.action.action_causes.find( ( item ) => item.cause.id == cause.id ) != undefined ) {
          return cause.id;
        }
      } ).map( ( cause ) => cause.id );

      let causesToLink =  causesCheckList.value.filter( ( cause ) => {
        if ( cause.checked && ( ( props.action.action_causes
          && props.action.action_causes.find( ( item ) => item.cause.id == cause.id ) == undefined )
          || ( props.action.action_causes == undefined || props.action.action_causes == null ) ) ) {
          return cause.id;
        }
      } ).map( ( cause ) => cause.id );

      let url = API.get_api( API.API_NAMES.ACTIONS, props.action.id ) + 'causes';

      if ( Array.isArray( causesToUnlink ) && causesToUnlink.length > 0 ) {
        promises.push( new Promise ( ( resolve ) => AXIOS.delete( url, { data: { id_causes: causesToUnlink }, headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '201' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            }
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            resolve();
          } ) ) );
      }

      if ( Array.isArray( causesToLink ) && causesToLink.length > 0 ) {
        promises.push( new Promise ( ( resolve ) => AXIOS.post( url, { id_causes: causesToLink }, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '201' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            }
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            resolve();
          } ) ) );
      }
    }

    Promise.all( promises )
      .then( () => {
        promises.length == 0 ? emit( 'save', false ) : emit( 'save', true );
        saveBtn.value.stopLoading();
      } );
  };
</script>