<template>
  <div class="bg-white shadow shadow-shadowColor rounded-xl p-4">
    <div class="flex w-fit h-fit justify-center">
      <VChart class="chart" auto-resize :option="option" :autoresize="true"/>
    </div>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';

  // Apache E-Charts mandatory modules
  import { use } from 'echarts/core';

  // Apache E-Charts manually imported modules
  import
  {
    DatasetComponent,
    TitleComponent,
    LegendComponent,
    TooltipComponent
  } from 'echarts/components';
  import { PieChart } from 'echarts/charts';
  import { LabelLayout } from 'echarts/features';
  import { CanvasRenderer } from 'echarts/renderers';

  const props = defineProps( {
    data: { Array, required: true }
  } );
  const { t } = useI18n();
  // eslint-disable-next-line vue/no-setup-props-destructure
  const curChartData = Object.assign( [], props.data.chartData );
  curChartData.forEach( ( e ) => {
    switch ( e.name ) {
    case 'Status1': e.name = t( 'Identified' ); break;
    case 'Status2': e.name = t( 'Planned' ); break;
    case 'Status3': e.name = t( 'Delayed' ); break;
    case 'Status4': e.name = t( 'Completes' ); break;
    }
  } );

  // E-Chart configuration
  use( [
    DatasetComponent,
    TitleComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
    TooltipComponent
  ] );
  // eslint-disable-next-line vue/no-setup-props-destructure
  var option = {
    title: {
      text: t( 'Total number of actions' ) + ' : ' + getTotal(),
      subtext: t( 'Actions distribution' ),
      left: 'center',
      bottom: '0%',
      textStyle: {
        fontSize: 15
      },
      subtextStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'fff'
      }
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '10%',
      left: 'center',
      selectedMode: false,
      itemWidth: 28,
      itemHeight: 17,
      textStyle: {
        fontSize: 16
      }
    },
    series: {
      type: 'pie',
      id: 'ActionsPieChart',
      data: curChartData,
      encode: {
        value: 0,
        itemName: 1
      },
      legendHoverLink: true,
      stillShowZeroSum: false,
      radius: [ '55%', '80%' ],
      center: [ '50%', '60%' ],
      startAngle: 180,
      label: {
        show: haveDelay(),
        position: 'center',
        // eslint-disable-next-line vue/no-setup-props-destructure
        formatter: t( 'Median delay' ) + '\n' + medianDelay( props.data.median_delay_minute ) + ' ' + t( 'days' ),
        fontSize: 18,
        fontWeight: 'bold'
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 20,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      color: [ '#a6a6a6', '#9333ea', '#b32424', '#37A2DA' ]
    }
  };

  function getTotal() {
    let sum = 0;
    if ( props.data.chartData != null && props.data.chartData.length > 0 ) {
      for ( var i = 0 ; i < props.data.chartData.length ; i++ ) {
        sum += props.data.chartData[ i ].value;
      }
    }
    return sum / 2;
  }

  function haveDelay() {
    if ( props.data.median_delay_minute
      && props.data.median_delay_minute != null
      && props.data.median_delay_minute > 0 ) {
      return true;
    }

    return false;
  }

  function medianDelay( minutes ) {
    return Math.trunc( minutes ? ( minutes / 60 / 24 ) : 0 );
  }
</script>

<style scoped>
  .chart {
    height: 500px;
    width: 320px;
  }
</style>