<template>
  <div class="bg-white flex flex-row p-4">
    <div class="text-3xl font-bold">
      <font-awesome-icon icon="fa-solid fa-angles-left" @click="prevRange"/>
    </div>
    <div class="text-center text-3xl font-bold flex-1">
      <div v-if="sites[0]">{{ sites[ 0 ].name }}</div>
    </div>
    <div class="flex flex-row justify-end gap-x-4 items-center">
      <div class="border rounded-md p-1">
        <FieldDate v-if="displaySettings.start_date"
                     :field="displaySettings.start_date"
                     @change="handleDate"
                     @clear-date="clearedDate"
                     :minDate="new Date()"
                     :key="componentKeyInit"></FieldDate>
      </div>
      <div>
        <font-awesome-icon icon="fa-solid fa-magnifying-glass-plus" @click="zoomIn"/>
      </div>
      <div>
        <font-awesome-icon icon="fa-solid fa-magnifying-glass-minus" @click="zoomOut"/>
      </div>
      <div class="text-3xl font-bold">
        <font-awesome-icon icon="fa-solid fa-angles-right" @click="nextRange"/>
      </div>
    </div>
  </div>
</template>


<script setup>
  import { ref } from 'vue';
  import moment from 'moment';
  import store from '@/store';
  import userSettings from '@/features/settings';
  import FieldDate from '@/components/object/FieldDate.vue';

  const props = defineProps( {
    sites: { Object, required: true },
    settings: { Object, required: true }
  } );
  const componentKeyInit = ref ( 0 );
  const params = ref( {
    is_24h: false,
    locale: store.getters.getLocale,
    format: 'dd/mm/yyyy'
  } );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const displaySettings = ref( props.settings );

  const forceInitRerender = () => {
    componentKeyInit.value += 1;
  };

  const init = async () => {
    moment.locale( store.getters.getLocale );

    params.value.format = moment.localeData().longDateFormat( 'L' ).replaceAll( 'D', 'd' ).replaceAll( 'Y', 'y' );
    params.value.is_24h = await userSettings.getOneSetting( 'time_24h' );
    forceInitRerender();
  };

  const handleDate = ( date ) => {
    displaySettings.value.start_date = moment( date ).format( 'YYYY-MM-DD' );
    saveStore();
  };

  const clearedDate = () => {
    displaySettings.value.start_date = moment().format( 'YYYY-MM-DD' );
    forceInitRerender();
    saveStore();
  };

  const prevRange = () => {
    displaySettings.value.start_date = moment( displaySettings.value.start_date ).add( -displaySettings.value.n_days, 'day' );
    forceInitRerender();
    saveStore();
  };

  const nextRange = () => {
    displaySettings.value.start_date = moment( displaySettings.value.start_date ).add( displaySettings.value.n_days, 'day' );
    forceInitRerender();
    saveStore();
  };

  const zoomIn = () => {
    if ( displaySettings.value.n_days <= 3 ) displaySettings.value.n_days = 1;
    else if ( displaySettings.value.n_days <= 5 ) displaySettings.value.n_days = 3;
    else displaySettings.value.n_days = 5;

    saveStore();
  };

  const zoomOut = () => {
    if ( displaySettings.value.n_days < 3 ) displaySettings.value.n_days = 3;
    else if ( displaySettings.value.n_days < 5 ) displaySettings.value.n_days = 5;
    else displaySettings.value.n_days = 10;

    saveStore();
  };

  const saveStore = () => {
    let boardDisplay = store.getters.getDisplay.board;
    boardDisplay = displaySettings.value;
    store.dispatch( 'setDisplayBoard', boardDisplay );
  };

  init();
</script>

<style scoped lang="scss">
</style>