import store from '@/store';
import RIGHTS from '@/constants/rights.constants';

/* ===== Permet de savoir si l'utilisateur à les droits de modifier les paramètres =====*/
const isUpdateSettings = async () => {
  return IsRight( RIGHTS.UPDATE_SETTINGS );
};

/* ===== Permet de savoir si l'utilisateur à les droits =====*/
const IsRight = async ( right_key ) => {
  let bRet = false;
  let user = store.getters.get_connected_user;
  if ( user && user.groups && user.groups.length > 0 ) {
    user.groups.forEach( ( group ) => {
      if ( group.rights && group.rights.legnth > 0 ) {
        if ( group.rights.includes( right_key ) ) {
          bRet = true;
        }
      }
    } );
  }

  return bRet;
};

export default {
  isUpdateSettings
};