<template>
  <div class="rp-item-card" :key="componentKey">
    <div class="flex space-x-3 self-end -mb-8 -mt-2 -mr-2">
      <div class="has-tooltip flex">
        <span class='tooltip'>{{ t( 'Link secruring action to impact studies' )}}</span>
        <button class="hover:bg-tertiary-hover rounded-full" @click="clickLinkImpactStudy">
          <font-awesome-icon icon="fa-solid fa-triangle-exclamation" size="xl" class="px-2 py-1"/>
        </button>
      </div>
      <button v-if="!readonly" class="hover:bg-tertiary-hover rounded-full" @click="remove">
        <font-awesome-icon icon="fa-solid fa-xmark" size="xl" class="px-2 py-1"/>
      </button>
    </div>
    <div class="font-bold text-xl w-full max-w-[37rem] pt-2">
      <Field :field="action.title" fkey="title"
        :placeholder="t( 'Title PLACEHOLDER' )"
        :readonly="readonly" @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.ACTION_TITLE"/>
    </div>
    <FieldArea class="flex-auto" :field="action.description"
      :placeholder="t( 'Description PLACEHOLDER' )" fkey="description"
      :readonly="readonly" @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.ACTION_DESCRIPTION"/>
    <div class="max-w-[42rem] w-fit flex space-x-7">
      <div class="font-bold">{{ t( 'Manager' ) }}</div>
      <div class="min-w-[10rem]">
        <InputSelect :items="users" :selected="(action.action_users && action.action_users[0].user)? action.action_users[0].user.id : null" :readonly="readonly" @change-value="changePilote"/>
      </div>
    </div>
    <div class="flex items-center gap-3 items-center mt-3 -mb-5">
      <div class="has-tooltip flex space-x-1 w-fit">
        <div class="font-bold text-sm py-2.5" v-html="t( 'Securing date' )"></div>
        <font-awesome-icon icon="fa-solid fa-circle-info" style="color: #2563eb;"/>
        <span class='tooltip'>{{ t( 'Securing date tooltip' )}}</span>
      </div>
      <FieldDate v-if="action.action_users" :title="''"
        :field="action.action_users[0].delivery_date" rkey=""
        @change="changeDeliveryDate"
        @clear-date="clearDate"
        :readonly="readonly"
        :minDate="moment(detectedDate).toDate()"
        :maxDate="moment().toDate()"/>
        <div v-if="isSecurizedAction()"
          class="flex flex-row space-x-4 justify-start items-center">
          <div class="text-secondary-text font-bold">{{ t( 'Action efficient' ) }}</div>
          <input type="checkbox" @click="changeEfficiency( !action.action_users[ 0 ].is_efficient )" :checked="action.action_users[ 0 ].is_efficient"
            class="w-6 h-6 accent-green-600/95 rounded" :class="!(props.readonly || isBusy) ? 'cursor-pointer' : ''" :disabled="readonly || isBusy">
        </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useModal } from 'vue-final-modal';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldDate from '@/components/object/FieldDate.vue';
  import InputSelect from '@/components/object/InputSelect.vue';
  import API from '@/constants/api.constants';
  import FieldArea from '@/components/object/FieldArea.vue';
  import moment from 'moment-timezone';
  import LinkActionToImpactStudiesModal from '@/components/modals/rp_modals/LinkActionToImpactStudiesModal.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    users: { Array, required: true },
    action: { Object, required: true },
    readonly: { Boolean, required: false, default: false },
    detectedDate: { String, required: false, default: null },
    impact_studies: { Array, required: true }
  } );
  const emit = defineEmits( [ 'changeItem', 'removeItem' ] );
  const { t } = useI18n();

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  const isBusy = ref( false );
  const busy = () => {
    isBusy.value = !isBusy.value;
  };

  const isSecurizedAction = () => {
    if ( props.action != null
      && props.action.action_users != null
      && Array.isArray( props.action.action_users )
      && props.action.action_users.length > 0
      && props.action.action_users[ 0 ] != null ) {
      let user = props.action.action_users[ 0 ];
      return user.delivery_date != null
        && user.delivery_date !== '';
    } else {
      return false;
    }
  };

  const changeEfficiency = async ( val ) => {
    if ( isSecurizedAction() ) {
      busy();
      let url = API.get_api( API.API_NAMES.ACTIONS_USERS, props.action.action_users[ 0 ].id );
      await AXIOS.put( url, { is_efficient: val }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          if ( response.status == '201' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            changeItem();
          } else {
            forceRerender();
          }
          busy();
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          forceRerender();
          utils.showCatch( err );
          busy();
        } );
    }
  };

  const changeField = ( fkey, val ) => {
    let url = API.get_api( API.API_NAMES.ACTIONS, props.action.id );
    AXIOS.put( url, { [ fkey ]: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeItem();
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const changePilote = ( val ) => {
    if ( !props.action.action_users || props.action.action_users.length == 0 ) {
      let url = API.get_api( API.API_NAMES.ACTIONS, props.action.id, 'users' );
      AXIOS.post( url, { id_users: val }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.analyse_axios_request( response );
          if ( response.status == '201' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            changeItem();
          } else {
            forceRerender();
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          forceRerender();
          utils.showCatch( err );
        } );
    } else {
      let url = API.get_api( API.API_NAMES.ACTIONS_USERS, props.action.action_users[ 0 ].id );
      AXIOS.put( url, { id_users: val }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.analyse_axios_request( response );
          if ( response.status == '201' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            changeItem();
          } else {
            forceRerender();
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          forceRerender();
          utils.showCatch( err );
        } );
    }
  };

  const changeDeliveryDate = async ( val ) => {
    let url = API.get_api( API.API_NAMES.ACTIONS_USERS, props.action.action_users[ 0 ].id );

    if ( val === '' && isSecurizedAction() && props.action.action_users[ 0 ].is_efficient == true ) {
      await AXIOS.put( url, { is_efficient: false }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.analyse_axios_request( response );
          if ( response.status == '201' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          } else {
            forceRerender();
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          forceRerender();
          utils.showCatch( err );
        } );
    }
    AXIOS.put( url, { delivery_date: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        utils.analyse_axios_request( response );
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeItem();
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const remove = () => {
    let url = API.get_api( API.API_NAMES.RP_SECURING_ACTION, props.action.id );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        utils.analyse_axios_request( response );
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          removeItem( props.action.id );
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const clickLinkImpactStudy = () => {
    linkImpactStudy.open();
  };

  const linkImpactStudy = useModal( {
    component: LinkActionToImpactStudiesModal,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: false,
      readonly: props.readonly,
      impact_studies: props.impact_studies,
      action: props.action,

      onCancel() {
        linkImpactStudy.close();
      },
      onSave( changes ) {
        if ( changes != undefined && changes != null && changes ) {
          changeItem();
        }
        linkImpactStudy.close();
      }
    }
  } );


  const clearDate = () => {
    changeDeliveryDate( '', null );
  };

  const changeItem = () => {
    emit( 'changeItem', props.action.id );
  };

  const removeItem = ( id ) => {
    emit( 'removeItem', id );
  };
</script>