<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-xl"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex-col space-y-5">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center"> {{ props.title }} </h1>

      <!-- Modal Body for Action -->
      <DisplayListRP :array="props.array" :event="eventClick" v-if="props.type === 'rp'"/>
      <DisplayListAction :array="props.array" :event="eventClick" v-if="props.type === 'action'"/>
    </div>

  </VueFinalModal>
</template>

<script setup>
  import DisplayListRP from '@/components/object/DisplayListRP.vue';
  import DisplayListAction from '@/components/object/DisplayListAction.vue';
  import { VueFinalModal } from 'vue-final-modal';
  import router from '@/router';

  const props = defineProps( {
    escToClose: { Boolean, required: false, default: true },
    clickToClose: { Boolean, required: false, default: true },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    title: { String, required: true, default: 'Title...' },
    type: { String, require: true, validator( value ) { return [ 'rp', 'action' ].includes( value ); } },
    array: { Array, required: true, default: [] }
  } );

  const emit = defineEmits( [ 'cancel' ] );
  const clickClose = () => {
    emit( 'cancel' );
  };

  const eventClick = function eventClick( params ) {
    if ( params ) {
      emit( 'cancel' );
      router.push( '/rp/' + params.id_rp_redirection );
    }
  };
</script>