<template>
  <div>
    <div>
      <label class="block mb-2 text-blue-500" for="email">{{ t('Please enter your email address') }}</label>
      <input class="w-full p-2 mb-9 text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100" type="text" name="email" v-model="input.email">
    </div>
    <div>
      <button class="w-full bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" type="button" v-on:click="reset()">{{ t('Send code') }}</button>
    </div>
    <div class="mt-4 text-white grid grid-cols-2 justify-items-stretch">
      <div class="justify-self-start">
        <SelectLanguage />
      </div>
      <div class="justify-self-end">
        <a class="text-blue-400 hover:text-blue-700 text-sm" href="#" v-on:click="clickBackLink()">{{ t('Back') }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import enumDisplayComponent from '@/enum/enumDisplayComponent';
  import AXIOS from '@/features/axios.js';
  import * as EmailValidator from 'email-validator';
  import API from '@/constants/api.constants';

  const emit = defineEmits( [ 'custom-event-name' ] );

  const { t } = useI18n();
  const input = ref( { email: '' } );

  const clickBackLink = () => {
    emit( 'custom-event-name', { display: enumDisplayComponent.SIGNIN } );
  };

  const reset = () => {
    if ( EmailValidator.validate( input.value.email ) ) {
      AXIOS.get( API.get_api( API.API_NAMES.AUTH_PASSWORD_CODE ), { params: { email: input.value.email } } )
        .then( ( response ) => {
          if ( response.status == '200' ) {
            emit( 'custom-event-name', { id: response.data.id,  display: enumDisplayComponent.VERIFY_CODE } );
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    } else {
      utils.showWarning( t( 'Please enter a valid email address' ) );
    }
  };
</script>