<template>
  <fieldset class="min-w-[350px] h-fit border shadow-lg opacity-95 rounded-xl space-y-4 rounded p-4 -ml-[1px]">
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="flex h-full w-full sm:col-span-6 justify-between">
        <span class="font-bold"> {{ t( 'Add' ) }} </span>
        <font-awesome-icon icon="fa-solid fa-xmark" class="fa-xl cursor-pointer" @click="close()" />
      </div>
      <div class="sm:col-span-6">
        <label for="first-name" class="block text-lg font-medium leading-6 text-gray-900">{{ t( 'Service name' ) }}</label>
        <div class="mt-2">
          <Field :fkey="newServiceName" :field="newServiceName"
            @change="(key, text) => newServiceName = text" :readonly="false"
            class="border rounded-lg p-1"
            :maxlength="MAX_INPUT_LENGTH.SERVICE_NAME"/>
        </div>
      </div>

      <button type="button" class="sm:col-span-6 w-fit py-1 px-2 bg-green-600 hover:bg-green-700 text-white text-sm font-semibold rounded-md shadow justify-self-end	" @click="addService()"> <font-awesome-icon icon="fa-solid fa-add" /> {{ t( 'Add' ) }} </button>
    </div>
  </fieldset>
</template>

<script setup>
  import Field from '@/components/object/FieldBasic.vue';
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const { t } = useI18n();
  const emit = defineEmits( [ 'add', 'close' ] );
  var newServiceName = ref( '' );

  function addService() {
    emit( 'add', newServiceName.value );
    emit( 'close' );
  }

  function close() {
    emit( 'close' );
  }

</script>