<template>
  <IconLoadingButton ref="button" :icon="icon" @click="redirectToChartsPage"></IconLoadingButton>
</template>

<script setup>
  import { ref } from 'vue';
  import IconLoadingButton from '@/components/buttons/IconLoadingButton.vue';
  import router from '@/router';

  const props = defineProps( {
    rp_id: { String, required: true }
  } );

  const button = ref( undefined );

  const icon = 'fa-chart-line';

  const redirectToChartsPage = () => {
    router.push( '/charts/' + props.rp_id );
    button.value.stopLoading;
  };
</script>