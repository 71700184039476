<template>
  <div v-if="!editable" :class="[readonly ? '' : 'hover:bg-tertiary-hover cursor-pointer', 'w-full']" @click="setEditable" >
    <div v-if="users_list.length != 0">
      <div v-for="user in users_list" :key="user">
        <div class="flex">
          <span class="grow">{{ user.text }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="italic text-blue-500">{{ t( 'Add user in RP' ) }}</p>
    </div>
  </div>
  <div v-if="editable && !readonly">
    <div v-for="user in users_list" :key="user">
      <div class="flex items-center" tabindex="0" @blur="(event) => { blur(event); }">
        <span class="grow">{{ user.text }}</span>
        <button class="noblur px-1.5 hover:bg-fourth-hover cursor-pointer rounded-xl" @click="removeUser(user.val)"><font-awesome-icon icon="fa-solid fa-xmark"/></button>
      </div>
    </div>
    <InputSelect ref="input_select_editable" :items="users_select" :selected="[]" @change-value="addUser" @blur="blur" :key="componentKey"></InputSelect>
  </div>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import InputSelect from '@/components/object/InputSelect.vue';
  import API from '@/constants/api.constants';

  const props = defineProps( {
    id: { String, required: true },
    users: { Array, required: true, default: [] },
    all_users: { Array, required: true, default: [] },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits( [ 'changeUsers' ] );

  const { t } = useI18n();
  const editable = ref( false );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const users_list = ref( props.users || [] );
  const users_select = ref( [] );
  const input_select_editable = ref( null );
  const componentKey = ref ( 0 );

  const forceRerender = () => {
    componentKey.value += 1;
  };

  const init = () => {
    users_select.value = [];
    users_list.value = [];
    if ( props.all_users ) {
      props.all_users.forEach( ( user ) => {
        if ( props.users && props.users.findIndex( ( item ) => item.val == user.val ) < 0 ) {
          users_select.value.push( { val: user.val, text: user.text } );
        } else {
          users_list.value.push( { val: user.val, text: user.text } );
        }
      } );
      forceRerender();
    }
  };

  const setEditable = () => {
    if ( !props.readonly ) {
      editable.value = true;
      nextTick( () => {
        input_select_editable.value.focus();
      } );
    }
  };

  const blur = ( event ) => {
    if ( event.relatedTarget && event.relatedTarget.className.includes( 'noblur' ) ) {
      event.preventDefault = false;
    } else {
      editable.value = false;
    }
  };

  const addUser = ( id_user ) => {
    let idx = users_select.value.findIndex( ( item ) => item.val == id_user );
    users_list.value.push( { val: users_select.value[ idx ].val, text: users_select.value[ idx ].text } );
    users_select.value.splice( idx, 1 );

    let url = API.get_api( API.API_NAMES.RP_USERS, props.id );
    AXIOS.post( url, { id_users: id_user }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        emit( 'changeUsers' );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const removeUser = ( id_user ) => {
    input_select_editable.value.focus();
    input_select_editable.value.clear_selection();

    let idx = users_list.value.findIndex( ( item ) => item.val == id_user );
    users_select.value.push( { val: users_list.value[ idx ].val, text: users_list.value[ idx ].text } );
    users_list.value.splice( idx, 1 );

    let url = API.get_api( API.API_NAMES.RP_USERS, props.id, id_user  );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        emit( 'changeUsers' );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  init();
</script>
