<template>
  <div class="rp-item-card space-y-2" :key="componentKey">
    <div class="flex h-fit w-full gap-x-10 -mb-2">
      <div class="flex h-fit gap-x-3 max-w-[24rem]">
        <div class="font-bold">{{ t( 'Category' ) }}</div>
        <InputSelect :items="categories" :selected="item.category" :readonly="readonly" @change-value="changeCategory"/>
      </div>
      <div class="flex h-fit gap-x-3 grow">
        <div class="font-bold w-fit">{{ t( 'Ranking' ) }}</div>
        <Number :min="1" :max="500" :field="position" fkey="position" :readonly="readonly" @change="changeField" oneline/>
      </div>
      <button v-if="!readonly" class="flex justify-end -my-2 -mr-3 h-fit hover:bg-tertiary-hover rounded-full" @click="remove">
        <font-awesome-icon icon="fa-solid fa-xmark" size="xl" class="px-2 py-1"/>
      </button>
    </div>
    <FieldArea class="flex-auto"
      :placeholder="t( 'Description' ) + '...'" :field="description"
      fkey="description" :readonly="readonly" @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.CAUSE_DESCRIPTION"/>
    <div v-if="item.path" class="flex flex-row flex-wrap gap-1 w-52 mt-4">
        <img class="aspect-square w-40" :src="utils.get_api_base_url() + path.join( '/public', item.path )"/>
        <div class="relative" droppable="false">
          <div class="absolute bottom-2 right-4 text-sm" droppable="false">
            <button droppable="false"><font-awesome-icon icon="fas fa-trash-can" @click="remove_picture()" droppable="false"/></button>
          </div>
        </div>
      </div>
  </div>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import path from 'path-browserify';
  import Number from '@/components/object/NumberInput.vue';
  import InputSelect from '@/components/object/InputSelect.vue';
  import API from '@/constants/api.constants';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    item: { Object, required: true },
    readonly: { Boolean, required: false, default: false },
    categories: { Array, require: true, default: [] }
  } );
  const emit = defineEmits( [ 'changeItem', 'removeItem' ] );

  const { t } = useI18n();
  const description = ref ( '' );
  const position = ref ( 1 );

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  watchEffect( () => ( description.value = props.item.description ) );
  watchEffect( () => ( position.value = props.item.position ) );

  const changeItem = () => {
    emit( 'changeItem', props.item.id );
  };

  const removeItem = () => {
    emit( 'removeItem', props.item.id  );
  };

  const changeField = ( fkey, val ) => {
    let url = API.get_api( API.API_NAMES.CAUSES, props.item.id );
    AXIOS.put( url, { [ fkey ]: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeItem();
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const changeCategory = ( val ) => {
    changeField( 'category', val );
  };

  const remove = () => {
    let url = API.get_api( API.API_NAMES.CAUSES, 'disable', props.item.id );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          removeItem();
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const remove_picture = () => {
    changeField( 'path', '' );
  };
</script>
